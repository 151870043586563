<template>
  <div id="mapp-container"></div>
</template>

<script>
export default {
  name: "Home",
  components: {
    // HelloWorld
  }
};
</script>
