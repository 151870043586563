<template>
  <div class="login"></div>
</template>

<script>
import appConfig from "@/assets/js/config";
import { stateCommunication } from "../microapp";
// import * as dd from "dingtalk-jsapi";
// import {post,setToken} from "@/assets/js/util";
import MicroAppBaseLibrary from "@zstech/mobile-base-library";
import Vue from "vue";

export default {
  name: "DDLogin",
  data: function () {
    return {
      title: "登录",
      loginState: false,
      redirectUrl: "",
      UserKey: "",
      action: appConfig.API_CONFIGS["单点登录获取token信息"]
    };
  },
  mounted: function () {
    this.UserKey = this.$route.query.key;
    if (this.$route.query.url) {
      this.redirectUrl = this.decode(this.$route.query.url);
    }
    // console.log(this.UserKey,this.redirectUrl)
    this.TYLogin();
  },
  methods: {
    TYLogin: function () {
      var _this = this;

      MicroAppBaseLibrary.mpost(
        "单点登录获取token信息",
        {
          UserKey: this.UserKey,
          AppID: 9527,
          AppShortCode: "39fa4806e5fef6292e22af0fd9e4a1f3"
        },
        function (data) {
          // debugger
          if (data.Success) {
            // 获取token

            _this.success(data.Data);
          } else {
            _this.$router.push("login");
          }
        },
        function (e) {
          //_this.showLoading = false;
          alert("未绑定账号" + e.message);
          _this.$router.push("login");
          return true;
        },
        function (e) {
          alert("api/oauth/Login/GetAppUserKey" + "调取失败2" + e.stack);
          _this.$router.push("login");
          return true;
        },
        "http://" + location.host + "/api/oauth"
      );
    },

    success: function (udata) {
      // debugger
      var _this = this;
      var userinfo = {
        token: udata.token
        // name: udata.UserInfo.Name,
        // moblie: udata.UserInfo.Mobile,
        // Org: udata.UserInfo.Department,
        // account: udata.UserInfo.username
      };

      //将登录信息保存到本地Storage
      localStorage.setItem("userinfo", JSON.stringify(userinfo));

      // _this.loginState = false;

      // 登录成功后写入鉴权信息
      // axios.defaults.headers.common["Authorization"] = udata.Token;
      // setToken(udata.Token);
      _this.$store.commit("setAccessToken", udata.token);
      // debugger
      stateCommunication.broadcast({
        accessToken: udata.token
      });
      MicroAppBaseLibrary.install(Vue, {
        accessToken: udata.token,
        apiBaseUrl: "/api/core"
      });
      if (_this.redirectUrl) _this.$router.push(_this.redirectUrl);
      else _this.$router.push("/");
    },
    fail: function (message) {
      var _this = this;
      _this.$dialog.alert({
        title: "登录失败",
        message: JSON.stringify(message)
      });
      _this.loginState = false;
      _this.$router.push("login");
    },
    decode: function (str) {
      //BASE6解密
      return decodeURIComponent(window.atob(str));
    }
  }
};
</script>
