<template>
  <div id="app">
    <keep-alive>
      <router-view @title="seTitle" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view @title="seTitle" v-if="!$route.meta.keepAlive" />
    <TouchID
      :point="TouchIDPoint"
      v-model="touchIdShow"
      v-if="isShow()"></TouchID>
  </div>
</template>

<script>
import appConfig from "@/assets/js/config";
import * as dd from "dingtalk-jsapi";

export default {
  methods: {
    isShow: function () {
      let i = this.$route.query.touchHide;
      if (i) {
        return false;
      }
      return appConfig.TOUCH_ID_HIDE_PAGES.includes(this.$route.name);
    },
    seTitle: function (title) {
      document.title = title;
      if (dd.env.platform != "notInDingTalk") {
        dd.ready(function () {
          dd.biz.navigation.setTitle({
            title: title
          });
        });
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
