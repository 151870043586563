<template>
  <div class="home">
    <HelloWorld msg="ABCDEFG" />
    <!-- <van-grid>
      <van-grid-item
        v-for="mapp in microApps"
        :key="mapp.AppID"
        icon="photo-o"
        :text="mapp.name"
        @click="openMicroApp(mapp.activeRule)"></van-grid-item>
    </van-grid> -->
    <div></div>
    <div class="mapps">
      <!-- <div
        v-for="mapp in microApps"
        :key="mapp.AppID"
        class="mapp"
        @click="openMicroApp(mapp.activeRule)">
        <a-icon type="android" />
        <div>{{ mapp.name }}</div>
      </div> -->
      <!-- <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="android" />
        <div>android</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="apple" />
        <div>apple</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="windows" />
        <div>windows</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="ie" />
        <div>ie</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="chrome" />
        <div>chrome</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="github" />
        <div>github</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="aliwangwang" />
        <div>aliwangwang</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="dingding" />
        <div>dingding</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="html5" />
        <div>html5</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="weibo" />
        <div>weibo</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="twitter" />
        <div>twitter</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="wechat" />
        <div>wechat</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="youtube" />
        <div>youtube</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="taobao" />
        <div>taobao</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="skype" />
        <div>skype</div>
      </div>
      <div class="mapp" @click="openMicroApp('/mapp/app1/form')">
        <a-icon type="qq" />
        <div>qq</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { HelloWorld } from "@zstech/mobile-base-library";

// import About from "@/views/About.vue";
export default {
  name: "Home",
  components: { HelloWorld },
  computed: {
    microApps() {
      return this.$store.state.microApps;
    }
  },
  methods: {
    openMicroApp(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style lang="scss" scoped>
.mapps {
  width: 400px;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 20px auto;
  background-color: #fff;

  .mapp {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.anticon::v-deep {
  font-size: 32px;
  color: #666;
}
</style>
