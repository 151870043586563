import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    microApps: [],
    accessToken: ""
  },
  mutations: {
    pushMicroApps(state, mapps) {
      state.microApps.push(...mapps);
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    }
  },
  actions: {},
  modules: {}
});
