<template>
  <div class="login"></div>
</template>

<script>
import { stateCommunication } from "../microapp";
import * as dd from "dingtalk-jsapi";
// import {post,setToken} from "@/assets/js/util";
import MicroAppBaseLibrary from "@zstech/mobile-base-library";
import Vue from "vue";

export default {
  name: "DDLogin",
  data: function () {
    return {
      title: "登录",
      loginState: false,
      redirectUrl: "",
      corpid: "" //钉钉企业ID
    };
  },
  mounted: function () {
    this.corpid = this.$route.query.corpId;
    if (this.$route.query.redurl)
      this.redirectUrl = this.decode(this.$route.query.redurl);

    localStorage.clear();
    this.ddLogin();
    localStorage.loginUrl = location.href;
  },
  methods: {
    ddLogin: function () {
      var _this = this;
      dd.ready(function () {
        dd.runtime.permission.requestAuthCode({
          corpId: _this.corpid,
          onSuccess: function (info) {
            var code = info.code;
            MicroAppBaseLibrary.mpost(
              "钉钉登录",
              { code: code },
              function (data) {
                if (data.Success) {
                  _this.success(data.Data);
                } else {
                  _this.$router.push("login");
                }
              },
              function (e) {
                //_this.showLoading = false;
                alert("未绑定账号" + e.message);
                _this.$router.push("login");
                return true;
              },
              function (e) {
                alert("login/DingDingLogin" + "调取失败2" + e.stack);
                _this.$router.push("login");
                return true;
              },
              "http://" + location.host + "/api/oauth"
            );
          },
          onFail: function (err) {
            _this.fail(err);
          }
        });
      });
    },
    success: function (udata) {
      var _this = this;
      var userinfo = {
        token: udata.Token,
        name: udata.UserInfo.Name,
        moblie: udata.UserInfo.Mobile,
        Org: udata.UserInfo.Department,
        account: udata.UserInfo.username
      };

      //将登录信息保存到本地Storage
      localStorage.setItem("userinfo", JSON.stringify(userinfo));

      // _this.loginState = false;

      // 登录成功后写入鉴权信息
      // axios.defaults.headers.common["Authorization"] = udata.Token;
      // setToken(udata.Token);
      _this.$store.commit("setAccessToken", udata.Token);

      stateCommunication.broadcast({
        accessToken: udata.Token
      });
      MicroAppBaseLibrary.install(Vue, {
        accessToken: udata.Token,
        apiBaseUrl: "/api/core"
      });
      if (_this.redirectUrl) _this.$router.push(_this.redirectUrl);
      else _this.$router.push("/");
    },
    fail: function (message) {
      var _this = this;
      _this.$dialog.alert({
        title: "登录失败",
        message: JSON.stringify(message)
      });
      _this.loginState = false;
      _this.$router.push("login");
    },
    decode: function (str) {
      //BASE6解密
      return decodeURIComponent(window.atob(str));
    }
  }
};
</script>
