<template>
  <div class="login">
    <div id="main" class="login-header">
      <div class="login-header__content">
        <img src="/img/logo.png" height="50" width="50" />
      </div>
      <div class="login-header__text">
        <span id="title" class="login-header__title">{{ title }}</span>
      </div>
    </div>
    <div id="loginForm" class="login-form">
      <div class="login-form-header">登 录</div>
      <div>
        <van-row>
          <van-col span="4" class="login-form__field-icon">
            <van-icon color="#4f77ab" class-prefix="ext" name="mobile" />
          </van-col>
          <van-col span="20" class="login-form__field-control">
            <van-field
              v-model="username"
              clearable
              placeholder="请输入用户名/手机号"
              @focus="usernameOnfocus = true"
              @blur="usernameOnfocus = false" />
          </van-col>
        </van-row>
        <van-divider
          class="login-form__field-divider"
          v-if="!usernameOnfocus"></van-divider>
        <van-divider
          class="login-form__field-divider--focus"
          v-if="usernameOnfocus"></van-divider>
        <van-row>
          <van-col span="4" class="login-form__field-icon">
            <van-icon color="#4f77ab" class-prefix="ext" name="locked" />
          </van-col>
          <van-col span="20" class="login-form__field-control">
            <van-field
              clearable
              v-model="password"
              type="password"
              placeholder="请输入密码"
              @focus="passwordOnfocus = true"
              @blur="passwordOnfocus = false" />
          </van-col>
        </van-row>
        <van-divider
          class="login-form__field-divider"
          v-if="!passwordOnfocus"></van-divider>
        <van-divider
          class="login-form__field-divider--focus"
          v-if="passwordOnfocus">
        </van-divider>
      </div>
      <div class="login-form__button-container">
        <van-button
          class="login-form__button"
          :loading="loginState"
          loading-text="登录中..."
          @click="doLogin"
          :disabled="loginState"
          type="danger"
          :round="true">
          登 录
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { stateCommunication } from "../microapp";
// import axios from "axios";
import * as dd from "dingtalk-jsapi";
// import {setToken} from "@/assets/js/util";
import MicroAppBaseLibrary from "@zstech/mobile-base-library";
import Vue from "vue";

// export default {
//   name: "Login",
//   components: {},
//   data() {
//     return {
//       form: this.$form.createForm(this, { name: "login" })
//     };
//   },
//   methods: {
//     handleSubmit() {
//       this.$sotre.commit("setAccessToken", "acctoken");
//       initMicroApps();
//       this.$router.push("/");
//     }
//   }
// };

export default {
  name: "Login",
  data: function () {
    return {
      title: "登录",
      // action: "Login/Login",
      username: "",
      password: "",
      loginState: false,
      usernameOnfocus: false,
      passwordOnfocus: false,
      corpid: "" //钉钉企业ID
    };
  },
  mounted: function () {
    this.$emit("title", this.title);
    //this.corpid = this.$route.query.corpId;
    // if (this.corpid) {
    //   this.ddLogin();
    // }
  },
  methods: {
    doLogin: function () {
      this.loginState = true;
      var params = {
        account: this.username,
        password: this.password
      };

      var _this = this;
      MicroAppBaseLibrary.mpost(
        "用户登录接口",
        params,
        function (d) {
          if (d.Success) {
            try {
              var udata = d.Data;
              _this.success(udata);
            } catch (err) {
              _this.fail(d.Message);
            }
          } else {
            _this.fail(d.Message);
          }
        },
        function (d) {
          _this.fail(d.Message);
        },
        function (d) {
          _this.fail(d.Message);
        },
        null,
        "/api/core"
      );
    },
    ddLogin: function () {
      var _this = this;
      dd.ready(function () {
        dd.runtime.permission.requestAuthCode({
          corpId: _this.corpid,
          onSuccess: function (info) {
            var code = info.code;
            MicroAppBaseLibrary.mpost(
              "钉钉登录",
              { code: code },
              function (data) {
                _this.success(data.Data);
              },
              function (e) {
                //_this.showLoading = false;
                console.error("login/DingDingLogin" + "调取失败" + e.stack);
                return true;
              },
              function (e) {
                console.error("login/DingDingLogin" + "调取失败" + e.stack);
                return true;
              },
              "/api/oauth"
            );
          },
          onFail: function (err) {
            _this.fail(err);
          }
        });
      });
    },
    success: function (udata) {
      var _this = this;
      var userinfo = {
        token: udata.Token,
        name: udata.UserInfo.Name,
        moblie: udata.UserInfo.Mobile,
        Org: udata.UserInfo.Department,
        account: _this.username
      };

      //将登录信息保存到本地Storage
      localStorage.setItem("userinfo", JSON.stringify(userinfo));

      _this.loginState = false;

      // 登录成功后写入鉴权信息
      //  axios.defaults.headers.common["Authorization"] = udata.Token;
      // setToken(udata.Token);
      _this.$store.commit("setAccessToken", udata.Token);

      stateCommunication.broadcast({
        accessToken: udata.Token
      });
      MicroAppBaseLibrary.install(Vue, {
        accessToken: udata.Token,
        apiBaseUrl: "http://" + location.host + "/api/core"
      });

      _this.$router.push("/");
    },
    fail: function (message) {
      var _this = this;
      _this.$dialog.alert({
        title: "登录失败",
        message: message
      });
      _this.loginState = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  position: fixed;
  background: #f6f9ff;
  height: 100%;
  width: 100%;
  z-index: 1999;
  font-size: 15px;
}

.login-header {
  position: relative;
  height: 180px;
  top: 0px;
  width: 100%;
  z-index: 2000;
  background-color: #ee0a24;
}

.login-header__content {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.login-header__text {
  width: 100%;
  color: white;
  margin-top: 8px;
  font-weight: 520;
  text-align: center;
  font-family: "微软雅黑";
}

.login-form {
  position: fixed;
  height: 200px;
  top: 160px;
  width: 86%;
  left: 7%;
  background: white;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 0 0 3px #888;
}

.login-form-header {
  height: 25px;
  padding: 8px 18px;
  line-height: 25px;
  font-family: "微软雅黑";
  font-weight: bolder;
  font-style: inherit;
  color: #444444;
}

.login-form__field-icon {
  padding-top: 12px;
  padding-left: 20px;
}

.login-form__field-divider {
  margin: 0;
  padding: 0 14px;
}

.login-form__field-divider--focus {
  border-color: #aaa;
  margin: 0;
  padding: 0 14px;
}

.login-form__button-container {
  text-align: center;
  margin-top: 20px;
}

.login-form__button {
  width: 80%;
  height: 30px;
  /* font-size: 12px; */
  font-family: "微软雅黑";
  line-height: 30px;
}
</style>
