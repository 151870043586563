/**
 * 表单功能扩展
 * @creator hq
 */

import MicroAppBaseLibrary from "@zstech/mobile-base-library";
import { Dialog } from "vant";
import moment from "moment";
import { showLoading } from "@/assets/js/util";
export default {
  FORM_EXTENDS: {
    form_1: {
      controlEvents: {
        XmjlYcy: {
          onChange: function (vueInst, value) {
            var params = {
              departmentId: ""
            };
            MicroAppBaseLibrary.mpost(
              "根据部门获取所属用户记录接口",
              params,
              function (res) {
                if (res.Success) {
                  var Data =
                    vueInst.$parent.$parent.$parent.$parent.updatedMainData;
                  for (var i = 0; i < res.Data.datalist.length; i++) {
                    if (value == res.Data.datalist[i].username) {
                      for (
                        var z = 0;
                        z < res.Data.datalist[i].exField.length;
                        z++
                      ) {
                        Data[res.Data.datalist[i].exField[z]["exFieldName"]] =
                          res.Data.datalist[i].exField[z].value;
                      }
                      break;
                    }
                  }
                  vueInst.cacheData = Data;
                  vueInst.$parent.$parent.$parent.$parent.fData = Data;
                  // vueInst.$parent.$parent.$parent.$parent.updatedMainData = Data;
                  // vueInst.updatePageData("cacheData", Data);
                }
              },
              function (e) {
                //_this.showLoading = false;
                console.error(
                  "根据部门获取所属用户记录接口" + "调取失败" + e.stack
                );
                return true;
              }
            );
          }
        }
      },
      formEvents: {
        beforeSaveData: function (vueInst) {
          var isYx = false;
          if (vueInst) {
            if (
              vueInst.formDataId == "" ||
              vueInst.formDataId == null ||
              vueInst.formDataId == undefined
            ) {
              vueInst.updatedMainData["Xmbh"] = "[CDATEYM+-+SN:3]";
              var Xmbh1 = vueInst.updatedMainData.Xmbh.split("[");
              var Xmbh2 = Xmbh1[1].split("]");
              var Xmbh =
                "[" +
                vueInst.updatedMainData.Xmjd +
                "+-+" +
                vueInst.updatedMainData.Ywly +
                "+-+" +
                Xmbh2[0] +
                "]";
              vueInst.updatedMainData["Xmbh"] = Xmbh;
            }
            if (
              moment(vueInst.updatedMainData.Xmjhsssj) >=
              moment(vueInst.updatedMainData.Xmjhjssj)
            ) {
              alert("项目开始时间不可大于或等于结束时间！");
              isYx = false;
            } else {
              isYx = true;
              var params = {
                departmentId: ""
              };
              MicroAppBaseLibrary.mpost(
                "根据部门获取所属用户记录接口",
                params,
                function (res) {
                  var SS = vueInst.updatedMainData;
                  var XmjlYcy = SS.XmjlYcy;
                  var dataId = 0;
                  if (res.Success) {
                    for (var i = 0; i < res.Data.datalist.length; i++) {
                      if (XmjlYcy == res.Data.datalist[i].username) {
                        dataId = res.Data.datalist[i].id;
                        break;
                      }
                    }
                    var params1 = {
                      tableName: "core_user_AppUser",
                      dataId: dataId,
                      ExfieldData: [
                        {
                          exFieldName: "Xmbdje",
                          value: SS["Xmbdje"]
                        }
                      ]
                    };
                    MicroAppBaseLibrary.mpost(
                      "获取扩展字段方法",
                      params1,
                      function (data) {
                        if (data.Success) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                      function (e) {
                        //_this.showLoading = false;
                        console.error(
                          "获取扩展字段方法" + "调取失败" + e.stack
                        );
                        return true;
                      }
                    );
                  }
                },
                function (e) {
                  //_this.showLoading = false;
                  console.error(
                    "根据部门获取所属用户记录接口" + "调取失败" + e.stack
                  );
                  return true;
                }
              );
            }
          } else {
            isYx = false;
          }
          return isYx;
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_2: {
      formEvents: {
        beforeSaveData: function (vueInst) {
          var myreg = (myreg =
            /^(((13[0-9]{1})|(14[0-9]{1})|(17[0]{1})|(15[0-3]{1})|(15[5-9]{1})|(18[0-9]{1}))+\d{8})$/);
          var Dh = vueInst.updatedMainData.Dh; //电话验证
          if (Dh != undefined && Dh != "" && Dh != null) {
            if (!myreg.test(Dh)) {
              alert("请输入有效的手机号码！");
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_6: {
      controlEvents: {
        Cgxz: {
          onChange: function (vueInst, value) {
            var canSelectedOptions = [];
            vueInst.$parent.$parent.$parent.$parent.updatedMainData.Cgxz =
              value;
            if (value == "工程项目采购（设备/材料）") {
              canSelectedOptions = [
                { label: "在建项目设备、材料", value: "1" },
                { label: "物流", value: "2" }
              ];
            } else if (value == "江西科环生产原料") {
              canSelectedOptions = [
                { label: "江西科环生产", value: "1" },
                { label: "其他", value: "2" }
              ];
            } else if (value == "工程项目采购（土建/安装/劳务发包）") {
              canSelectedOptions = [
                { label: "土建", value: "1" },
                { label: "安装", value: "2" },
                { label: "劳务发包", value: "3" }
              ];
            } else if (value == "实验设备/药剂") {
              canSelectedOptions = [
                { label: "药剂", value: "1" },
                { label: "耗材", value: "2" },
                { label: "设备", value: "3" },
                { label: "其他", value: "4" }
              ];
            } else if (value == "运营项目物料") {
              canSelectedOptions = [{ label: "运营项目物料", value: "1" }];
            } else if (value == "其他") {
              canSelectedOptions = [];
            }

            vueInst.cacheOptions.Cglx = canSelectedOptions;
            vueInst.updatePageData(
              "cacheData",
              vueInst.$parent.$parent.$parent.$parent.updatedMainData
            );
          }
        },
        Xmbh: {
          onChange: function (vueInst, value) {
            if (
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Xmbh !=
              value
            ) {
              //删除明细
              vueInst.$parent.$parent.$parent.$parent.deleteAllDetailRows(
                "采购申请明细表"
              );
            }
          }
        }
      },
      formEvents: {
        afterLoadHtml: function () {
          setTimeout(() => {
            autoHeight();
          }, 1000);
        },
        beforeSaveData: function (vueInst, api) {
          if (vueInst && api.pageName == "FormAddPage") {
            //如果采购性质为项目采购（工程、运营、研发）时，项目编号未必填
            if (
              vueInst.updatedMainData.Cgxz == "项目采购（工程、运营、研发）"
            ) {
              if (
                vueInst.updatedMainData.Xmbh == undefined ||
                vueInst.updatedMainData.Xmbh == null ||
                vueInst.updatedMainData.Xmbh == ""
              ) {
                alert("项目编号，项目名称，项目经理为必填!");
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        },
        beforeRenderData: function (vueInst, api) {
          var checkApiisnull = (function () {
            let bo = false;
            for (var e in api.formData) {
              if (e) {
                bo = true;
                return bo;
              }
            }
            return bo;
          })();
          if (checkApiisnull && api.formData.ID != "") {
            var canSelectedOptions = [];
            if (api.formData.Cgxz == "工程项目采购（设备/材料）") {
              canSelectedOptions = [
                { label: "在建项目设备、材料", value: "1" },
                { label: "物流", value: "2" }
              ];
            } else if (api.formData.Cgxz == "江西科环生产原料") {
              canSelectedOptions = [
                { label: "江西科环生产", value: "1" },
                { label: "其他", value: "2" }
              ];
            } else if (
              api.formData.Cgxz == "工程项目采购（土建/安装/劳务发包）"
            ) {
              canSelectedOptions = [
                { label: "土建", value: "1" },
                { label: "安装", value: "2" },
                { label: "劳务发包", value: "3" }
              ];
            } else if (api.formData.Cgxz == "实验设备/药剂") {
              canSelectedOptions = [
                { label: "药剂", value: "1" },
                { label: "耗材", value: "2" },
                { label: "设备", value: "3" },
                { label: "其他", value: "4" }
              ];
            } else if (api.formData.Cgxz == "运营项目物料") {
              canSelectedOptions = [{ label: "运营项目物料", value: "1" }];
            } else if (api.formData.Cgxz == "其他") {
              canSelectedOptions = [];
            }
            vueInst.cacheOptions.Cglx = canSelectedOptions;
          }
        }
      }
    },
    form_7: {
      controlEvents: {
        Bjzj: {
          onChange: function (vueInst) {
            if (vueInst.cacheData.Bjzj < 500000) {
              //询比价
              vueInst.cacheData.Bjlx = "询比价";
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Bjlx =
                "询比价";
            } else if (
              vueInst.cacheData.Bjzj >= 500000 &&
              vueInst.cacheData.Bjzj < 4000000
            ) {
              vueInst.cacheData.Bjlx = "邀标";
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Bjlx =
                "邀标";
            } else if (vueInst.cacheData.Bjzj <= 4000000) {
              vueInst.cacheData.Bjlx = "公开招标";
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Bjlx =
                "公开招标";
            }
          }
        },
        Sl: {
          onChange: function (vueInst) {
            setTimeout(() => {
              var details =
                vueInst.$parent.$parent.$parent.$parent.updatedDetailData
                  .采购比价明细表;
              for (let i = 0; i < details.length; i++) {
                const detail = details[i];
                if (detail.GsNum < parseFloat(detail.Sl)) {
                  //变红
                  document.getElementsByName("Sl")[i].parentNode.style.border =
                    "1px solid red";
                  document.getElementsByName("Sl")[i].style.color = "red";
                } else {
                  document.getElementsByName("Sl")[i].parentNode.style.border =
                    "0";
                  document.getElementsByName("Sl")[i].style.color = "black";
                }
              }
            }, 100);
          }
        },
        Xmbh: {
          onChange: function (vueInst, value) {
            if (
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Xmbh !=
              value
            ) {
              //删除明细
              vueInst.$parent.$parent.$parent.$parent.deleteAllDetailRows(
                "采购比价明细表"
              );
            }
          }
        }
      },
      formEvents: {
        DetailKeyName: { key: "Cgxz", name: "采购性质" },
        afterLoadHtml: function () {
          setTimeout(() => {
            autoHeight();
          }, 1000);
        },
        afterDetailChange: function (vueInst, detailDataList) {
          //明细变更事件
          setTimeout(() => {
            var details = detailDataList.采购比价明细表;
            for (let i = 0; i < details.length; i++) {
              const detail = details[i];
              if (detail.GsNum < parseFloat(detail.Sl)) {
                //变红
                document.getElementsByName("Sl")[i].parentNode.style.border =
                  "1px solid red";
                document.getElementsByName("Sl")[i].style.color = "red";
              } else {
                document.getElementsByName("Sl")[i].parentNode.style.border =
                  "0";
                document.getElementsByName("Sl")[i].style.color = "black";
              }
            }
          }, 100);

          if (detailDataList.采购比价明细表.length == 0) {
            //明细清空，采购性质同时清空
            vueInst.fData.Cgxz = "";
            vueInst.updatedMainData.Cgxz = "";
          }
          //计算含税总价 Hszj，不含税单价 Bhsdj，不含税总价 Bhszj，比价总价 Bjzj
          var total = 0;
          for (
            let index = 0;
            index < vueInst.updatedDetailData.采购比价明细表.length;
            index++
          ) {
            var detail = vueInst.updatedDetailData.采购比价明细表[index];
            if(!detail.CGSl || !detail.Hsdj) continue;
            detail.Hszj = (
              parseFloat(detail.CGSl) * parseFloat(detail.Hsdj)
            ).toFixed(2);
            if (isNaN(detail.Hszj)) {
              detail.Hszj = "";
              continue;
            }
            detail.Bhsdj = (
              parseFloat(detail.Hsdj) /
              (1 + parseFloat(detail.Sh))
            ).toFixed(2);
            detail.Bhszj = (detail.Hszj / (1 + parseFloat(detail.Sh))).toFixed(
              2
            );
            total += detail.Hszj;
          }
          vueInst.updatedMainData.Bjzj = total.toFixed(2);
          vueInst.fData.Bjzj = total.toFixed(2);
          if (vueInst.updatedDetailData.采购比价明细表.length > 0) {
            for (let detail of vueInst.updatedDetailData.采购比价明细表) {
              if (detail.Cgxz) {
                vueInst.updatedMainData.Cgxz = detail.Cgxz;
                vueInst.fData.Cgxz = detail.Cgxz;
                break;
              }
            }
          }
          if (
            JSON.stringify(vueInst.formDetailsDataList.采购比价明细表) !=
            JSON.stringify(vueInst.updatedDetailData.采购比价明细表)
          ) {
            vueInst.formDetailsDataList.采购比价明细表 = JSON.parse(
              JSON.stringify(vueInst.updatedDetailData.采购比价明细表)
            );
          }
        },
        afterGetFormData: function (vueInst) {
          setTimeout(() => {
            var details = vueInst.formDetailTableData.采购比价明细表;
            if (details) {
              for (var i = 0; i < details.length; i++) {
                let data = details[i];
                if (parseInt(data.GsNum) < parseInt(data.Sl)) {
                  document.getElementsByName("Sl")[i].style.color = "red";
                } else {
                  document.getElementsByName("Sl")[i].style.color = "black";
                }
              }
            }
          }, 100);
        },
        beforeRenderData: function (vueInst) {
          if (
            !vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
              vueInst.detailName
            ][vueInst.detailIndex].Hszj
          )
            vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
              vueInst.detailName
            ][vueInst.detailIndex].Sh = "0.13";
        },
        //验证导入数据
        ValidateImportData: function (vueInst, detailName, importData) {
          var formData = vueInst.updatedMainData;
          for (let i = 0; i < importData.length; i++) {
            const detail = importData[i];
            if (
              detail.WBSbm &&
              formData.Xmbh &&
              detail.WBSbm.indexOf(formData.Xmbh) == -1
            ) {
              Dialog.alert({
                message: "导入的wbs编码不在选入的项目编号范围内"
              });
              return false;
            }
          }
          return true;
        },
        beforeSaveData: function (vueInst, api, stateCaption, callBack) {
          if (vueInst && api.pageName == "FormAddPage") {
            var Bool = false;
            if (
              vueInst.updatedMainData.Sm == undefined ||
              vueInst.updatedMainData.Sm == ""
            ) {
              if (
                parseFloat(vueInst.updatedMainData.Bjzj) <= 500000 &&
                vueInst.updatedMainData.Bjlx != "询比价"
              ) {
                //	比价类型：当比价明细总价小于等于50万时，比价类型自动选择“询比价”
                vueInst.$toast("比价总价与比价类型不符合，说明字段必填！");
                return false;
              } else if (
                parseFloat(vueInst.updatedMainData.Bjzj) > 500000 &&
                parseFloat(vueInst.updatedMainData.Bjzj) < 4000000 &&
                vueInst.updatedMainData.Bjlx != "邀标"
              ) {
                //	大于50万，小于400万时比价类型自动选择“邀标”
                vueInst.$toast("比价总价与比价类型不符合，说明字段必填！");
                return false;
              } else if (
                parseFloat(vueInst.updatedMainData.Bjzj) >= 4000000 &&
                vueInst.updatedMainData.Bjlx != "公开招标"
              ) {
                //	大于等于400万时比价类型自动选择“公开招标”
                vueInst.$toast("比价总价与比价类型不符合，说明字段必填！");
                return false;
              }
            }

            if (
              vueInst.updatedDetailData ||
              vueInst.updatedDetailData.采购比价明细表
            ) {
              //验证明细导入的WBS编码 是否正确
              var details = vueInst.updatedDetailData.采购比价明细表;
              Bool = true;
              for (let i = 0; i < details.length; i++) {
                const item = details[i];
                if (item.WBSbm.indexOf(vueInst.updatedMainData.Xmbh) == -1) {
                  Bool = false;
                  vueInst.$toast("WBS编码与项目编号不符！");
                  break;
                }
              }
              return Bool;
            }
            return true;
          } else if (vueInst && api.pageName == "FormProcessingPage") {
            console.log(callBack);
            var arr = [];
            if (
              stateCaption == "项目经理" ||
              ((stateCaption == "部门经理" || stateCaption == "采购经理") &&
                vueInst.processing_flows[vueInst.processing_flows.length - 2]
                  .actor == "开始")
            ) {
              if (
                getFlowResult(vueInst.processNames, vueInst.processRadio) !=
                "同意"
              )
                return true;
              for (var i = 0; i < vueInst.formDetailTableNames.length; i++) {
                for (
                  var z = 0;
                  z <
                  vueInst.formDetailsDataList[vueInst.formDetailTableNames[i]]
                    .length;
                  z++
                ) {
                  arr.push({
                    BudgetCode:
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].WBSbm,
                    Money:
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].Hszj
                  });
                }
              }
              var params = {
                data: arr,
                Leval: "1"
              };
              console.log(params);
              MicroAppBaseLibrary.mpost(
                "校验概算节点的使用情况",
                params,
                function (data) {
                  if (data.Data.alist[0][2].length > 0) {
                    //存在超概
                    Dialog.confirm({
                      title: "超概",
                      message:
                        data.Data.alist[0][2][0].BudgetCode + "该节点已超概！"
                    })
                      .then(() => {
                        //存在超概的情况下 生成超概申请单
                        //too1: 超概申请单生成成功后
                        console.log(vueInst);
                        var mainData = {
                          Ywdh: vueInst.fData.Bjsqdh, //业务单号
                          Ywmc: vueInst.fData._SUBJECT + "超概申请单", //业务名称
                          Cglx: vueInst.fData._SUBJECT, //超概类型
                          Cgsm: "", //超概说明
                          Sqbm: vueInst.fData.Xqbm, //申请部门
                          Sqr: vueInst.fData.Xqbmjbr, //申请人
                          Sqsj: vueInst.fData.Sqsj, //申请时间
                          XmjlYcy: vueInst.fData.XmjlYcy, //项目经理
                          RatioexcessLevel:
                            data.Data.alist[0][2][0].RatioexcessLevel,
                          IsRatioexcess: data.Data.alist[0][2][0].IsRatioexcess,
                          OtherCode: vueInst.fData.Bjsqdh, //单号
                          Formnom: vueInst.fData._FORMNO //单号
                          // Wbsbm: data.Data.alist[0][2][0].BudgetCode, //Wbs编码
                          // Wbsmc: data.Data.alist[0][2][0].BudgetName, //Wbs名称
                          // Ysje: data.Data.alist[0][2][0].BudgetMoney, //预算金额
                          // Sjje: Sjje, //实际金额
                          // Cgje: parseFloat(Sjje - data.Data.alist[0][2][0].BudgetMoney), //超概金额
                        };
                        var detailData = {
                          超概申请审批单明细: []
                        };
                        for (let i = 0; i < data.Data.alist[0][2].length; i++) {
                          var flag = false;
                          for (let z = 0; z < arr.length; z++) {
                            if (
                              arr[z].BudgetCode ==
                              data.Data.alist[0][2][i].BudgetCode
                            ) {
                              detailData["超概申请审批单明细"].push({
                                Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                                Wbsmc: data.Data.alist[0][2][i].BudgetName,
                                Ysje: data.Data.alist[0][2][i].BudgetMoney,
                                Sjje: arr[z].Money,
                                Cgje: data.Data.alist[0][2][i].Cgje,
                                RatioexcessRatio:
                                  data.Data.alist[0][2][i].RatioexcessRatio,
                                Sxje: data.Data.alist[0][2][i].Sxje
                                  ? data.Data.alist[0][2][i].Sxje
                                  : 0
                              });
                              flag = true;
                            }
                          }
                          if (!flag) {
                            detailData["超概申请审批单明细"].push({
                              Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                              Wbsmc: data.Data.alist[0][2][i].BudgetName,
                              Ysje: data.Data.alist[0][2][i].BudgetMoney,
                              Cgje: data.Data.alist[0][2][i].Cgje,
                              RatioexcessRatio:
                                data.Data.alist[0][2][i].RatioexcessRatio,
                              Sxje: data.Data.alist[0][2][i].Sxje
                                ? data.Data.alist[0][2][i].Sxje
                                : 0
                            });
                          }
                        }
                        var params = {
                          templateid: 45,
                          formdataid: undefined,
                          noticeid: 0,
                          from: "",
                          mainData: mainData,
                          mainDataKz: [],
                          detailData: detailData
                        };
                        console.log(params);
                        MicroAppBaseLibrary.mpost(
                          "新建表单数据保存接口",
                          params,
                          function (data) {
                            if (data.Success) {
                              var params1 = {
                                templateId: vueInst.fData._TEMPLATE_ID,
                                formdataId: vueInst.fData.ID,
                                State: 1 //冻结
                              };
                              MicroAppBaseLibrary.mpost(
                                "概算冻结表单",
                                params1,
                                function (data1) {
                                  if (data1.Success) {
                                    vueInst.saveData(callBack);
                                  }
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单" + "调取失败" + e.stack
                                  );
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单" + "调取失败" + e.stack
                                  );
                                },
                                "api/budget"
                              );
                            }
                          },
                          function (e) {
                            console.error(
                              "新建表单数据保存接口" + "调取失败" + e.stack
                            );
                          }
                        );
                      })
                      .catch(() => {
                        console.log("已取消");
                      });
                  } else if (data.Data.alist[0][1].length > 0) {
                    //存在警告
                    Dialog.confirm({
                      title: "提示",
                      message:
                        data.Data.alist[0][1][0].BudgetName +
                        "该节点已使用超过" +
                        data.Data.alist[0][1][0].ratio +
                        "%"
                    })
                      .then(() => {
                        //正常确认后继续流传
                        vueInst.saveData(callBack);
                      })
                      .catch(() => {
                        console.log("提示");
                      });
                  } else if (data.Data.alist[0][0].length > 0) {
                    //正常
                    vueInst.saveData(callBack);
                  } else {
                    vueInst.$toast("概算编号未找到对应数据。");
                  }
                },
                function (e) {
                  //_this.showLoading = false;
                  console.error(
                    "校验概算节点的使用情况" + "调取失败" + e.stack
                  );
                  return true;
                },
                function (e) {
                  console.error(
                    "校验概算节点的使用情况" + "调取失败" + e.stack
                  );
                  return true;
                },
                "/api/budget"
              );
              return false;
            } else {
              return true;
            }
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_10: {
      controlEvents: {
        Bjddh: {
          onChange: function (vueInst, value) {
            var params = {
              OperationTemplateMXName: "采购合同明细表",
              OperationTemplateName: "采购合同"
            };
            MicroAppBaseLibrary.mpost(
              "获取明细配置",
              params,
              function (data) {
                if (data.Success) {
                  var SourceFormName = data.Data.data[0].SourceFormName;
                  var OperationFormName = data.Data.data[0].OperationFormName;
                  var params1 = {
                    OperationTemplateName: OperationFormName,
                    SourceTemplateName: SourceFormName,
                    from: "",
                    page: {
                      page_index: 1,
                      page_number: 10
                    },
                    search_key: "",
                    filter: [
                      {
                        key: "Bjsqdh",
                        value: value,
                        sqlOperator: "EN"
                      }
                    ]
                  };
                  MicroAppBaseLibrary.mpost(
                    "获取选入明细数据",
                    params1,
                    function (data1) {
                      if (data1.Success) {
                        var Arrzb = [];
                        var Arrmxb = [];
                        var OperationFormFields =
                          data1.Data.OperationFormFields.split(","); //选择表
                        var SourceFormFields =
                          data1.Data.SourceFormFields.split(","); //被选表
                        var d = data1.Data.data; //数据
                        for (var i = 0; i < d.length; i++) {
                          var objzb = {};
                          var objmxb = {};
                          for (var z = 0; z < OperationFormFields.length; z++) {
                            if (OperationFormFields[z].indexOf("Mxb") >= 0) {
                              var S1 = OperationFormFields[z].split(".")[1];
                              var S2 = SourceFormFields[z].split(".")[1];
                              objmxb[S1] = d[i][S2];
                            } else if (
                              OperationFormFields[z].indexOf("zb") >= 0
                            ) {
                              var S3 = OperationFormFields[z].split(".")[1];
                              var S4 = SourceFormFields[z].split(".")[1];
                              objzb[S3] = d[i][S4];
                            }
                          }
                          objmxb["_HH"] = d[i]._HH;
                          Arrzb.push(objzb);
                          Arrmxb.push(objmxb);
                        }
                        vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                          "采购合同明细表"
                        ] = [];
                        vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                          "采购合同明细表",
                          Arrmxb
                        );
                      }
                    },
                    function (e) {
                      console.error("获取选入明细数据" + "调取失败" + e.stack);
                    }
                  );
                }
              },
              function (e) {
                console.error("获取明细配置" + "调取失败" + e.stack);
              }
            );
          }
        },
        Xmbh: {
          onChange: function (vueInst, value) {
            if (
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Xmbh !=
              value
            ) {
              vueInst.$parent.$parent.$parent.$parent.deleteAllDetailRows(
                "采购合同明细表"
              );
            }
          }
        }
      },
      formEvents: {
        DetailSelectType: true, //是否允许重复选择明细数据
        DetailKeyName: { key: "Tjcs", name: "中标供应商" },
        afterDetailChange: function (vueInst) {
          if (!vueInst.updatedMainData.Htzjd) {
            vueInst.updatedMainData.Htzjd = n2ChM(
              vueInst.updatedMainData.Htzjx
            );
            vueInst.fData.Htzjd = vueInst.updatedMainData.Htzjd;
          }
        },
        afterLoadHtml: function (vueInst) {
          if (!vueInst.fData.ID) {
            document.getElementsByName(
              "chakan3"
            )[0].parentNode.parentNode.style.display = "none";
            document.getElementsByName(
              "chakan2"
            )[0].parentNode.parentNode.style.display = "none";
          }

          setTimeout(() => {
            autoHeight();
          }, 1000);
        },
        beforeSaveData: function (vueInst) {
          if (!vueInst.updatedMainData.Htzjd) {
            vueInst.updatedMainData.Htzjd = n2ChM(
              vueInst.updatedMainData.Htzjx
            );
          }
          if (vueInst) return true;
          return false;
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        },
        beforeRenderData: function (vueInst) {
          if (vueInst.cacheData.Htbm) {
            MicroAppBaseLibrary.mpost(
              "采购合同合同总额",
              { Htbh: vueInst.cacheData.Htbm },
              function (res) {
                if (res.Success) {
                  var data = res.Data;
                  vueInst.cacheData.Ykpe = data.sfpMoney;
                  vueInst.cacheData.Yfkje = data.money;
                  vueInst.cacheData.Yfkbl =
                    (
                      (parseFloat(data.money) * 100) /
                      parseFloat(vueInst.cacheData.Htsjzjx)
                    ).toFixed(2) + "%";
                  vueInst.cacheData.Syfkje =
                    vueInst.cacheData.Htsjzjx - data.money;
                }
              },
              function (e) {
                console.error("采购合同合同总额" + "调取失败" + e.stack);
              },
              function (e) {
                console.error("采购合同合同总额" + "调取失败" + e.stack);
              },
              "/api/PurchasingManagement"
            );
          }
        }
      }
    },
    form_13: {
      controlEvents: {
        Ycghtbh: {
          onChange: function (vueInst, value) {
            var params = {
              Htbh: value
            };
            setTimeout(() => {
              //记录原数据
              window.oldFormDetailData = JSON.parse(
                JSON.stringify(
                  vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                    "采购合同变更明细表"
                  ]
                )
              );
            }, 3000);
            MicroAppBaseLibrary.mpost(
              "采购合同变更保存前验证",
              params,
              function (data) {
                if (data.Success) {
                  vueInst.$parent.$parent.$parent.$parent.fData.Istrue =
                    data.Data;
                }
              },
              function (e) {
                //_this.showLoading = false;
                console.error("采购合同变更保存前验证" + "调取失败" + e.stack);
                return true;
              },
              function (e) {
                //_this.showLoading = false;
                console.error("采购合同变更保存前验证" + "调取失败" + e.stack);
                return true;
              },
              "/api/PurchasingManagement"
            );
          }
        },
        CGID: {
          onChange: function (vueInst, value) {
            var params = {
              templateId: 10,
              formdataid: value
            };
            MicroAppBaseLibrary.mpost(
              "获取指定表单数据接口",
              params,
              function (data) {
                if (data.Success) {
                  if (data.Data.formdetailsdata.length > 0) {
                    vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                      "采购合同变更明细表"
                    ] = [];
                    vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                      "采购合同变更明细表"
                    ] = [];
                    vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                      "采购合同变更明细表",
                      data.Data.formdetailsdata[0]
                    );
                  }
                }
              },
              function (e) {
                //_this.showLoading = false;
                console.error("获取指定表单数据接口" + "调取失败" + e.stack);
                return true;
              }
            );
          }
        },
        Status: {
          onChange: function (vueInst, value) {
            if (value == "删除") {
              vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                vueInst.detailName
              ][vueInst.detailIndex].Num = 0;
              vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                vueInst.detailName
              ][vueInst.detailIndex].Hszj = 0;
              vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                vueInst.detailName
              ][vueInst.detailIndex].Hsdj = 0;
              vueInst.cacheData =
                vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                  vueInst.detailName
                ][vueInst.detailIndex];
              vueInst.cacheData.Status = value;
              vueInst.updatePageData("cacheData", vueInst.cacheData);
            }
          }
        }
      },
      formEvents: {
        afterLoadHtml: function (vueInst) {
          if (!vueInst.fData.ID) {
            document.getElementsByName(
              "chakan2"
            )[0].parentNode.parentNode.style.display = "none";
          }

          setTimeout(() => {
            autoHeight();
          }, 1000);
        },
        afterGetFormData: function (vueInst) {
          //绑定明细状态
          setTimeout(() => {
            var details = vueInst.formDetailsDataList["采购合同变更明细表"];
            if (!details || details.length == 0) return;

            var items = document.getElementsByName("Status");
            for (let i = 0; i < items.length; i++) {
              var color = getColor(items[i].innerText);
              items[i].parentNode.parentNode.parentNode.style.backgroundColor =
                color;
            }
          }, 100);

          function getColor(status) {
            switch (status) {
              case "新增":
                return "#00f800";
              case "变更":
                return "yellow";
              case "删除":
                return "red";
              default:
                return "";
            }
          }
        },
        beforeRenderData: function (vueInst, api) {
          if (
            api.detailName &&
            vueInst.$parent.$parent.$parent.$parent.api.pageName ==
              "FormAddPage"
          ) {
            var canSelectedOptions = statusSelectList(api.formData.Hszj);
            vueInst.cacheOptions.Status = canSelectedOptions;
            if (canSelectedOptions.length == 1)
              vueInst.cacheData.Status = "新增";
            else vueInst.cacheData.Status = "未变更";
            vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
              api.detailName
            ][api.detailIndex].Status = vueInst.cacheData.Status;
            vueInst.updatePageData("cacheData", vueInst.cacheData);
          }
        },
        beforeSaveData: function (vueInst, api, stateCaption, callBack) {
          if (vueInst) {
            if (vueInst && api.pageName == "FormProcessingPage") {
              var arr = [];
              if (stateCaption == "项目经理" || stateCaption == "采购部经理") {
                if (
                  getFlowResult(vueInst.processNames, vueInst.processRadio) ==
                  "不同意"
                )
                  return true;

                //同意验证是否超概
                for (var i = 0; i < vueInst.formDetailTableNames.length; i++) {
                  for (
                    var z = 0;
                    z <
                    vueInst.formDetailsDataList[vueInst.formDetailTableNames[i]]
                      .length;
                    z++
                  ) {
                    var curHszj =
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].Hszj;
                    var oldHszj =
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].Htje;
                    arr.push({
                      BudgetCode:
                        vueInst.formDetailsDataList[
                          vueInst.formDetailTableNames[i]
                        ][z].WBSbm,
                      Money: curHszj - oldHszj
                    }); //验证新增的变更金额
                  }
                }
                var params = {
                  data: arr,
                  Leval: "1"
                };
                console.log(params);
                MicroAppBaseLibrary.mpost(
                  "校验概算节点的使用情况",
                  params,
                  function (data) {
                    if (data.Data.alist[0][2].length > 0) {
                      //存在超概
                      Dialog.confirm({
                        title: "超概",
                        message:
                          data.Data.alist[0][2][0].BudgetName + "该节点已超概！"
                      })
                        .then(() => {
                          //存在超概的情况下 生成超概申请单
                          //too1: 超概申请单生成成功后
                          console.log(vueInst);
                          var mainData = {
                            Ywdh: vueInst.fData.Bgsqbh, //业务单号
                            Ywmc: vueInst.fData._SUBJECT + "超概申请单", //业务名称
                            Cglx: vueInst.fData._SUBJECT, //超概类型
                            Cgsm: "", //超概说明
                            Sqbm: vueInst.fData.Zbbm, //申请部门
                            Sqr: vueInst.fData.Jbr, //申请人
                            Sqsj: moment().format("YYYY-MM-DD HH:mm:ss"), //申请时间
                            XmjlYcy: JSON.parse(localStorage.userinfo).account, //项目经理
                            RatioexcessLevel:
                              data.Data.alist[0][2][0].RatioexcessLevel,
                            IsRatioexcess:
                              data.Data.alist[0][2][0].IsRatioexcess,
                              OtherCode: vueInst.fData.Bgsqbh, //单号
                            Formnom: vueInst.fData._FORMNO //单号
                            // Wbsbm: data.Data.alist[0][2][0].BudgetCode, //Wbs编码
                            // Wbsmc: data.Data.alist[0][2][0].BudgetName, //Wbs名称
                            // Ysje: data.Data.alist[0][2][0].BudgetMoney, //预算金额
                            // Sjje: Sjje, //实际金额
                            // Cgje: parseFloat(Sjje - data.Data.alist[0][2][0].BudgetMoney), //超概金额
                          };
                          var detailData = {
                            超概申请审批单明细: []
                          };
                          for (
                            let i = 0;
                            i < data.Data.alist[0][2].length;
                            i++
                          ) {
                            var flag = false;
                            for (let z = 0; z < arr.length; z++) {
                              if (
                                arr[z].BudgetCode ==
                                data.Data.alist[0][2][i].BudgetCode
                              ) {
                                detailData["超概申请审批单明细"].push({
                                  Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                                  Wbsmc: data.Data.alist[0][2][i].BudgetName,
                                  Ysje: data.Data.alist[0][2][i].BudgetMoney,
                                  Sjje: arr[z].Money,
                                  Cgje: data.Data.alist[0][2][i].Cgje,
                                  RatioexcessRatio:
                                    data.Data.alist[0][2][i].RatioexcessRatio
                                });
                                flag = true;
                              }
                            }
                            if (!flag) {
                              detailData["超概申请审批单明细"].push({
                                Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                                Wbsmc: data.Data.alist[0][2][i].BudgetName,
                                Ysje: data.Data.alist[0][2][i].BudgetMoney,
                                Cgje: data.Data.alist[0][2][i].Cgje,
                                RatioexcessRatio:
                                  data.Data.alist[0][2][i].RatioexcessRatio
                              });
                            }
                          }
                          var params = {
                            templateid: 45,
                            formdataid: undefined,
                            noticeid: 0,
                            from: "",
                            mainData: mainData,
                            mainDataKz: [],
                            detailData: detailData
                          };
                          console.log(params);
                          MicroAppBaseLibrary.mpost(
                            "新建表单数据保存接口",
                            params,
                            function (data) {
                              if (data.Success) {
                                var params1 = {
                                  templateId: vueInst.fData._TEMPLATE_ID,
                                  formdataId: vueInst.fData.ID,
                                  State: 1 //冻结
                                };
                                MicroAppBaseLibrary.mpost(
                                  "概算冻结表单",
                                  params1,
                                  function (data1) {
                                    if (data1.Success) {
                                      vueInst.saveData(callBack);
                                    }
                                  },
                                  function (e) {
                                    console.error(
                                      "概算冻结表单" + "调取失败" + e.stack
                                    );
                                  },
                                  function (e) {
                                    console.error(
                                      "概算冻结表单" + "调取失败" + e.stack
                                    );
                                  },
                                  "api/budget"
                                );
                              }
                            },
                            function (e) {
                              console.error(
                                "新建表单数据保存接口" + "调取失败" + e.stack
                              );
                            }
                          );
                        })
                        .catch(() => {
                          console.log("已取消");
                        });
                    } else if (data.Data.alist[0][1].length > 0) {
                      //存在警告
                      Dialog.confirm({
                        title: "提示",
                        message:
                          data.Data.alist[0][1][0].BudgetName +
                          "该节点已使用超过" +
                          data.Data.alist[0][1][0].ratio +
                          "%"
                      })
                        .then(() => {
                          //正常确认后继续流传
                          vueInst.saveData(callBack);
                        })
                        .catch(() => {
                          console.log("提示");
                        });
                    } else if (data.Data.alist[0][0].length > 0) {
                      //正常
                      vueInst.saveData(callBack);
                    } else {
                      alert("概算编号未找到对应数据。");
                    }
                  },
                  function (e) {
                    //_this.showLoading = false;
                    console.error(
                      "校验概算节点的使用情况" + "调取失败" + e.stack
                    );
                    return true;
                  },
                  function (e) {
                    console.error(
                      "校验概算节点的使用情况" + "调取失败" + e.stack
                    );
                    return true;
                  },
                  "/api/budget"
                );
                return false;
              } else {
                return true;
              }
            } else if (vueInst && api.pageName == "FormAddPage") {
              if (vueInst.fData.Istrue) {
                alert("该合同编号变更存在审批中，不可重复添加单据！");
                return false;
              } else {
                if (vueInst.updatedDetailData.采购合同变更明细表.length == 0) {
                  vueInst.$toast("明细数据不能为空！");
                  return false;
                }
                MicroAppBaseLibrary.mpost(
                  "采购合同明细数量变更",
                  { htbh: vueInst.updatedMainData.Ycghtbh },
                  function (res) {
                    if (res.Success) {
                      var data = res.Data;
                      var bo = true;
                      for (var i = 0; i < data.length; i++) {
                        var Wzxydbh = data[i].Wzxydbh;
                        var Wzxydhh = data[i].Wzxydhh;
                        var mxData =
                          vueInst.updatedDetailData["采购合同变更明细表"];
                        for (var j = 0; j < mxData.length; j++) {
                          if (
                            mxData[j].Wzxydbh == Wzxydbh &&
                            mxData[j].Wzxydhh == Wzxydhh
                          ) {
                            if (
                              parseFloat(mxData[j].Sl) <
                              parseFloat(data[i].shsl)
                            ) {
                              bo = false;
                              Dialog.confirm({
                                title: "提示",
                                message:
                                  "变更明细表第" +
                                  (j + 1) +
                                  "行的数量不能小于已收货数量!"
                              })
                                .then(() => {
                                  //正常确认后继续流传
                                  return false;
                                })
                                .catch(() => {
                                  console.log("提示");
                                });
                              // alert("变更明细表第"+(j+1)+"行的数量不能小于已收货数量!");
                              // return false;
                            }
                          }
                        }
                      }
                      if (bo) {
                        vueInst.saveData(callBack);
                      } else {
                        return false;
                      }
                    }
                  },
                  function (e) {
                    console.error(
                      "采购合同明细数量变更" + "调取失败" + e.stack
                    );
                  },
                  function (e) {
                    console.error(
                      "采购合同明细数量变更" + "调取失败" + e.stack
                    );
                  },
                  "/api/PurchasingManagement"
                );
                return false;
              }
            }
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_15: {
      controlEvents: {
        CGHTID: {
          onChange: function (vueInst, value) {
            var params = {
              templateId: 10,
              formdataid: value
            };
            MicroAppBaseLibrary.mpost(
              "获取指定表单数据接口",
              params,
              function (data) {
                if (data.Success) {
                  var params1 = {
                    Htbh: data.Data.formdata.Htbm
                  };
                  MicroAppBaseLibrary.mpost(
                    "采购合同收货历史收货数量",
                    params1,
                    function (data1) {
                      if (data1.Success) {
                        if (data1.Data != null) {
                          if (data1.Data.list.length > 0) {
                            var Arr = [];
                            for (var i = 0; i < data1.Data.list.length; i++) {
                              for (
                                var j = 0;
                                j < data.Data.formdetailsdata[0].length;
                                j++
                              ) {
                                if (
                                  data.Data.formdetailsdata[0][j].Wzxydbh ==
                                    data1.Data.list[i].Wzxydbh &&
                                  data1.Data.list[i].Wzxydhh ==
                                    data.Data.formdetailsdata[0][j].Wzxydhh
                                ) {
                                  Arr.push({
                                    Mxsm: data.Data.formdetailsdata[0][j].Mxsm,
                                    Wzmc: data.Data.formdetailsdata[0][j].Wlmc,
                                    Ggxh: data.Data.formdetailsdata[0][j].Ggxh,
                                    Htsl: data.Data.formdetailsdata[0][j].Sl,
                                    Dw: data.Data.formdetailsdata[0][j].Jldw,
                                    YshSl: data1.Data.list[i].YshSl,
                                    Kcsl: data1.Data.list[i].Kcsl,
                                    WESbm: data1.Data.list[i].WESbm,
                                    WEBmc: data1.Data.list[i].WEBmc,
                                    Wzxydbh: data1.Data.list[i].Wzxydbh,
                                    Wzxydhh: data1.Data.list[i].Wzxydhh,
                                    SourceMxTableHH:
                                      data.Data.formdetailsdata[0][j]._HH,
                                    SourceDataID: data.Data.formdata.ID
                                  });
                                }
                              }
                            }
                            vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                              "采购合同收货明细表"
                            ] = [];
                            vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                              "采购合同收货明细表"
                            ] = [];
                            vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                              "采购合同收货明细表",
                              Arr
                            );
                          }
                        } else {
                          var Arr1 = [];
                          if (data.Data.formdetailsdata.length > 0) {
                            for (
                              var z = 0;
                              z < data.Data.formdetailsdata[0].length;
                              z++
                            ) {
                              Arr1.push({
                                Mxsm: data.Data.formdetailsdata[0][z].Mxsm,
                                Wzmc: data.Data.formdetailsdata[0][z].Wlmc,
                                Ggxh: data.Data.formdetailsdata[0][z].Ggxh,
                                Htsl: data.Data.formdetailsdata[0][z].Sl,
                                Dw: data.Data.formdetailsdata[0][z].Jldw,
                                YshSl: 0,
                                Kcsl: 0,
                                WESbm: data.Data.formdetailsdata[0][z].WBSbm,
                                WEBmc: data.Data.formdetailsdata[0][z].WBSmc,
                                Wzxydbh:
                                  data.Data.formdetailsdata[0][z].Wzxydbh,
                                Wzxydhh:
                                  data.Data.formdetailsdata[0][z].Wzxydhh,
                                SourceMxTableHH:
                                  data.Data.formdetailsdata[0][z]._HH,
                                SourceDataID: data.Data.formdata.ID
                              });
                            }
                            vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                              "采购合同收货明细表"
                            ] = [];
                            vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                              "采购合同收货明细表",
                              Arr1
                            );
                          }
                        }
                      }
                    },
                    function (e) {
                      //_this.showLoading = false;
                      console.error(
                        "采购合同收货历史收货数量" + "调取失败" + e.stack
                      );
                    },
                    function (e) {
                      //_this.showLoading = false;
                      console.error(
                        "采购合同收货历史收货数量" + "调取失败" + e.stack
                      );
                    },
                    "/api/PurchasingManagement"
                  );
                }
              },
              function (e) {
                //_this.showLoading = false;
                console.error("获取指定表单数据接口" + "调取失败" + e.stack);
              }
            );
          }
        }
      },
      formEvents: {
        afterLoadHtml: function () {
          setTimeout(() => {
            autoHeight();
          }, 1000);
        },
        beforeSaveData: function (vueInst) {
          if (vueInst) {
            if (vueInst.updatedMainData.Htlb == "闭口") {
              var bool = false;
              if (!vueInst.updatedDetailData.采购合同收货明细表) {
                return true;
              }
              for (
                var i = 0;
                i < vueInst.updatedDetailData.采购合同收货明细表.length;
                i++
              ) {
                var Htsl = parseFloat(
                  vueInst.updatedDetailData.采购合同收货明细表[i].Htsl
                ); //合同数量
                var Bcshsl = parseFloat(
                  vueInst.updatedDetailData.采购合同收货明细表[i].Bcshsl
                ); //本次收获数量
                var YshSl = parseFloat(
                  vueInst.updatedDetailData.采购合同收货明细表[i].YshSl
                ); //已收获数
                if (YshSl == Htsl) {
                  //如果以收获数量等于合同数量，不可进行提交
                  alert(
                    "已收货数量=合同数量，不可对物资名称为" +
                      vueInst.updatedDetailData.采购合同收货明细表[i].Wzmc +
                      "的该明细进行收货"
                  );
                  return false;
                } else if (Bcshsl + YshSl > Htsl) {
                  //已收货数量+本次收货数量大于合同数量，不可提交
                  alert(
                    "已收货数量+本次收货数量大于合同数量，不可对物资名称为" +
                      vueInst.updatedDetailData.采购合同收货明细表[i].Wzmc +
                      "的该明细进行收货"
                  );
                  return false;
                } else {
                  bool = true;
                }
                return bool;
              }
            } else {
              return true;
            }
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_19: {
      controlEvents: {
        Htbh: {
          onChange: function (vueInst, value) {
            var params = {
              Htbh: value
            };
            MicroAppBaseLibrary.mpost(
              "采购合同合同总额",
              params,
              function (data) {
                if (data.Success) {
                  if (data.Data != null) {
                    vueInst.cacheData.Ljyzfze = data.Data.money;
                    vueInst.$parent.$parent.$parent.$parent.fData.Ljyzfze =
                      data.Data.money;
                    vueInst.$parent.$parent.$parent.$parent.updatedMainData.Ljyzfze =
                      data.Data.money;

                    vueInst.cacheData.Zfzje = data.Data.spzMoney;
                    vueInst.$parent.$parent.$parent.$parent.fData.Zfzje =
                      data.Data.spzMoney;
                    vueInst.$parent.$parent.$parent.$parent.updatedMainData.Zfzje =
                      data.Data.spzMoney;
                  }
                  // vueInst.updatePageData("cacheData", vueInst.cacheData);
                }
              },
              function (e) {
                console.log(e.Message);
              },
              function (e) {
                console.log(e.Message);
              },
              "/api/PurchasingManagement"
            );
          }
        }
      },
      formEvents: {
        afterLoadHtml: function () {
          setTimeout(() => {
            autoHeight();
          }, 1000);
        },
        beforeSaveData: function (vueInst) {
          if (vueInst) {
            var bo = true;
            var Hjx = parseFloat(vueInst.updatedMainData.Hjx); //合计金额
            var Ljyzfze = parseFloat(vueInst.updatedMainData.Ljyzfze); //累计已支付总额
            var Htze = parseFloat(vueInst.updatedMainData.Htze); //合同总额
            var Zfzje = parseFloat(vueInst.updatedMainData.Zfzje); //支付中金额

            if (vueInst.updatedMainData.Fklx == "进度款") {
              if (
                vueInst.updatedMainData.Shdbh == undefined ||
                vueInst.updatedMainData.Shdbh == ""
              ) {
                alert("付款类型是进度款时，收货单编号为必填！");
                return (bo = false);
              }
            }
            if (vueInst.updatedMainData.Htlb == "闭口") {
              if (Hjx + Zfzje + Ljyzfze > Htze) {
                //付款明细总额+累计已付款金额>合同总额
                alert(
                  "付款明细总额+累计已付款金额+审批中的付款金额总和超过合同总额，无法付款！"
                );
                return (bo = false);
              }
            } else if (vueInst.updatedMainData.Htlb == "开口") {
              console.log("开口合同当付款明细总额不能大于收货金额");
              return (bo = true);
            }
            // if(Hjx>(Htze-Ljyzfze-Zfzje)){  //本次付款，合计小写不能超过剩余未付款金额
            //   alert("付款金额不能大于合同未支付金额！");
            //   return bo = false;
            // }
            return bo;
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_21: {
      controlEvents: {
        Fphm: {
          onChange: function (vueInst, value) {
            var params1 = {
              Fphm: value
            };
            MicroAppBaseLibrary.mpost(
              "采购发票号码唯一校验",
              params1,
              function (e) {
                console.error("采购发票号码唯一校验成功！" + e.stack);
              },
              function (e) {
                if (!e.Success) {
                  var data =
                    vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                      vueInst.detailName
                    ][vueInst.detailIndex];
                  data.Fphm = "";
                  vueInst.cacheData = data;
                  vueInst.updatePageData("cacheData", vueInst.cacheData);
                  alert(
                    "采购发票号码唯一校验:" + "发票号已使用，不能重复使用！"
                  );
                }
              },
              function (e) {
                console.error("采购发票号码唯一校验" + "调取失败" + e.stack);
              },
              "/api/PurchasingManagement"
            );
          }
        }
      },
      formEvents: {}
    },
    form_22: {
      controlEvents: {
        CGSQID: {
          onChange: function (vueInst, value) {
            var _value = value;
            var params1 = {
              OperationTemplateName: "采购需用关闭",
              SourceTemplateName: "采购申请",
              from: "",
              page: {
                page_index: 1,
                page_number: 10
              },
              search_key: "",
              filter: [{ key: "_PARENT_ID", value: _value, sqlOperator: "EN" }]
            };
            MicroAppBaseLibrary.mpost(
              "获取选入明细数据",
              params1,
              function (data1) {
                if (data1.Success) {
                  var Arrzb = [];
                  var Arrmxb = [];
                  var OperationFormFields =
                    data1.Data.OperationFormFields.split(","); //选择表
                  var SourceFormFields = data1.Data.SourceFormFields.split(","); //被选表
                  var d = data1.Data.data; //数据
                  for (var i = 0; i < d.length; i++) {
                    if (d[i]._PARENT_ID == value) {
                      var objzb = {};
                      var objmxb = {};
                      for (var z = 0; z < OperationFormFields.length; z++) {
                        if (OperationFormFields[z].indexOf("Mxb") >= 0) {
                          var S1 = OperationFormFields[z].split(".")[1];
                          var S2 = SourceFormFields[z].split(".")[1];
                          objmxb[S1] = d[i][S2];
                        } else if (OperationFormFields[z].indexOf("zb") >= 0) {
                          var S3 = OperationFormFields[z].split(".")[1];
                          var S4 = SourceFormFields[z].split(".")[1];
                          objzb[S3] = d[i][S4];
                        }
                      }
                      objmxb["_HH"] = d[i]._HH;
                      Arrzb.push(objzb);
                      Arrmxb.push(objmxb);
                    }
                  }
                  vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                    "采购需用关闭明细表"
                  ] = [];
                  vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                    "采购需用关闭明细表",
                    Arrmxb
                  );
                }
              },
              function (e) {
                console.error("获取选入明细数据" + "调取失败" + e.stack);
              },
              function (e) {
                console.error("获取选入明细数据" + "调取失败" + e.stack);
              },
              "/api/PurchasingManagement"
            );
          }
        }
      },
      formEvents: {
        beforeSaveData: function (vueInst) {
          if (vueInst) {
            if (vueInst.updatedMainData.Fklx == "进度款") {
              if (
                vueInst.updatedMainData.Shdbh == undefined ||
                vueInst.updatedMainData.Shdbh == ""
              ) {
                alert("付款类型是进度款时，收货单编号为必填！");
                return false;
              } else {
                var Hjx = parseFloat(vueInst.updatedMainData.Hjx); //合计金额
                var Ljyzfze = parseFloat(vueInst.updatedMainData.Ljyzfze); //累计已支付总额
                var Htze = parseFloat(vueInst.updatedMainData.Htze); //合同总额
                if (Hjx + Ljyzfze > Htze) {
                  //付款明细总额+累计已付款金额>合同总额
                  alert("付款明细总额+累计已付款金额超过合同总额，无法付款！");
                  return false;
                } else {
                  return true;
                }
              }
            } else {
              return true;
            }
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_25: {
      controlEvents: {
        Bjzj: {
          onChange: function (vueInst) {
            if (vueInst.cacheData.Bjzj <= 1000000) {
              //询比价
              vueInst.cacheData.Bjlx = "询比价";
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Bjlx =
                "询比价";
            } else if (
              vueInst.cacheData.Bjzj > 1000000 &&
              vueInst.cacheData.Bjzj < 4000000
            ) {
              vueInst.cacheData.Bjlx = "邀标";
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Bjlx =
                "邀标";
            } else if (vueInst.cacheData.Bjzj <= 4000000) {
              vueInst.cacheData.Bjlx = "公开招标";
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Bjlx =
                "公开招标";
            }
          }
        },
        Num: {
          onChange: function (vueInst) {
            if (!window.currentTreeSelectData) return;

            //判断明细编号
            setTimeout(() => {
              var details =
                vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                  "工程比价审批明细表"
                ];
              for (var i = 0; i < details.length; i++) {
                let data = details[i];
                data.GsNum = JSON.parse(data.GsNum).toString();

                if (
                  data.WBSbm &&
                  window.currentTreeSelectData[data.WBSbm] &&
                  data.Num &&
                  window.currentTreeSelectData[data.WBSbm].Num <
                    parseInt(data.Num)
                ) {
                  document.getElementsByName("Num")[i].parentNode.style.border =
                    "1px solid red";
                  document.getElementsByName("Num")[i].style.color = "red";
                } else {
                  document.getElementsByName("Num")[i].parentNode.style.border =
                    "0";
                  document.getElementsByName("Num")[i].style.color = "black";
                }
              }
            }, 100);
          }
        },
        WBSbm: {
          onChange: function (vueInst) {
            //判断明细编号
            setTimeout(() => {
              var details =
                vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                  "工程比价审批明细表"
                ];
              for (var i = 0; i < details.length; i++) {
                let data = details[i];
                if (
                  data.WBSbm &&
                  window.currentTreeSelectData[data.WBSbm] &&
                  data.Num &&
                  window.currentTreeSelectData[data.WBSbm].Num <
                    parseInt(data.Num)
                ) {
                  document.getElementsByName("Num")[i].parentNode.style.border =
                    "1px solid red";
                  document.getElementsByName("Num")[i].style.color = "red";
                } else {
                  document.getElementsByName("Num")[i].parentNode.style.border =
                    "0";
                  document.getElementsByName("Num")[i].style.color = "black";
                }
              }
            }, 100);
          }
        },
        Xmbh: {
          onChange: function (vueInst, value) {
            if (
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Xmbh !=
              value
            ) {
              vueInst.$parent.$parent.$parent.$parent.deleteAllDetailRows(
                "工程比价审批明细表"
              );
            }
          }
        }
      },
      formEvents: {
        afterGetFormData: function (vueInst, value) {
          console.log("表单加载后事件", vueInst, value);
          setTimeout(() => {
            var details = vueInst.formDetailTableData["工程比价审批明细表"];
            if (details) {
              for (var i = 0; i < details.length; i++) {
                let data = details[i];
                if (parseInt(data.GsNum) < parseInt(data.Num)) {
                  document.getElementsByName("Num")[i].style.color = "red";
                } else {
                  document.getElementsByName("Num")[i].style.color = "black";
                }
              }
            }
          }, 100);
        },
        afterDetailChange: function (vueInst, detailDataList) {
          //明细变更事件
          //计算含税总价 Hszj，不含税单价 Bhsdj，不含税总价 Bhszj，比价总价 Bjzj
          var total = 0;
          for (
            let index = 0;
            index < vueInst.updatedDetailData.工程比价审批明细表.length;
            index++
          ) {
            var detail = vueInst.updatedDetailData.工程比价审批明细表[index];
            if (!detail.Hsdj) continue;
            detail.Hszj = parseFloat(detail.Num) * parseFloat(detail.Hsdj);
            detail.Bhsdj = (
              parseFloat(detail.Hsdj) /
              (1 + parseFloat(detail.Sl))
            ).toFixed(2);
            detail.Bhszj = (detail.Hszj / (1 + parseFloat(detail.Sl))).toFixed(
              2
            );
            total += detail.Hszj;
          }
          if (
            JSON.stringify(vueInst.updatedDetailData.工程比价审批明细表) !=
            JSON.stringify(detailDataList.工程比价审批明细表)
          ) {
            detailDataList.工程比价审批明细表 = JSON.parse(
              JSON.stringify(vueInst.updatedDetailData.工程比价审批明细表)
            );
          }
          vueInst.updatedMainData.Bjzj = total;
          vueInst.fData.Bjzj = total;
        },
        beforeSaveData: function (vueInst, api, stateCaption, callBack) {
          if (vueInst && api.pageName == "FormAddPage") {
            var Bool = false;
            if (
              vueInst.updatedMainData.Sm == undefined ||
              vueInst.updatedMainData.Sm == ""
            ) {
              if (
                parseFloat(vueInst.updatedMainData.Bjzj) <= 1000000 &&
                vueInst.updatedMainData.Bjlx != "询比价"
              ) {
                //	比价类型：当比价明细总价小于等于50万时，比价类型自动选择“询比价”
                alert("比价总价与比价类型不符合，说明字段必填！");
                Bool = false;
              } else if (
                parseFloat(vueInst.updatedMainData.Bjzj) > 1000000 &&
                parseFloat(vueInst.updatedMainData.Bjzj) < 4000000 &&
                vueInst.updatedMainData.Bjlx != "邀标"
              ) {
                //	大于50万，小于400万时比价类型自动选择“邀标”
                alert("比价总价与比价类型不符合，说明字段必填！");
                Bool = false;
              } else if (
                parseFloat(vueInst.updatedMainData.Bjzj) >= 4000000 &&
                vueInst.updatedMainData.Bjlx != "公开招标"
              ) {
                //	大于等于400万时比价类型自动选择“公开招标”
                alert("比价总价与比价类型不符合，说明字段必填！");
                Bool = false;
              } else {
                Bool = true;
              }
            } else {
              Bool = true;
            }
            return Bool;
          } else if (vueInst && api.pageName == "FormProcessingPage") {
            var arr = [];
            if (stateCaption == "项目经理") {
              if (
                getFlowResult(vueInst.processNames, vueInst.processRadio) ==
                "不同意"
              )
                return true;
              for (var i = 0; i < vueInst.formDetailTableNames.length; i++) {
                for (
                  var z = 0;
                  z <
                  vueInst.formDetailsDataList[vueInst.formDetailTableNames[i]]
                    .length;
                  z++
                ) {
                  arr.push({
                    BudgetCode:
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].WBSbm,
                    Money:
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].Hszj
                  });
                }
              }
              vueInst.showLoading = true;
              var params = {
                data: arr,
                Leval: "1"
              };
              console.log(params);
              MicroAppBaseLibrary.mpost(
                "校验概算节点的使用情况",
                params,
                function (data) {
                  if (data.Data.alist[0][2].length > 0) {
                    //存在超概
                    Dialog.confirm({
                      title: "超概",
                      message:
                        data.Data.alist[0][2][0].BudgetName + "该节点已超概！"
                    })
                      .then(() => {
                        showLoading(vueInst);
                        //存在超概的情况下 生成超概申请单
                        //too1: 超概申请单生成成功后
                        console.log(vueInst);
                        var mainData = {
                          Ywdh: vueInst.fData.Bjdsqbh, //业务单号
                          Ywmc: vueInst.fData._SUBJECT + "超概申请单", //业务名称
                          Cglx: vueInst.fData._SUBJECT, //超概类型
                          Cgsm: "", //超概说明
                          Sqbm: vueInst.fData.Xqbm, //申请部门
                          Sqr: vueInst.fData.Xqbmjbr, //申请人
                          Sqsj: moment().format("YYYY-MM-DD HH:mm:ss"), //申请时间
                          XmjlYcy: vueInst.fData.XmjlYcy, //项目经理
                          RatioexcessLevel:
                            data.Data.alist[0][2][0].RatioexcessLevel,
                          IsRatioexcess: data.Data.alist[0][2][0].IsRatioexcess,
                          OtherCode: vueInst.fData.Bjdsqbh, //单号
                          Formnom: vueInst.fData._FORMNO //单号
                          // Wbsbm: data.Data.alist[0][2][0].BudgetCode, //Wbs编码
                          // Wbsmc: data.Data.alist[0][2][0].BudgetName, //Wbs名称
                          // Ysje: data.Data.alist[0][2][0].BudgetMoney, //预算金额
                          // Sjje: Sjje, //实际金额
                          // Cgje: parseFloat(Sjje - data.Data.alist[0][2][0].BudgetMoney), //超概金额
                        };
                        var detailData = {
                          超概申请审批单明细: []
                        };
                        for (let i = 0; i < data.Data.alist[0][2].length; i++) {
                          var flag = false;
                          for (let z = 0; z < arr.length; z++) {
                            if (
                              arr[z].BudgetCode ==
                              data.Data.alist[0][2][i].BudgetCode
                            ) {
                              detailData["超概申请审批单明细"].push({
                                Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                                Wbsmc: data.Data.alist[0][2][i].BudgetName,
                                Ysje: data.Data.alist[0][2][i].BudgetMoney,
                                Sjje: arr[z].Money,
                                Cgje: data.Data.alist[0][2][i].Cgje,
                                RatioexcessRatio:
                                  data.Data.alist[0][2][i].RatioexcessRatio
                              });
                              flag = true;
                            }
                          }
                          if (!flag) {
                            detailData["超概申请审批单明细"].push({
                              Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                              Wbsmc: data.Data.alist[0][2][i].BudgetName,
                              Ysje: data.Data.alist[0][2][i].BudgetMoney,
                              Cgje: data.Data.alist[0][2][i].Cgje,
                              RatioexcessRatio:
                                data.Data.alist[0][2][i].RatioexcessRatio
                            });
                          }
                        }
                        var params = {
                          templateid: 45,
                          formdataid: undefined,
                          noticeid: 0,
                          from: "",
                          mainData: mainData,
                          mainDataKz: [],
                          detailData: detailData
                        };
                        console.log(params);
                        MicroAppBaseLibrary.mpost(
                          "新建表单数据保存接口",
                          params,
                          function (data) {
                            if (data.Success) {
                              var params1 = {
                                templateId: vueInst.fData._TEMPLATE_ID,
                                formdataId: vueInst.fData.ID,
                                State: 1 //冻结
                              };
                              MicroAppBaseLibrary.mpost(
                                "概算冻结表单",
                                params1,
                                function (data1) {
                                  if (data1.Success) {
                                    vueInst.saveData(callBack);
                                  }
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单" + "调取失败" + e.stack
                                  );
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单" + "调取失败" + e.stack
                                  );
                                },
                                "/api/budget"
                              );
                            }
                          },
                          function (e) {
                            console.error(
                              "新建表单数据保存接口" + "调取失败" + e.stack
                            );
                          }
                        );
                      })
                      .catch(() => {
                        console.log("已取消");
                      });
                  } else if (data.Data.alist[0][1].length > 0) {
                    //存在警告
                    Dialog.confirm({
                      title: "提示",
                      message:
                        data.Data.alist[0][1][0].BudgetName +
                        "该节点已使用超过" +
                        data.Data.alist[0][1][0].ratio +
                        "%"
                    })
                      .then(() => {
                        //正常确认后继续流传
                        vueInst.saveData(callBack);
                      })
                      .catch(() => {
                        console.log("提示");
                      });
                  } else if (data.Data.alist[0][0].length > 0) {
                    //正常
                    vueInst.saveData(callBack);
                  } else {
                    alert("概算编号未找到对应数据。");
                  }
                },
                function (e) {
                  //_this.showLoading = false;
                  console.error(
                    "校验概算节点的使用情况" + "调取失败" + e.stack
                  );
                  return true;
                },
                function (e) {
                  console.error(
                    "校验概算节点的使用情况" + "调取失败" + e.stack
                  );
                  return true;
                },
                "/api/budget"
              );
              return false;
            } else {
              return true;
            }
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_27: {
      controlEvents: {
        Htlb: {
          onChange: function (vueInst, value) {
            if (value && value == "设计咨询") {
              document.getElementsByName("Xmmc")[0].readOnly = false;
              console.log(vueInst.cacheData.Htlb);
            } else {
              document.getElementsByName("Xmmc")[0].readOnly = true;
              console.log(vueInst.cacheData.Htlb);
            }

            if (value == "工程合同") {
              //工程合同不能手动新增
              vueInst.$parent.$parent.$parent.$parent.isNew = false;
            } else {
              vueInst.$parent.$parent.$parent.$parent.isNew = true;
            }
          }
        },
        Xmbh: {
          onChange: function (vueInst, value) {
            if (
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Xmbh !=
              value
            ) {
              vueInst.$parent.$parent.$parent.$parent.deleteAllDetailRows(
                "工程合同审批明细表"
              );
            }
          }
        }
      },
      formEvents: {
        DetailSelectType: true, //是否允许重复选择明细数据
        DetailKeyName: { key: "Tjcs", name: "中标供应商" },
        afterLoadHtml: function (vueInst) {
          if (!vueInst.fData.ID) {
            document.getElementsByName(
              "chakan3"
            )[0].parentNode.parentNode.style.display = "none";
            document.getElementsByName(
              "chakan2"
            )[0].parentNode.parentNode.style.display = "none";
          }
        },
        beforeSaveData: function (vueInst) {
          if (vueInst) return true;
          return false;
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        },
        beforeRenderData: function (vueInst) {
          if (vueInst.cacheData.HtbH) {
            MicroAppBaseLibrary.mpost(
              "工程合同合同总额",
              { Htbh: vueInst.cacheData.HtbH },
              function (res) {
                if (res.Success) {
                  var data = res.Data;
                  vueInst.cacheData.Ykpe = data.sfpMoney;
                  vueInst.cacheData.Yfkje = data.money;

                  vueInst.cacheData.Syfkje =
                    vueInst.cacheData.Htsjzjx - data.money;
                }
              },
              function (e) {
                console.error("工程合同合同总额" + "调取失败" + e.stack);
              },
              function (e) {
                console.error("工程合同合同总额" + "调取失败" + e.stack);
              },
              "/api/EngineeringManagement"
            );
          }
        }
      }
    },
    form_30: {
      controlEvents: {
        GCHTID: {
          onChange: function (vueInst, value) {
            var params = {
              templateId: 27,
              formdataid: value
            };
            MicroAppBaseLibrary.mpost(
              "获取指定表单数据接口",
              params,
              function (data) {
                if (data.Success) {
                  if (data.Data.formdetailsdata.length > 0) {
                    vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                      "工程合同变更明细表"
                    ] = [];
                    vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                      "工程合同变更明细表"
                    ] = [];
                    vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                      "工程合同变更明细表",
                      data.Data.formdetailsdata[0]
                    );
                  }
                }
              },
              function (e) {
                //_this.showLoading = false;
                console.error("获取指定表单数据接口" + "调取失败" + e.stack);
                return true;
              }
            );
          }
        },
        Yhtbh: {
          onChange: function (vueInst, value) {
            // setTimeout(() => {
            //   //记录原数据
            //   window.oldFormDetailData = JSON.parse(
            //     JSON.stringify(
            //       vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
            //         "工程合同变更明细表"
            //       ]
            //     )
            //   );
            //   console.log("选择原合同数据明细：", window.oldFormDetailData);
            // }, 1000);

            var params = {
              Htbh: value
            };
            MicroAppBaseLibrary.mpost(
              "工程合同变更保存前验证",
              params,
              function (data) {
                if (data.Success) {
                  vueInst.$parent.$parent.$parent.$parent.fData.Istrue =
                    data.Data;
                }
              },
              function (e) {
                //_this.showLoading = false;
                console.error("工程合同变更保存前验证" + "调取失败" + e.stack);
                return true;
              },
              function (e) {
                //_this.showLoading = false;
                console.error("工程合同变更保存前验证" + "调取失败" + e.stack);
                return true;
              },
              "/api/EngineeringManagement"
            );
          }
        },
        Status: {
          onChange: function (vueInst, value) {
            if (value == "删除") {
              vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                vueInst.detailName
              ][vueInst.detailIndex].Num = 0;
              vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                vueInst.detailName
              ][vueInst.detailIndex].Hszj = 0;
              vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                vueInst.detailName
              ][vueInst.detailIndex].Hsdj = 0;
              vueInst.cacheData =
                vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                  vueInst.detailName
                ][vueInst.detailIndex];
              vueInst.cacheData.Status = value;
              vueInst.updatePageData("cacheData", vueInst.cacheData);
            }
          }
        }
      },
      formEvents: {
        afterGetFormData: function (vueInst) {
          //绑定明细状态
          setTimeout(() => {
            var details = vueInst.formDetailsDataList["工程合同变更明细表"];
            if (!details || details.length == 0) return;

            var items = document.getElementsByName("Status");
            for (let i = 0; i < items.length; i++) {
              var color = getColor(items[i].innerText);
              items[i].parentNode.parentNode.parentNode.style.backgroundColor =
                color;
            }
          }, 100);

          function getColor(status) {
            switch (status) {
              case "新增":
                return "#00f800";
              case "变更":
                return "yellow";
              case "删除":
                return "red";
              default:
                return "";
            }
          }
        },
        formDetailDeleteEvent: function (vueInst, e) {
          //验证明细删除
          var flag =
            !vueInst.updatedDetailData[e.detailName][e.detailIndex].Bjddh;
          if (!flag) {
            alert("本行明细只允许选择变更状态");
          }
          return flag;
        },
        beforeRenderData: function (vueInst, api) {
          if (
            api.detailName &&
            vueInst.$parent.$parent.$parent.$parent.api.pageName ==
              "FormAddPage"
          ) {
            var canSelectedOptions = statusSelectList(api.formData.Hszj);
            vueInst.cacheOptions.Status = canSelectedOptions;
            if (canSelectedOptions.length == 1)
              vueInst.cacheData.Status = "新增";
            else vueInst.cacheData.Status = "未变更";
            vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
              api.detailName
            ][api.detailIndex].Status = vueInst.cacheData.Status;
            vueInst.updatePageData("cacheData", vueInst.cacheData);
          }
        },
        beforeSaveData: function (vueInst, api, stateCaption, callBack) {
          if (vueInst && api.pageName == "FormProcessingPage") {
            var arr = [];
            if (stateCaption == "项目经理" || stateCaption == "工程部经理") {
              if (
                getFlowResult(vueInst.processNames, vueInst.processRadio) ==
                "不同意"
              )
                return true;
              for (var i = 0; i < vueInst.formDetailTableNames.length; i++) {
                for (
                  var z = 0;
                  z <
                  vueInst.formDetailsDataList[vueInst.formDetailTableNames[i]]
                    .length;
                  z++
                ) {
                  arr.push({
                    BudgetCode:
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].WBSbm,
                    Money:
                      vueInst.formDetailsDataList[
                        vueInst.formDetailTableNames[i]
                      ][z].Hszj
                  });
                }
              }
              var params = {
                data: arr,
                Leval: "1"
              };
              console.log(params);
              MicroAppBaseLibrary.mpost(
                "校验概算节点的使用情况",
                params,
                function (data) {
                  if (data.Data.alist[0][2].length > 0) {
                    //存在超概
                    Dialog.confirm({
                      title: "超概",
                      message:
                        data.Data.alist[0][2][0].BudgetName + "该节点已超概！"
                    })
                      .then(() => {
                        //存在超概的情况下 生成超概申请单
                        //too1: 超概申请单生成成功后
                        console.log(vueInst);
                        var mainData = {
                          Ywdh: vueInst.fData.Bgsqbh, //业务单号
                          Ywmc: vueInst.fData._SUBJECT + "超概申请单", //业务名称
                          Cglx: vueInst.fData._SUBJECT, //超概类型
                          Cgsm: "", //超概说明
                          Sqbm: vueInst.fData.Zbbm, //申请部门
                          Sqr: vueInst.fData.Jbr, //申请人
                          Sqsj: moment().format("YYYY-MM-DD HH:mm:ss"), //申请时间
                          XmjlYcy: JSON.parse(localStorage.userinfo).account, //项目经理
                          RatioexcessLevel:
                            data.Data.alist[0][2][0].RatioexcessLevel,
                          IsRatioexcess: data.Data.alist[0][2][0].IsRatioexcess,
                          OtherCode: vueInst.fData.Bgsqbh,
                          Formnom: vueInst.fData._FORMNO //单号
                          // Wbsbm: data.Data.alist[0][2][0].BudgetCode, //Wbs编码
                          // Wbsmc: data.Data.alist[0][2][0].BudgetName, //Wbs名称
                          // Ysje: data.Data.alist[0][2][0].BudgetMoney, //预算金额
                          // Sjje: Sjje, //实际金额
                          // Cgje: parseFloat(Sjje - data.Data.alist[0][2][0].BudgetMoney), //超概金额
                        };
                        var detailData = {
                          超概申请审批单明细: []
                        };
                        for (let i = 0; i < data.Data.alist[0][2].length; i++) {
                          var flag = false;
                          for (let z = 0; z < arr.length; z++) {
                            if (
                              arr[z].BudgetCode ==
                              data.Data.alist[0][2][i].BudgetCode
                            ) {
                              detailData["超概申请审批单明细"].push({
                                Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                                Wbsmc: data.Data.alist[0][2][i].BudgetName,
                                Ysje: data.Data.alist[0][2][i].BudgetMoney,
                                Sjje: arr[z].Money,
                                Cgje: data.Data.alist[0][2][i].Cgje,
                                RatioexcessRatio:
                                  data.Data.alist[0][2][i].RatioexcessRatio
                              });
                              flag = true;
                            }
                          }
                          if (!flag) {
                            detailData["超概申请审批单明细"].push({
                              Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                              Wbsmc: data.Data.alist[0][2][i].BudgetName,
                              Ysje: data.Data.alist[0][2][i].BudgetMoney,
                              Cgje: data.Data.alist[0][2][i].Cgje,
                              RatioexcessRatio:
                                data.Data.alist[0][2][i].RatioexcessRatio
                            });
                          }
                        }
                        var params = {
                          templateid: 45,
                          formdataid: undefined,
                          noticeid: 0,
                          from: "",
                          mainData: mainData,
                          mainDataKz: [],
                          detailData: detailData
                        };
                        console.log(params);
                        MicroAppBaseLibrary.mpost(
                          "新建表单数据保存接口",
                          params,
                          function (data) {
                            if (data.Success) {
                              var params1 = {
                                templateId: vueInst.fData._TEMPLATE_ID,
                                formdataId: vueInst.fData.ID,
                                State: 1 //冻结
                              };
                              MicroAppBaseLibrary.mpost(
                                "概算冻结表单",
                                params1,
                                function (data1) {
                                  if (data1.Success) {
                                    vueInst.saveData(callBack);
                                  }
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单 调取失败" + e.stack
                                  );
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单 调取失败" + e.stack
                                  );
                                },
                                "api/budget"
                              );
                            }
                          },
                          function (e) {
                            console.error(
                              "新建表单数据保存接口" + "调取失败" + e.stack
                            );
                          }
                        );
                      })
                      .catch(() => {
                        console.log("已取消");
                      });
                  } else if (data.Data.alist[0][1].length > 0) {
                    //存在警告
                    Dialog.confirm({
                      title: "提示",
                      message:
                        data.Data.alist[0][1][0].BudgetName +
                        "该节点已使用超过" +
                        data.Data.alist[0][1][0].ratio +
                        "%"
                    })
                      .then(() => {
                        //正常确认后继续流传
                        vueInst.saveData(callBack);
                      })
                      .catch(() => {
                        console.log("提示");
                      });
                  } else if (data.Data.alist[0][0].length > 0) {
                    //正常
                    vueInst.saveData(callBack);
                  } else {
                    alert("概算编号未找到对应数据。");
                  }
                },
                function (e) {
                  //_this.showLoading = false;
                  console.error(
                    "校验概算节点的使用情况" + "调取失败" + e.stack
                  );
                  return true;
                },
                function (e) {
                  console.error(
                    "校验概算节点的使用情况" + "调取失败" + e.stack
                  );
                  return true;
                },
                "/api/budget"
              );
              return false;
            } else {
              return true;
            }
          } else if (vueInst && api.pageName == "FormAddPage") {
            if (vueInst) {
              // if(window.oldFormDetailData){
              //   //对比明细状态
              //   var currentDetailData =
              //     vueInst.updatedDetailData["工程合同变更明细表"];
              //   for (var j = 0; j < currentDetailData.length; j++) {
              //     var flag = isExists(
              //       currentDetailData[j],
              //       window.oldFormDetailData
              //     );
              //     if (flag === 0) {
              //       window.oldFormDetailData.push(currentDetailData[j]);
              //     }
              //   }
              //   window.oldFormDetailData.forEach((item) => {
              //     if (!item.Status) item.Status = "删除";
              //   });
              //   vueInst.updatedDetailData["工程合同变更明细表"] =
              //     window.oldFormDetailData;
              // }

              if (vueInst.fData.Istrue) {
                alert("该合同编号变更存在审批中，不可重复添加单据！");
                return false;
              } else {
                MicroAppBaseLibrary.mpost(
                  "工程累计金额及报量",
                  { Htbh: vueInst.updatedMainData.Yhtbh },
                  function (res) {
                    if (res.Success) {
                      var data = res.Data.data;
                      var bo = true;
                      for (var i = 0; i < data.length; i++) {
                        var SourceDataID = data[i].SourceDataID;
                        var SourceMxTableHH = data[i].SourceMxTableHH;
                        var mxData =
                          vueInst.updatedDetailData["工程合同变更明细表"];
                        for (var j = 0; j < mxData.length; j++) {
                          if (
                            mxData[j].SourceDataID == SourceDataID &&
                            mxData[j].SourceMxTableHH == SourceMxTableHH
                          ) {
                            if (
                              parseFloat(mxData[j].Num) <
                              parseFloat(data[i].Ljbsl)
                            ) {
                              bo = false;
                              Dialog.confirm({
                                title: "提示",
                                message:
                                  "变更明细表第" +
                                  (j + 1) +
                                  "行的数量不能小于已报量数量（" +
                                  data[i].Ljbsl +
                                  "）!"
                              })
                                .then(() => {
                                  //正常确认后继续流传
                                  return false;
                                })
                                .catch(() => {
                                  console.log("提示");
                                });
                              // alert("变更明细表第"+(j+1)+"行的数量不能小于已收货数量!");
                              // return false;
                            }
                            if (
                              parseFloat(mxData[j].Hszj) <
                              parseFloat(data[i].Ljybje)
                            ) {
                              bo = false;
                              Dialog.confirm({
                                title: "提示",
                                message:
                                  "变更明细表第" +
                                  (j + 1) +
                                  "行的含税总价不能小于已报量核准金额(" +
                                  data[i].Ljybje +
                                  ")!"
                              })
                                .then(() => {
                                  //正常确认后继续流传
                                  return false;
                                })
                                .catch(() => {
                                  console.log("提示");
                                });
                            }
                          }
                        }
                      }
                      if (bo) {
                        vueInst.saveData(callBack);
                      } else {
                        return false;
                      }
                    }
                  },
                  function (e) {
                    console.error("工程合同变更明细表" + "调取失败" + e.stack);
                  },
                  function (e) {
                    console.error("工程合同变更明细表" + "调取失败" + e.stack);
                  },
                  "/api/EngineeringManagement"
                );
                return false;
              }
            }
          }
          //判断是否存在对象
          // function isExists(obj, arr) {
          //   for (let k = 0; k < arr.length; k++) {
          //     const item = arr[k];
          //     if (item._HH == obj._HH) {
          //       //判断对象是否相等
          //       if (JSON.stringify(obj) == JSON.stringify(item)) {
          //         item.Status = "保存"; //未修改
          //       } else {
          //         obj.Status = "修改"; //已修改
          //         Object.assign(item, obj);
          //       }
          //       return 1;
          //     }
          //   }
          //   obj.Status = "新增";
          //   return 0; //不存在
          // }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_32: {
      controlEvents: {
        GCHTID: {
          onChange: function (vueInst, value) {
            var params = {
              templateId: 27,
              formdataid: value
            };
            MicroAppBaseLibrary.mpost(
              "获取指定表单数据接口",
              params,
              function (data) {
                if (data.Success) {
                  var params1 = {
                    Htbh: data.Data.formdata.Htbh
                  };
                  var details = changeToDic(data.Data.formdetailsdata[0]);
                  MicroAppBaseLibrary.mpost(
                    "工程累计金额及报量",
                    params1,
                    function (data1) {
                      if (data1.Success) {
                        if (data1.Data != null) {
                          if (data1.Data.list.length > 0) {
                            var Arr = [];
                            for (var i = 0; i < data1.Data.list.length; i++) {
                              Arr.push({
                                Blsm: data1.Data.list[i].Blsm,
                                Htsl: details[data1.Data.list[i]._HH].Num,
                                Dw: data1.Data.list[i].Dw,
                                Htjg: details[data1.Data.list[i]._HH].Hsdj,
                                Htje: details[data1.Data.list[i]._HH].Hszj,
                                Ljbsl: data1.Data.list[i].Ljbsl,
                                Ljybje: data1.Data.list[i].Ljybje,
                                _HH: (z + 1) * 10,
                                WBSbm: data1.Data.list[i].WBSbm,
                                WBSmc: data1.Data.list[i].WBSmc,
                                SourceMxTableHH:
                                  data1.Data.list[i].SourceMxTableHH,
                                SourceDataID: data1.Data.list[i].SourceDataID
                              });
                            }
                            vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                              "工程合同报量明细表"
                            ] = [];
                            vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                              "工程合同报量明细表"
                            ] = [];
                            vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                              "工程合同报量明细表",
                              Arr
                            );
                          }
                        } else {
                          var Arr1 = [];
                          if (data.Data.formdetailsdata.length > 0) {
                            for (
                              var z = 0;
                              z < data.Data.formdetailsdata[0].length;
                              z++
                            ) {
                              Arr1.push({
                                Blsm: data.Data.formdetailsdata[0][z].Xmnr,
                                Htsl: data.Data.formdetailsdata[0][z].Num,
                                Dw: data.Data.formdetailsdata[0][z].Dw,
                                Htjg: data.Data.formdetailsdata[0][z].Bhsdj,
                                Htje: data.Data.formdetailsdata[0][z].Hszj,
                                Ljbsl: 0,
                                Ljybje: 0,
                                _HH: (z + 1) * 10,
                                WBSbm: data.Data.formdetailsdata[0][z].WBSbm,
                                WBSmc: data.Data.formdetailsdata[0][z].WBSmc,
                                SourceMxTableHH:
                                  data.Data.formdetailsdata[0][z]
                                    .SourceMxTableHH,
                                SourceDataID:
                                  data.Data.formdetailsdata[0][z].SourceDataID
                              });
                            }
                            vueInst.$parent.$parent.$parent.$parent.formDetailsDataList[
                              "工程合同报量明细表"
                            ] = [];
                            vueInst.$parent.$parent.$parent.$parent.addDetailRows(
                              "工程合同报量明细表",
                              Arr1
                            );
                          }
                        }
                      }
                    },
                    function (e) {
                      //_this.showLoading = false;
                      console.error(
                        "工程合同报量明细表" + "调取失败" + e.stack
                      );
                    },
                    function (e) {
                      //_this.showLoading = false;
                      console.error(
                        "工程合同报量明细表" + "调取失败" + e.stack
                      );
                    },
                    "/api/EngineeringManagement"
                  );
                }
              },
              function (e) {
                //_this.showLoading = false;
                console.error("获取指定表单数据接口" + "调取失败" + e.stack);
              }
            );
          }
        }
      },
      formEvents: {
        validateFlow: true, //是否提交验证
        beforeSaveData: function (vueInst) {
          if (vueInst) {
            var flag = vueInst.updatedMainData.Htlx == "闭口";
            var bool = true;
            for (
              var i = 0;
              i < vueInst.updatedDetailData.工程合同报量明细表.length;
              i++
            ) {
              var Bcbsl = parseFloat(
                vueInst.updatedDetailData.工程合同报量明细表[i].Bcbsl
              ); //本次报送量
              // var Ljbsl = parseFloat(
              //   vueInst.updatedDetailData.工程合同报量明细表[i].Ljbsl
              // ); //累计报送量
              var Bcbszje = parseFloat(
                vueInst.updatedDetailData.工程合同报量明细表[i].Bcbszje
              ); //本次报送金额
              var Ljybje = parseFloat(
                vueInst.updatedDetailData.工程合同报量明细表[i].Ljybje
              ); //累计已报送金
              var Htje = parseFloat(
                vueInst.updatedDetailData.工程合同报量明细表[i].Htje
              ); //合同金额
              // var Htsl = parseFloat(
              //   vueInst.updatedDetailData.工程合同报量明细表[i].Htsl
              // ); //合同数量

              var Bchzsl = parseFloat(
                vueInst.updatedDetailData.工程合同报量明细表[i].Bchzsl
              ); //本次核准数量
              var Bczje = parseFloat(
                vueInst.updatedDetailData.工程合同报量明细表[i].Bczje
              ); //本次核准金额

              //if (Bcbsl + Ljbsl > Htsl && flag) {
              //如果以收获数量等于合同数量，不可进行提交
              // vueInst.$toast("本次报送量+累计报送量，不可大于合同数量");
              // return false;
              //} else
              if (Bcbszje + Ljybje > Htje && flag) {
                //已收货数量+本次收货数量大于合同数量，不可提交
                vueInst.$toast(
                  "明细第" +
                    (i + 1) +
                    "行：本次报送金额+累计已报金额，不可大于合同金额"
                );
                return false;
              } else if (Bcbsl < Bchzsl) {
                vueInst.$toast(
                  "明细第" + (i + 1) + "行：本次核准数量是不能大于本次报送数量"
                );
                return false;
              }
              // else if (Bcbsl > Htsl && flag) {
              //   vueInst.$toast("本次报送数量，不可大于合同数量");
              //   return false;
              // }
              else if (Bczje > Bcbszje) {
                vueInst.$toast(
                  "明细第" + (i + 1) + "行：本次核准金额，不可大于本次报送金额"
                );
                return false;
              } else if (Bczje > Htje && flag) {
                vueInst.$toast(
                  "明细第" + (i + 1) + "行：本次核准金额，不可大于合同金额"
                );
                return false;
              }
            }
            return bool;
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        },
        beforeRenderData: function (vueInst) {
          if (vueInst.cacheData.Htbh) {
            MicroAppBaseLibrary.mpost(
              "工程合同已开发票金额",
              { Htbh: vueInst.cacheData.Htbh },
              function (res) {
                if (res.Success) {
                  var data = res.Data;
                  vueInst.cacheData.Ykpe = data.sfpMoney;
                  vueInst.cacheData.Yfkje = data.money;
                  vueInst.cacheData.Yfkbl =
                    (
                      (parseFloat(data.money) * 100) /
                      parseFloat(vueInst.cacheData.Htsjzjx)
                    ).toFixed(2) + "%";
                  vueInst.cacheData.Syfkje =
                    vueInst.cacheData.Htsjzjx - data.money;
                }
              },
              function (e) {
                console.error("工程合同已开发票金额" + "调取失败" + e.stack);
              },
              function (e) {
                console.error("工程合同已开发票金额" + "调取失败" + e.stack);
              },
              "/api/EngineeringManagement"
            );
          }
        }
      }
    },
    form_35: {
      controlEvents: {
        Htbh: {
          onChange: function (vueInst, value) {
            var params = {
              Htbh: value
            };
            MicroAppBaseLibrary.mpost(
              "工程合同合同总额",
              params,
              function (data) {
                if (data.Success) {
                  if (data.Data != null) {
                    vueInst.$parent.$parent.$parent.$parent.fData.Htbh = value;

                    vueInst.cacheData.Ljzfkje = data.Data.money;
                    vueInst.$parent.$parent.$parent.$parent.fData.Ljzfkje =
                      data.Data.money;
                    vueInst.$parent.$parent.$parent.$parent.updatedMainData.Ljzfkje =
                      data.Data.money;

                    vueInst.cacheData.Zfzje = data.Data.spzMoney;
                    vueInst.$parent.$parent.$parent.$parent.fData.Zfzje =
                      data.Data.spzMoney;
                    vueInst.$parent.$parent.$parent.$parent.updatedMainData.Zfzje =
                      data.Data.spzMoney;
                  }
                  // vueInst.updatePageData("cacheData", vueInst.cacheData);
                }
              },
              function (e) {
                console.log(e.Message);
              },
              function (e) {
                console.log(e.Message);
              },
              "/api/EngineeringManagement"
            );
          }
        },
        Blddh: {
          onChange: function (vueInst) {
            var params = {
              Htbh: vueInst.$parent.$parent.$parent.$parent.fData.Htbh
            };
            MicroAppBaseLibrary.mpost(
              "工程合同合同总额",
              params,
              function (data) {
                if (data.Success) {
                  if (data.Data != null) {
                    vueInst.cacheData.Ljzfkje = data.Data.money;
                    vueInst.$parent.$parent.$parent.$parent.fData.Ljzfkje =
                      data.Data.money;
                    vueInst.$parent.$parent.$parent.$parent.updatedMainData.Ljzfkje =
                      data.Data.money;

                    vueInst.cacheData.Zfzje = data.Data.spzMoney;
                    vueInst.$parent.$parent.$parent.$parent.fData.Zfzje =
                      data.Data.spzMoney;
                    vueInst.$parent.$parent.$parent.$parent.updatedMainData.Zfzje =
                      data.Data.spzMoney;
                  }
                  // vueInst.updatePageData("cacheData", vueInst.cacheData);
                }
              },
              function (e) {
                console.log(e.Message);
              },
              function (e) {
                console.log(e.Message);
              },
              "/api/EngineeringManagement"
            );
          }
        },
        Pzje: {
          onChange: function (vueInst, value) {
            //如果是进度款，带出合计
            if (
              window.currentFormData &&
              window.currentFormData.Fklx == "进度款"
            ) {
              setTimeout(() => {
                vueInst.$parent.$parent.$parent.$parent.fData.Htx = value;
                vueInst.$parent.$parent.$parent.$parent.updatedMainData.Htx =
                  value;
                vueInst.updatePageData(
                  "cacheData",
                  vueInst.$parent.$parent.$parent.$parent.updatedMainData
                );
                setTimeout(() => {
                  document
                    .getElementsByName("Htx")[0]
                    .dispatchEvent(new Event("change"));
                }, 100);
              }, 100);
            }
          }
        },
        Fklx: {
          onChange: function (vueInst, value) {
            //如果是进度款，带出合计
            if (
              window.currentFormData &&
              value == "进度款" &&
              window.currentFormData.Pzje
            ) {
              vueInst.$parent.$parent.$parent.$parent.fData.Htx =
                window.currentFormData.Pzje;
              vueInst.$parent.$parent.$parent.$parent.updatedMainData.Htx =
                window.currentFormData.Pzje;
              vueInst.updatePageData(
                "cacheData",
                vueInst.$parent.$parent.$parent.$parent.updatedMainData
              );
              setTimeout(() => {
                document
                  .getElementsByName("Htx")[0]
                  .dispatchEvent(new Event("change"));
              }, 100);
            }
          }
        }
      },
      formEvents: {
        beforeSaveData: function (vueInst) {
          if (vueInst.updatedMainData.Fklx == "进度款") {
            if (vueInst.updatedMainData.Blddh == undefined) {
              alert("保存失败：付款类型为进度款，需要关联报量单单号！");
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_37: {
      controlEvents: {
        Skr: {
            onChange: function(vueInst, value) {
                var params = {
                    departmentId: ""
                };
                MicroAppBaseLibrary.mpost("根据部门获取所属用户记录接口", params, function(res) {
                        if (res.Success) {
                            var Data = vueInst.$parent.$parent.$parent.$parent.updatedMainData;
                            for (var i = 0; i < res.Data.datalist.length; i++) {
                                if (value == res.Data.datalist[i].name) {
                                    for (var z = 0; z < res.Data.datalist[i].exField.length; z++) {
                                        Data[res.Data.datalist[i].exField[z]["exFieldName"]] = res.Data.datalist[i].exField[z].value;
                                    }
                                    break;
                                }
                            }
                            vueInst.cacheData = Data;
                            vueInst.$parent.$parent.$parent.$parent.fData = Data
                                // vueInst.$parent.$parent.$parent.$parent.updatedMainData = Data;
                                // vueInst.updatePageData("cacheData", Data);
                        }
                    },
                    function(e) {
                        //_this.showLoading = false;
                        console.error("根据部门获取所属用户记录接口" + "调取失败" + e.stack);
                        return true;
                    }
                );

            }
        },
        Xmbh: {
          onChange: function (vueInst, value) {
            var className =
              document.getElementsByName("WBSbm")[0].parentNode.className;
            if (value.substr(0, 1) == "T") {
              //隐藏星号
              setTimeout(() => {
                document.getElementsByName("WBSbm")[0].parentNode.className =
                  className.replace("oa-form-required", "");
              }, 1000);
            } else {
              //显示星号
              if (className.indexOf("oa-form-required") == -1) {
                setTimeout(() => {
                  document.getElementsByName("WBSbm")[0].parentNode.className =
                    className + " oa-form-required";
                }, 1000);
              }
            }
          }
        }
      },
      formEvents: {
        beforeRenderData: function (vueInst) {
          var className =
            document.getElementsByName("WBSbm")[0].parentNode.className;
          var xmbh =
            vueInst.$parent.$parent.$parent.$parent.updatedMainData.Xmbh;
          if (xmbh.substr(0, 1) == "T") {
            //隐藏星号
            setTimeout(() => {
              document.getElementsByName("WBSbm")[0].parentNode.className =
                className.replace("oa-form-required", "");
            }, 1000);
          } else {
            //显示星号
            if (className.indexOf("oa-form-required") == -1) {
              setTimeout(() => {
                document.getElementsByName("WBSbm")[0].parentNode.className =
                  className + " oa-form-required";
              }, 1000);
            }
          }
        },
        validateField: function (vueInst) {
          if (
            vueInst.updatedMainData.Xmbh &&
            vueInst.updatedMainData.Xmbh.substr(0, 1) == "T"
          ) {
            var rules = vueInst.form.formValidateRules.thisRules;
            for (var i = 0; i < rules.length; i++) {
              var item = rules[i];
              if (item.name == "WBSbm") {
                rules.splice(i, 1);
                break;
              }
            }
          }
        },
        beforeSaveData: function (vueInst, api, stateCaption, callBack) {
          if (vueInst && api.pageName == "FormProcessingPage") {
            var arr = [];
            if (stateCaption == "项目经理" && vueInst.updatedMainData.WBSbm) {
              if (
                getFlowResult(vueInst.processNames, vueInst.processRadio) ==
                "不同意"
              )
                return true;
              arr.push({
                BudgetCode: vueInst.updatedMainData.WBSbm,
                Money: vueInst.updatedMainData.Hjx
              });
              var params = {
                data: arr,
                Leval: "1"
              };
              console.log(params);
              MicroAppBaseLibrary.mpost(
                "校验概算节点的使用情况",
                params,
                function (data) {
                  if (data.Data.alist[0][2].length > 0) {
                    //存在超概
                    Dialog.confirm({
                      title: "超概",
                      message:
                        data.Data.alist[0][2][0].BudgetName + "该节点已超概！"
                    })
                      .then(() => {
                        //存在超概的情况下 生成超概申请单
                        //too1: 超概申请单生成成功后
                        console.log(vueInst);
                        var mainData = {
                          Ywdh: vueInst.fData.Bxdbh, //业务单号
                          Ywmc: vueInst.fData._SUBJECT + "超概申请单", //业务名称
                          Cglx: vueInst.fData._SUBJECT, //超概类型
                          Cgsm: "", //超概说明
                          Sqbm: vueInst.fData.Sqbm, //申请部门
                          Sqr: vueInst.fData.Jbr, //申请人
                          Sqsj: moment().format("YYYY-MM-DD HH:mm:ss"), //申请时间
                          XmjlYcy: vueInst.fData.XmjlYcy, //项目经理
                          RatioexcessLevel:
                            data.Data.alist[0][2][0].RatioexcessLevel,
                          IsRatioexcess: data.Data.alist[0][2][0].IsRatioexcess,
                          OtherCode: vueInst.fData.Bxdbh, //单号
                          Formnom: vueInst.fData._FORMNO //单号
                          // Wbsbm: data.Data.alist[0][2][0].BudgetCode, //Wbs编码
                          // Wbsmc: data.Data.alist[0][2][0].BudgetName, //Wbs名称
                          // Ysje: data.Data.alist[0][2][0].BudgetMoney, //预算金额
                          // Sjje: Sjje, //实际金额
                          // Cgje: parseFloat(Sjje - data.Data.alist[0][2][0].BudgetMoney), //超概金额
                        };
                        var detailData = {
                          超概申请审批单明细: []
                        };
                        for (let i = 0; i < data.Data.alist[0][2].length; i++) {
                          var flag = false;
                          for (let z = 0; z < arr.length; z++) {
                            if (
                              arr[z].BudgetCode ==
                              data.Data.alist[0][2][i].BudgetCode
                            ) {
                              detailData["超概申请审批单明细"].push({
                                Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                                Wbsmc: data.Data.alist[0][2][i].BudgetName,
                                Ysje: data.Data.alist[0][2][i].BudgetMoney,
                                Sjje: arr[z].Money,
                                Cgje: data.Data.alist[0][2][i].Cgje,
                                RatioexcessRatio:
                                  data.Data.alist[0][2][i].RatioexcessRatio
                              });
                              flag = true;
                            }
                          }
                          if (!flag) {
                            detailData["超概申请审批单明细"].push({
                              Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                              Wbsmc: data.Data.alist[0][2][i].BudgetName,
                              Ysje: data.Data.alist[0][2][i].BudgetMoney,
                              Cgje: data.Data.alist[0][2][i].Cgje,
                              RatioexcessRatio:
                                data.Data.alist[0][2][i].RatioexcessRatio
                            });
                          }
                        }
                        var params = {
                          templateid: 45,
                          formdataid: undefined,
                          noticeid: 0,
                          from: "",
                          mainData: mainData,
                          mainDataKz: [],
                          detailData: detailData
                        };
                        console.log(params);
                        MicroAppBaseLibrary.mpost(
                          "新建表单数据保存接口",
                          params,
                          function (data) {
                            if (data.Success) {
                              var params1 = {
                                templateId: vueInst.fData._TEMPLATE_ID,
                                formdataId: vueInst.fData.ID,
                                State: 1 //冻结
                              };
                              MicroAppBaseLibrary.mpost(
                                "概算冻结表单",
                                params1,
                                function (data1) {
                                  if (data1.Success) {
                                    vueInst.saveData(callBack);
                                  }
                                },
                                function (e) {
                                  console.error(
                                    ["概算冻结表单"] + "调取失败" + e.stack
                                  );
                                },
                                function (e) {
                                  console.error(
                                    ["概算冻结表单"] + "调取失败" + e.stack
                                  );
                                },
                                "api/budget"
                              );
                            }
                          },
                          function (e) {
                            console.error(
                              ["新建表单数据保存接口"] + "调取失败" + e.stack
                            );
                          }
                        );
                      })
                      .catch(() => {
                        console.log("已取消");
                      });
                  } else if (data.Data.alist[0][1].length > 0) {
                    //存在警告
                    Dialog.confirm({
                      title: "提示",
                      message:
                        data.Data.alist[0][1][0].BudgetName +
                        "该节点已使用超过" +
                        data.Data.alist[0][1][0].ratio +
                        "%"
                    })
                      .then(() => {
                        //正常确认后继续流传
                        vueInst.saveData(callBack);
                      })
                      .catch(() => {
                        console.log("提示");
                      });
                  } else if (data.Data.alist[0][0].length > 0) {
                    //正常
                    vueInst.saveData(callBack);
                  } else {
                    alert("概算编号未找到对应数据。");
                  }
                },
                function (e) {
                  //_this.showLoading = false;
                  console.error(
                    ["校验概算节点的使用情况"] + "调取失败" + e.stack
                  );
                  return true;
                },
                function (e) {
                  console.error(
                    ["校验概算节点的使用情况"] + "调取失败" + e.stack
                  );
                  return true;
                },
                "/api/budget"
              );
              return false;
            } else {
              return true;
            }
          } else {
            if (vueInst) return true;
            return false;
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_39: {
      formEvents: {
        beforeSaveData: function (vueInst, api, stateCaption, callBack) {
          if (vueInst && api.pageName == "FormProcessingPage") {
            var arr = [];
            if (stateCaption == "项目经理") {
              if (
                getFlowResult(vueInst.processNames, vueInst.processRadio) ==
                "不同意"
              )
                return true;
              arr.push({
                BudgetCode: vueInst.updatedMainData.WBSbm,
                Money: vueInst.updatedMainData.Hjx
              });
              var params = {
                data: arr,
                Leval: "1"
              };
              console.log(params);
              MicroAppBaseLibrary.mpost(
                "校验概算节点的使用情况",
                params,
                function (data) {
                  if (data.Data.alist[0][2].length > 0) {
                    //存在超概
                    Dialog.confirm({
                      title: "超概",
                      message:
                        data.Data.alist[0][2][0].BudgetName + "该节点已超概！"
                    })
                      .then(() => {
                        //存在超概的情况下 生成超概申请单
                        //too1: 超概申请单生成成功后
                        console.log(vueInst);
                        var mainData = {
                          Ywdh: vueInst.fData.Fkdbh, //业务单号
                          Ywmc: vueInst.fData._SUBJECT + "超概申请单", //业务名称
                          Cglx: vueInst.fData._SUBJECT, //超概类型
                          Cgsm: "", //超概说明
                          Sqbm: vueInst.fData.Fkdbh, //申请部门
                          Sqr: vueInst.fData.Jbr, //申请人
                          Sqsj: moment().format("YYYY-MM-DD HH:mm:ss"), //申请时间
                          XmjlYcy: vueInst.fData.XmjlYcy, //项目经理
                          RatioexcessLevel:
                            data.Data.alist[0][2][0].RatioexcessLevel,
                          IsRatioexcess: data.Data.alist[0][2][0].IsRatioexcess,
                          OtherCode: vueInst.fData.Fkdbh, //单号
                          Formnom: vueInst.fData._FORMNO //单号
                          // Wbsbm: data.Data.alist[0][2][0].BudgetCode, //Wbs编码
                          // Wbsmc: data.Data.alist[0][2][0].BudgetName, //Wbs名称
                          // Ysje: data.Data.alist[0][2][0].BudgetMoney, //预算金额
                          // Sjje: Sjje, //实际金额
                          // Cgje: parseFloat(Sjje - data.Data.alist[0][2][0].BudgetMoney), //超概金额
                        };
                        var detailData = {
                          超概申请审批单明细: []
                        };
                        for (let i = 0; i < data.Data.alist[0][2].length; i++) {
                          var flag = false;
                          for (let z = 0; z < arr.length; z++) {
                            if (
                              arr[z].BudgetCode ==
                              data.Data.alist[0][2][i].BudgetCode
                            ) {
                              detailData["超概申请审批单明细"].push({
                                Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                                Wbsmc: data.Data.alist[0][2][i].BudgetName,
                                Ysje: data.Data.alist[0][2][i].BudgetMoney,
                                Sjje: arr[z].Money,
                                Cgje: data.Data.alist[0][2][i].Cgje,
                                RatioexcessRatio:
                                  data.Data.alist[0][2][i].RatioexcessRatio
                              });
                              flag = true;
                            }
                          }
                          if (!flag) {
                            detailData["超概申请审批单明细"].push({
                              Wbsbm: data.Data.alist[0][2][i].BudgetCode,
                              Wbsmc: data.Data.alist[0][2][i].BudgetName,
                              Ysje: data.Data.alist[0][2][i].BudgetMoney,
                              Cgje: data.Data.alist[0][2][i].Cgje,
                              RatioexcessRatio:
                                data.Data.alist[0][2][i].RatioexcessRatio
                            });
                          }
                        }
                        var params = {
                          templateid: 45,
                          formdataid: undefined,
                          noticeid: 0,
                          from: "",
                          mainData: mainData,
                          mainDataKz: [],
                          detailData: detailData
                        };
                        console.log(params);
                        MicroAppBaseLibrary.mpost(
                          "新建表单数据保存接口",
                          params,
                          function (data) {
                            if (data.Success) {
                              var params1 = {
                                templateId: vueInst.fData._TEMPLATE_ID,
                                formdataId: vueInst.fData.ID,
                                State: 1 //冻结
                              };
                              MicroAppBaseLibrary.mpost(
                                "概算冻结表单",
                                params1,
                                function (data1) {
                                  if (data1.Success) {
                                    vueInst.saveData(callBack);
                                  }
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单" + "调取失败" + e.stack
                                  );
                                },
                                function (e) {
                                  console.error(
                                    "概算冻结表单" + "调取失败" + e.stack
                                  );
                                },
                                "api/budget"
                              );
                            }
                          },
                          function (e) {
                            console.error(
                              ["新建表单数据保存接口"] + "调取失败" + e.stack
                            );
                          }
                        );
                      })
                      .catch(() => {
                        console.log("已取消");
                      });
                  } else if (data.Data.alist[0][1].length > 0) {
                    //存在警告
                    Dialog.confirm({
                      title: "提示",
                      message:
                        data.Data.alist[0][1][0].BudgetName +
                        "该节点已使用超过" +
                        data.Data.alist[0][1][0].ratio +
                        "%"
                    })
                      .then(() => {
                        //正常确认后继续流传
                        vueInst.saveData(callBack);
                      })
                      .catch(() => {
                        console.log("提示");
                      });
                  } else if (data.Data.alist[0][0].length > 0) {
                    //正常
                    vueInst.saveData(callBack);
                  } else {
                    alert("概算编号未找到对应数据。");
                  }
                },
                function (e) {
                  //_this.showLoading = false;
                  console.error(
                    ["校验概算节点的使用情况"] + "调取失败" + e.stack
                  );
                  return true;
                },
                function (e) {
                  console.error(
                    ["校验概算节点的使用情况"] + "调取失败" + e.stack
                  );
                  return true;
                },
                "/api/budget"
              );
              return false;
            } else {
              return true;
            }
          } else {
            if (vueInst) return true;
            return false;
          }
        },
        aftersavingdata: function (vueInst) {
          if (vueInst) return true;
          return false;
        }
      }
    },
    form_44: {
      controlEvents: {
        Fphm: {
          onChange: function (vueInst, value) {
            var params1 = {
              Fphm: value
            };
            MicroAppBaseLibrary.mpost(
              "工程发票号码唯一校验",
              params1,
              function (e) {
                console.error("工程发票号码唯一校验成功！" + e.stack);
              },
              function (e) {
                if (!e.Success) {
                  var data =
                    vueInst.$parent.$parent.$parent.$parent.updatedDetailData[
                      vueInst.detailName
                    ][vueInst.detailIndex];
                  data.Fphm = "";
                  vueInst.cacheData = data;
                  vueInst.updatePageData("cacheData", vueInst.cacheData);
                  alert(
                    "工程发票号码唯一校验:" + "发票号已使用，不能重复使用！"
                  );
                }
              },
              function (e) {
                console.error("工程发票号码唯一校验" + "调取失败" + e.stack);
              },
              "/api/EngineeringManagement"
            );
          }
        }
      },
      formEvents: {}
    },
    form_45: {
      controlEvents: {},
      formEvents: {
        beforeSaveData: function (vueInst) {
          //验证事项金额
          var details = vueInst.updatedDetailData.超概申请审批单明细;
          for (var detail of details) {
            detail.Sxje = detail.Sxje ? detail.Sxje : 0;
            detail.Sjje = detail.Sjje ? detail.Sjje : 0;
          }
          return true;
        }
      }
    }
  }
};

//获取当前审批选择
function getFlowResult(processNames, selectedValue) {
  for (let i = 0; i < processNames.length; i++) {
    const item = processNames[i];
    if (item.value == selectedValue) return item.text;
  }
}

function changeToDic(arr) {
  var obj = {};
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    obj[item.SourceMxTableHH] = item;
  }
  return obj;
}

function statusSelectList(flag) {
  var canSelectedOptions = [];
  if (flag) {
    canSelectedOptions = [
      { label: "未变更", value: "未变更" },
      { label: "变更", value: "变更" },
      { label: "删除", value: "删除" }
    ];
  } else {
    canSelectedOptions = [{ label: "新增", value: "新增" }];
  }
  return canSelectedOptions;
}
function autoHeight() {
  var textearas = document.getElementsByTagName("textarea");
  for (var i = 0; i < textearas.length; i++) {
    setTextareaHeight(textearas[i]);
    textearas[i].onkeyup = function () {
      setTextareaHeight(this);
    };
  }
}
function setTextareaHeight(elem) {
  console.log(elem);
  elem.style.height = "auto";
  elem.scrollTop = 0;
  elem.style.height = elem.scrollHeight + "px";
  elem.style.minHeight = "60px";
}

//转金额大写
function n2ChM(s) {
  var n = Math.round(s * 100) / 100;
  n = n.toString(10).split(".");
  var nDB = n[0];
  if (nDB.length > 12) return "数值超出范围！支持的最大数值为 999999999999.99";
  var eCN = "零壹贰叁肆伍陆柒捌玖";
  var r = "";
  var l = nDB.length - 1;
  for (let i = 0; i <= l; i++)
    r +=
      eCN.charAt(parseInt(nDB.charAt(i))) +
      [["圆", "万", "亿"][Math.floor((l - i) / 4)], "拾", "佰", "仟"][
        (l - i) % 4
      ];
  if (n.length == 2 && n[1] != "") {
    var nDA = n[1];
    l = nDA.length - 1;
    for (let i = 0; i <= l; i++)
      r += eCN.charAt(parseInt(nDA.charAt(i))) + ["角", "分"][i];
  }
  r = r.replace(/零佰|零拾|零仟|零角/g, "零");
  r = r.replace(/零{2,}/g, "零");
  r = r.replace(/零(?=圆|万|亿)/g, "");
  r = r.replace(/亿万/, "亿");
  r = r.replace(/^圆零?/, "");
  if (r != "" && !/分$/.test(r)) r += "整";
  return r;
}
