import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../views/Layout.vue";
import Login from "../views/Login.vue";
import DDLogin from "../views/DDLogin.vue";
import TYLogin from "../views/TYLogin.vue";
import MicroApp from "../views/MicroApp.vue";
import NavigationPage from "../views/NavigationPage.vue";

import {
  About,
  AddGateForSigningPage,
  AddGatePage,
  BusinessPushDetailPage,
  BusinessPushPage,
  CopyToMeListPage,
  FormAddPage,
  FormEditPage,
  FormDetailPage,
  FormListPage,
  FormMessageAddPage,
  FormMessageDetailPage,
  FormMessageListPage,
  FormOriginalListPage,
  FormProcessingPage,
  FormSigningMessageReplyPage,
  MailDetailPage,
  MailListPage,
  MeetingDetailPage,
  MeetingListPage,
  MyFormMsgListPage,
  MyNoticeListPage,
  MyPatternSetPage,
  MySetPage,
  NewsDetailPage,
  NewsFormReg,
  NewsListPage,
  ReplyToMeListPage,
  ToOffice,
  ViewShow,
  WorkFlowChartPage,
  FormViewListPage,
  ToList,
  UserTable
  // UserForms
} from "@zstech/mobile-base-library";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Layout",
    component: Layout,
    redirect: "home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home
      },
      {
        path: "/mapp/*",
        name: "mapp",
        component: MicroApp
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/DDLogin",
    name: "Login",
    component: DDLogin
  },
  {
    path: "/TYLogin",
    name: "TYLogin",
    component: TYLogin
  },
  {
    path: "/",
    name: "Navigation",
    component: NavigationPage
  },
  {
    path: "/NavigationPage",
    name: "Navigation",
    component: NavigationPage
  },
  {
    path: "/About",
    name: "About",
    component: About
  },
  {
    name: "FormListPage",
    path: "/FormListPage",
    meta: {
      keepAlive: true
    },
    component: FormListPage
  },
  {
    name: "MailListPage",
    path: "/MailListPage",
    meta: {
      keepAlive: true
    },
    component: MailListPage
  },
  {
    name: "MeetingListPage",
    path: "/MeetingListPage",
    meta: {
      keepAlive: true
    },
    component: MeetingListPage
  },
  {
    name: "MySetPage",
    path: "/MySetPage",
    meta: {
      keepAlive: true
    },
    component: MySetPage
  },
  {
    name: "MyPatternSetPage",
    path: "/MyPatternSetPage",
    meta: {
      keepAlive: false
    },
    component: MyPatternSetPage
  },
  {
    name: "FormAddPage",
    path: "/FormAddPage",
    meta: {
      keepAlive: false
    },
    component: FormAddPage
  },
  {
    name: "FormEditPage",
    path: "/FormEditPage",
    meta: {
      keepAlive: false
    },
    component: FormEditPage
  },
  {
    name: "FormProcessingPage",
    path: "/FormProcessingPage",
    meta: {
      keepAlive: false
    },
    component: FormProcessingPage
  },
  {
    name: "FormDetailPage",
    path: "/FormDetailPage",
    meta: {
      keepAlive: false
    },
    component: FormDetailPage
  },
  {
    name: "FormMessageListPage",
    path: "/FormMessageListPage",
    meta: {
      keepAlive: false
    },
    component: FormMessageListPage
  },
  {
    name: "MyFormMsgListPage",
    path: "/MyFormMsgListPage",
    meta: {
      keepAlive: true
    },
    component: MyFormMsgListPage
  },
  {
    name: "FormMessageDetailPage",
    path: "/FormMessageDetailPage",
    meta: {
      keepAlive: true
    },
    component: FormMessageDetailPage
  },
  {
    name: "FormMessageAddPage",
    path: "/FormMessageAddPage",
    meta: {
      keepAlive: false
    },
    component: FormMessageAddPage
  },
  {
    name: "ReplyToMeListPage",
    path: "/ReplyToMeListPage",
    meta: {
      keepAlive: false
    },
    component: ReplyToMeListPage
  },
  {
    name: "AddGatePage",
    path: "/AddGatePage",
    meta: {
      keepAlive: true
    },
    component: AddGatePage
  },
  {
    name: "CopyToMeListPage",
    path: "/CopyToMeListPage",
    meta: {
      keepAlive: true
    },
    component: CopyToMeListPage
  },
  {
    name: "MyNoticeListPage",
    path: "/MyNoticeListPage",
    meta: {
      keepAlive: true
    },
    component: MyNoticeListPage
  },
  {
    name: "NewsListPage",
    path: "/NewsListPage",
    meta: {
      keepAlive: true
    },
    component: NewsListPage
  },
  {
    name: "NewsDetailPage",
    path: "/NewsDetailPage",
    meta: {
      keepAlive: false
    },
    component: NewsDetailPage
  },
  {
    name: "MailDetailPage",
    path: "/MailDetailPage",
    meta: {
      keepAlive: false
    },
    component: MailDetailPage
  },
  {
    name: "MeetingDetailPage",
    path: "/MeetingDetailPage",
    meta: {
      keepAlive: false
    },
    component: MeetingDetailPage
  },
  {
    name: "ToOffice",
    path: "/ToOffice",
    meta: {
      keepAlive: false
    },
    component: ToOffice
  },
  {
    name: "NewsFormReg",
    path: "/NewsFormReg",
    meta: {
      keepAlive: false
    },
    component: NewsFormReg
  },
  {
    name: "BusinessPushPage",
    path: "/BusinessPushPage",
    meta: {
      keepAlive: false
    },
    component: BusinessPushPage
  },
  {
    name: "BusinessPushDetailPage",
    path: "/BusinessPushDetailPage",
    meta: {
      keepAlive: false
    },
    component: BusinessPushDetailPage
  },
  {
    name: "FormOriginalListPage",
    path: "/FormOriginalListPage",
    meta: {
      keepAlive: true
    },
    component: FormOriginalListPage
  },
  {
    name: "AddGateForSigningPage",
    path: "/AddGateForSigningPage",
    meta: {
      keepAlive: false
    },
    component: AddGateForSigningPage
  },
  {
    name: "FormSigningMessageReplyPage",
    path: "/FormSigningMessageReplyPage",
    meta: {
      keepAlive: false
    },
    component: FormSigningMessageReplyPage
  },
  {
    name: "WorkFlowChartPage",
    path: "/WorkFlowChartPage",
    meta: {
      keepAlive: false
    },
    component: WorkFlowChartPage
  },
  {
    name: "ViewShow",
    path: "/ViewShow",
    meta: {
      keepAlive: false
    },
    component: ViewShow
  },
  {
    name: "FormViewListPage",
    path: "/FormViewListPage",
    meta: {
      keepAlive: true
    },
    component: FormViewListPage
  },
  {
    name: "UserTable",
    path: "/UserTable",
    meta: {
      keepAlive: false
    },
    component: UserTable
  },
  {
    name: "ToList",
    path: "/ToList",
    meta: {
      keepAlive: false
    },
    component: ToList
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
