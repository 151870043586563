import "whatwg-fetch";
import "custom-event-polyfill";
import "core-js/stable/promise";
import "core-js/stable/symbol";
import "core-js/stable/string/starts-with";
import "core-js/web/url";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initMicroApps } from "./microapp";
import Vant from "vant";
import "vant/lib/index.css";
import MicroAppBaseLibrary from "@zstech/mobile-base-library";
import "@zstech/mobile-base-library/lib/mobile-base-library.css";
import "@/assets/icon/iconfont.css";
import externalFormExtend from "@/assets/js/form-extend.js";

var accessToken = localStorage.userinfo
  ? JSON.parse(localStorage.userinfo).token
  : "";
if (
  !accessToken &&
  location.href.indexOf("/login") == -1 &&
  location.href.indexOf("/TYLogin") == -1 &&
  location.href.indexOf(".") == -1
)
  location.href = "/login";
Vue.use(MicroAppBaseLibrary, {
  accessToken: accessToken,
  apiBaseUrl: "http://" + location.host + "/api/core"
});

MicroAppBaseLibrary.install(Vue, {
  accessToken: accessToken,
  apiBaseUrl: "http://" + location.host + "/api/core",
  formExtend: externalFormExtend
});

Vue.use(Vant);

Vue.config.productionTip = false;

initMicroApps();

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
