import { registerMicroApps, start } from "qiankun";
import microAppService from "../services/MicroAppService";
import _ from "lodash";
import store from "../store";
import { initGlobalState } from "qiankun";

export function initMicroApps() {
  if (!window.IsMicroAppsInitialized) {
    window.IsMicroAppsInitialized = true;

    microAppService.getMicroApps().then((resp) => {
      var microApps = [];

      _.forEach(resp.data.Data, (mapp) => {
        microApps.push({
          name: mapp.AppName,
          entry: mapp.EntryUrl,
          container: "#mapp-container",
          activeRule: mapp.EntryRoute
        });
      });

      registerMicroApps(microApps);
      store.commit("pushMicroApps", microApps);
    });
    // [
    //   {
    //     name: "SubApp",
    //     entry: "http://localhost:8081",
    //     container: "#mapp-container",
    //     activeRule: "/mapp/app1"
    //   },
    //   {
    //     name: "SubApp2",
    //     entry: "http://localhost:8081",
    //     container: "#mapp-container",
    //     activeRule: "/mapp/app2"
    //   }
    // ]
  }

  start();
}

// 与MicroAPP的通信类
class StateCommunication {
  // 通信数据
  state = {
    accessToken: ""
  };

  stateActions = null;

  constructor() {
    this.stateActions = initGlobalState(this.state);

    if (localStorage.getItem("userinfo") != null) {
      this.broadcast({
        accessToken: JSON.parse(localStorage.getItem("userinfo")).token
      });
    }
  }

  // 传播数据
  broadcast(state) {
    this.stateActions.setGlobalState(state);
  }

  // 订阅数据变化
  subscribe(callback) {
    this.stateActions.onGlobalStateChange(callback);
  }

  // 立即订阅数据变化
  immediatelySubscribe(callback) {
    this.stateActions.onGlobalStateChange(callback, true);
  }
}

var stateCommunication = new StateCommunication();

export { stateCommunication };

// 初始化 state
// let state = {
//   token: ""
// };

// const appStateActions = initGlobalState(state);

// appStateActions.onGlobalStateChange((state, prev) => {
//   // state: 变更后的状态; prev 变更前的状态
//   console.log(state, prev);
// });

// appStateActions.setGlobalState(state);

// export { appStateActions };
