/**
 * 全局函数库
 * @creator hq
 */
import moment from "moment";
import $ from "jquery";
import axios from "axios";
// import MathExpression from "./math-expression";
import Qs from "qs";
import appConfig from "./config";
import { getLoginState } from "./login";
import { ZSHttp } from "@zstech/zs-commons-library";

export var BSYServer = {};

var isGecko = navigator.userAgent.toLowerCase().indexOf("gecko") != -1;

var zshttp = new ZSHttp({
  enableDataEncryption: false, // 启用数据加密功能
  // 用来加密数据加密密钥的RSA公钥
  dataEncryptionRSAPublicKey:
    "-----BEGIN PUBLIC KEY-----" +
    "\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAz2LpI4S0sPRKFPR+TPQsuFb+/3gnbqX55Z9YvY5bFuaq8sAmSZTm+zt5S9cjs54vWVZxFXIyw995kkdWWOioeVBwly+vSrrjF30wIfz7DchxaXb7NsSLzP+BOYgmmqhG0NhtAo04fD9qcU0/U6OpUwaSZUI8Rz9CFe7InetQoczhyM/MPYUg0rcS7MvH2gSej1LL2VlgHkRshySITeo8s0HXFWmR/CpSEhSPAqeaG0h0VCqTqe8HiI6+uoMu2PT2ekOfwuLEAmKBbi5uHDJ7RQnvGNWbMzqUkkI20IdaD0zMfcSn4e0CE+ThJ7DI6Pvb8+DwClchn1Zzf49qF1iJRQIDAQAB" +
    "\n-----END PUBLIC KEY-----"
});
//动态加载js脚本文件
BSYServer.loadScript = function (url) {
  document.write('<script type="text/javascript" src="' + url + '"></script>');
};

// 动态加载样式文件
BSYServer.loadStyle = function (url) {
  try {
    if (isGecko) {
      var e = document.createElement("link");
      e.rel = "stylesheet";
      e.type = "text/css";
      e.href = url;
      document.getElementsByTagName("head")[0].appendChild(e);
    } else {
      document.createStyleSheet(url);
    }
  } catch (e) {
    console.log(e);
  }
};
//浏览器类型
var browser = {
  versions: (function () {
    var u = navigator.userAgent; //,
    //app = navigator.appVersion;
    //console.log(u+"\napp::::::"+app);
    //alert(u);
    //alert(app);
    return {
      //移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
      html5plus: u.indexOf("Html5Plus") > 0
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase()
};

/**
 * 发起get请求
 * @param url
 * @param bakCall
 */
export function getFile(url, bakCall) {
  /*axios.get(url).then(function(rep){
      bakCall(rep);
    }).catch(function(e) {
      bakCall(null);
    });*/
  $.ajax({
    url: url,
    type: "HEAD",
    async: false,
    statusCode: {
      404: function () {
        bakCall(null);
      }
    },
    error: function () {
      bakCall(null);
    },
    success: function () {
      bakCall({ status: 200 });
    }
  });
}

/**
 * 判断当前入口是PC端还是APP端
 * @returns {string}
 */
export function isQyweixin() {
  let flag =
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) == null
      ? false
      : true;

  return flag
    ? is_weixin()
      ? "来自于APP端企业微信"
      : ""
    : is_weixin()
    ? "来自于PC端的企业微信"
    : "来自于PC端浏览器";
}

/**
 * 判断是在企业微信打开 还是 在浏览器打开
 * @returns {boolean}
 */
export function is_weixin() {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
}

/**
 * 跳转到name对应路由页面
 * @author wangxin
 * @param {Object} vueObj 当前vue实例
 * @param {String} pagename 需要跳转到的页面name
 * @param {JSONObject} param 跳转时携带的参数
 */
export function toPage(vueObj, pagename, param) {
  if (
    param != undefined &&
    param != null &&
    Object.getOwnPropertyNames(param).length > 0
  ) {
    if (pagename == "FormViewListPage" && this.IsPcDingTalk) {
      //如果是钉钉PC并且是跳转列表
      pagename = "usertable";
      if (param["type"] == undefined) {
        param["listtype"] = "viewlist";
      } else {
        param["listtype"] = param["type"];
      }
      param["viewid"] = "viewId";
    }

    if (param.loginBack) {
      window.location.href =
        appConfig.BASE_URL +
        appConfig.OA_LOGING_JUMP_URL +
        "?token=" +
        getToken() +
        "&redirectUrl=" +
        encodeURIComponent(pagename);
    } else {
      delete param.iosAuthInfo;
      param["isBack"] = false;
      vueObj.$router.push({
        name: pagename,
        query: param
      });
    }
  } else {
    param = { isBack: false };
    vueObj.$router.push({
      name: pagename,
      query: param
    });
  }
}
/**
 * 退出登录
 * @param vueObj
 */
export function toLogOut(vueObj) {
  if (isApp()) {
    window.location.href = "exitapp://";
  } else {
    toPage(vueObj, "Login");
  }
}

/**
 * 获取length长度的随机字符串
 * @param length
 * @return {string}
 */
export function randomString(length) {
  var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += str[Math.floor(Math.random() * str.length)];
  return result;
}

/**
 * 使用replace跳转到name对应路由页面
 * @author wangxin
 * @param {Object} vueObj 当前vue实例
 * @param {String} pagename 需要跳转到的页面name
 * @param {JSONObject} param 跳转时携带的参数
 */
export function toReplacePage(vueObj, pagename, param) {
  if (param && Object.getOwnPropertyNames(param).length > 0) {
    vueObj.$router.replace({
      name: pagename,
      query: param
    });
  } else {
    vueObj.$router.replace({
      name: pagename
    });
  }
}
/**
 * 显示加载中提示
 *  调用该方法前，需要在vue实例data中定义showLoading = false属性，
 * 如果需要条件隐藏加载中提示，只要将属性showLoading设置为true
 * @author wangxin
 * @param {Object} vueobj
 *
 */
export function showLoading(vueobj) {
  vueobj.showLoading = true;
  const toast = vueobj.$toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true, // 禁用背景点击
    loadingType: "spinner",
    message: "提交中"
  });

  let second = 20;
  const timer = setInterval(() => {
    second--;
    if (vueobj.showLoading && second) {
      toast.message = "提交中";
    } else {
      clearInterval(timer);
      vueobj.$toast.clear();
    }
  }, 500);
}

/**
 * 获取PC浏览器信息
 * @author wangxin
 * @return {String}
 */
export function BrowserType() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  var isIE =
    userAgent.indexOf("compatible") > -1 &&
    userAgent.indexOf("MSIE") > -1 &&
    !isOpera; //判断是否IE浏览器 ,IE11一下版本
  var isIE11 =
    userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1; //判断是否是IE11
  var isEdge = userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 && !isIE; //判断是否IE的Edge浏览器
  var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  var isSafari =
    userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
  var isChrome =
    userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion == 7) {
      return "IE7";
    } else if (fIEVersion == 8) {
      return "IE8";
    } else if (fIEVersion == 9) {
      return "IE9";
    } else if (fIEVersion == 10) {
      return "IE10";
    } else {
      return "0";
    } //IE版本过低
  } //isIE end
  if (isIE11) {
    return "Chrome";
  }
  if (isFF) {
    return "Chrome";
  }
  if (isOpera) {
    return "Chrome";
  }
  if (isSafari) {
    return "ios";
  }
  if (isChrome) {
    return "Chrome";
  }
  if (isEdge) {
    return "Chrome";
  }
}
/**
 * 获取移动端浏览器信息
 * @author wangxin
 * @return {String}
 */
export function getMobileBrowserType() {
  if (browser.versions.mobile) {
    //判断是否是移动设备打开
    var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
    //alert("iPhone="+browser.versions.ios+"\n"+",html5plus="+browser.versions.html5plus);
    if (ua.match(/MicroMessenger/i) === "micromessenger") {
      return "QYWEIXIN";
    }
    if (ua.match(/WeiBo/i) === "weibo") {
      return "weibo";
    }
    if (ua.match(/QQ/i) === "qq") {
      return "qqspace";
    }
    if (browser.versions.ios && browser.versions.html5plus) {
      return "IOSAPP";
    } else if (browser.versions.ios) {
      return "ios";
    }
    if (browser.versions.android && browser.versions.html5plus) {
      return "ADAPP";
    } else if (browser.versions.android) {
      return "Chrome";
    } else {
      return BrowserType();
    }
  } else {
    return BrowserType();
  }
}
/**
 * 将打开本应用的浏览器类型存入localStorage
 * @author wangxin
 */
export function setBrowserType(types) {
  localStorage.setItem("$browserType", types);
}
/**
 * 获取，打开本应用的浏览器类型存入localStorage
 * @author wangxin
 * @return {String}
 */
export function getBrowserType() {
  return localStorage.getItem("$browserType");
}

/**
 * 是IOS APP
 * @return {boolean}
 */
export function isIOSApp() {
  if (isApp() && getBrowserType()) {
    return getBrowserType().toUpperCase() === "IOS";
  }
  return false;
}

/**
 * 判断打开本应用的否是APP
 * @author wangxin
 * @return {Boolean}
 */
export function isApp() {
  return false;
  /*var browser = getBrowserType();
    return (
      browser === "android" ||
      browser === "ADAPP" ||
      browser === "iPhone" ||
      browser === "IOSAPP" ||
      browser === "ios" ||
      browser.indexOf("ios") != -1 ||
      browser.indexOf("IOS") != -1 ||
      browser === "Chrome"
    ); //是app打开本页面*/
}

/**
 * 格式化日期时间字符串为YY-MM-DD HH24:mm
 * @param {String} datatimestr
 * @return {String}
 * @author wangxin
 */
export function formatDatetime(datatimestr) {
  return moment(datatimestr).format("YYYY-MM-DD HH:mm");
}

export var Default_Date_Format = "YYYY-MM-DD HH:mm:ss";
export var Default_Date_Format_Date = "YYYY-MM-DD";
export var Default_Time_Format = "HH:mm";
//支持的时间日期格式
export var DefaultFormats = [
  "YYYY/MM/DD",
  "YYYY-MM-DD",
  "YYYY/MM/DDTHH:mm:ss",
  "YYYY/M/D H:mm:ss",
  "YYYY-MM-DDTHH:mm:ss",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD h:mm:ss A",
  "YYYY-MM-DD HH:mm"
];
/**
 * 格式化日期字符串
 * @param {String} datatimeStr
 * @return {String} format
 * @returns {*}
 */
export function formatDateTime(datatimeStr, format) {
  if (datatimeStr) {
    let tempData = moment(datatimeStr, DefaultFormats);
    if (tempData.isValid()) {
      if (format) {
        return tempData.format(format);
      } else {
        return tempData.format(Default_Date_Format);
      }
    }
  }
  return datatimeStr;
}
/**
 * 检测字符串是否满足某种时间日期字符串格式
 * @param str
 * @returns {boolean}
 */
export function checkStringIsTime(str) {
  return moment(str, DefaultFormats, true).isValid();
}

/**
 * 获取指定格式的今日的日期时间字符串
 * @param format
 * @returns {*|string|void|{type, default}}
 */
export function getNowDateTimeString(format) {
  if (format) {
    return new moment().format(format);
  } else {
    return new moment().format(Default_Date_Format);
  }
}

/**
 * 对Date的扩展，将 Date 转化为指定格式的String
 * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2 个占位符 * 年(y)可以用 1-4 个占位符，
 * 毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @param {Date} date
 * @param {String} fmt
 */
export function patternDate(date, fmt) {
  return moment(date).format(fmt);
}

/**
 * 根据配置的日期值类型，和格式，返回对应的字符串值
 * 注意，此函数依赖monent.js组件
 * @param {string} valueType
 * @param {string} monentFormat
 * @param {string} beginTime
 */
export function getFromatDateString(valueType, monentFormat, beginTime) {
  if (valueType && monentFormat) {
    if (valueType === "now") {
      //今天
      return patternDate(new Date(), monentFormat);
    } else {
      //值类型公式
      valueType = trim(valueType);
      var types = valueType.split(","),
        expent = "moment(" + (beginTime ? "'" + beginTime + "'" : "") + ")";
      for (var i = 0; i < types.length; i++) {
        var manth = types[i],
          temps,
          step,
          type;
        if (manth.indexOf("-") > 0) {
          //是获取当前时间前的时间点
          temps = manth.split("-");
          type = temps[0];
          step = temps[temps.length - 1];
          expent += ".subtract(" + step + ",'" + type + "')";
        } else {
          //是获取当前时间后的时间点
          temps = manth.split("+");
          type = temps[0];
          step = temps[temps.length - 1];
          expent += ".add(" + step + ",'" + type + "')";
        }
      }
      expent += ".format('" + monentFormat + "');";
      console.log("时间公式转换：", expent);
      try {
        return eval(expent);
      } catch (e) {
        console.error("获取指定公式时间失败", e);
      }
    }
  }
  return "";
}

/**
 * 字符串转换为Date对象
 * @param v
 * @return {*}
 */
export function strToDate(v) {
  if (v) {
    if (isType(v) === "String") {
      let tempData = moment(v, [
        Default_Date_Format,
        moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
        moment.HTML5_FMT.DATETIME_LOCAL_MS,
        moment.HTML5_FMT.DATE
      ]);
      if (tempData.isValid()) {
        return tempData.toDate();
      }
    }
  }
  return null;
}

/**
 * 根据时间字符串获取Date对象
 * @param thisdate
 * @returns {Date}
 */
export function getDatefromstr(thisdate) {
  if (thisdate) {
    var split = "-";
    if (thisdate.indexOf("/") > 0) split = "/";
    var sd = thisdate.split(split);
    if (sd.length == 3) {
      var m = new Number(sd[1]) - 1;
      var d = sd[2];
      var h = "00",
        mi = "00",
        ss = "00";
      if (d.length > 2) {
        var ti = d.split(" ");
        if (ti.length == 2) {
          var temptime = ti[1];
          d = ti[0];
          var tistr = temptime.split(":");
          if (tistr.length == 3) {
            h = tistr[0];
            mi = tistr[1];
            ss = tistr[2];
          } else if (tistr.length == 2) {
            h = tistr[0];
            mi = tistr[1];
          } else if (temptime.length == 2) {
            h = temptime;
          }
        }
      }
      try {
        return new Date(sd[0], m, d, h, mi, ss);
      } catch (e) {
        return null;
      }
    }
  }
  return null;
}

/**
 * 获取当前的年份
 * @returns
 */
export function getNowYear() {
  return new Date().getFullYear();
}

/**
 * 获取指定日期的前后几天
 * @param thisdate
 * @param countdate
 * @returns {String}
 */
export function getThisDate(thisdate, countdate) {
  if (thisdate != null && thisdate != undefined) {
    var sd = thisdate.split("-");
    if (sd.length == 3) {
      var m = new Number(sd[1]) - 1;
      var de = new Date(sd[0], m, sd[2]);
      de.setDate(de.getDate() + countdate); //获取AddDayCount天后的日期
      var y = de.getFullYear();
      m = de.getMonth() + 1; //获取当前月份的日期
      var d = de.getDate();
      var mstr = "";
      if (m < 10) {
        mstr = "0" + m;
      } else {
        mstr = "" + m;
      }
      var dstr = "";
      if (d < 10) {
        dstr = "0" + d;
      } else {
        dstr = "" + d;
      }
      return y + "-" + mstr + "-" + dstr;
    }
  }
  return "";
}

/**
 * 获取n天前的日期
 * @param n
 * @return {*|Date}
 */
export function getDayPreviousDate(n) {
  let nowDate = new moment();
  nowDate.subtract(n, "days");
  return nowDate.toDate();
}
/**
 * 获取n年前的日期
 * @param n
 * @return {*|Date}
 */
export function getYearPreviousDate(n) {
  let nowDate = new moment();
  nowDate.subtract(n, "years");
  return nowDate.toDate();
}

/**
 * 获取n年后的日期
 * @param n
 * @return {*|Date}
 */
export function getYearAfterDate(n) {
  let nowDate = new moment();
  nowDate.add(n, "years");
  return nowDate.toDate();
}

/**
 * 获取当前的年份
 * @return {*|moment.Moment|number}
 */
export function getYear() {
  let nowDate = new moment();
  return nowDate.year();
}

/**
 * 获取当前的月份
 * @returns
 */
export function getNowMonth() {
  var myDate = new Date();
  return myDate.getMonth() + 1;
}

/**
 * 获取date所在周是星期几
 * @param {Object} date 日期格式字符串如：2019-12-20,或者Date对象
 * @returns {String}
 */
export function getThisDayWeek(date) {
  var nowTime = null;
  if (date != undefined && date != null) {
    if (isType(date) === "String") nowTime = getDatefromstr(date);
    else if (isType(date) === "Date") nowTime = date;
    else return null;
  } else {
    return null;
  }
  let dayInWeek = nowTime.getDay();
  return appConfig.WEEK_CN[dayInWeek];
}

/**
 * 获取某年有多少周
 * @param year
 * @returns {Number}
 */
export function getYearOfWeeks(year) {
  //该年的一月1号
  var oneday = new Date(year, 0, 1);
  var n = 6 - ((oneday.getDay() + 6) % 7); //下一个星期日是几天后
  var oneSunday = oneday;
  //本年第一个心情日
  oneSunday.setDate(oneday.getDate() + n); //本年第一个星期天，也就是该年第一周的开始
  //本年所有周的最后一天
  var lastday = undefined;
  //获取下一年第一个周六
  var nextyear_oneday = new Date(year + 1, 0, 1); //下一年的第一天
  n = 6 - ((nextyear_oneday.getDay() + 6) % 7);
  var oneSaturday = nextyear_oneday.getDate() + n - 1; //该年第一个周六的日期
  if (oneSaturday < 7) {
    //表示该年的前oneSaturday天属于前年的最后一周
    lastday = nextyear_oneday;
    lastday.setDate(oneSaturday);
  } else {
    lastday = new Date(year, 11, 31);
    lastday.setDate(lastday.getDate() - (7 - oneSaturday));
  }
  //console.log(lastday.toLocaleDateString());
  var weeks = 0;
  while (oneSunday < lastday) {
    oneSunday.setDate(oneSunday.getDate() + 7);
    weeks++;
  }
  //console.log(year+"年共有"+weeks+"周")
  return weeks;
}
/**
 * 获取今天所在周的起始日期到截止日期的范围
 * @param {String} delimiter 两个日期间的分隔符
 * @param {int} par 相对当前第几周 0或undefind:表示本周,par>0:表示下par周，par<0:表示上par周
 * @returns {String}
 */
export function getNowWeekDays(delimiter, par) {
  const weekTimes = 86400000;
  let nowTime = new Date();
  let dayInWeek = nowTime.getDay();
  dayInWeek == 0 && (dayInWeek = 7);
  let thisWeekFirstDay = nowTime.getTime() - (dayInWeek - 1) * weekTimes;
  let thisWeekLastDay = nowTime.getTime() + (7 - dayInWeek) * weekTimes;
  par = ~~par;
  var firstDay = longFormateToDate(thisWeekFirstDay + par * 7 * weekTimes, "/");
  var lastDay = longFormateToDate(thisWeekLastDay + par * 7 * weekTimes, "/");
  if (delimiter == undefined || delimiter == null || delimiter === "") {
    delimiter = ",";
  }
  return firstDay + delimiter + lastDay;
}

/**
 * 获取date所在周的起始日期到截止日期的范围
 * @param {Object} date 日期格式字符串如：2019-12-20,或者Date对象
 * @param {String} delimiter 两个日期间的分隔符
 * @returns {String}
 */
export function getThisDateWeek(date, delimiter) {
  const weekTimes = 86400000;
  var nowTime = null;
  if (date != undefined && date != null) {
    if (isType(date) === "String") nowTime = getDatefromstr(date);
    else if (isType(date) === "Date") nowTime = date;
    else return null;
  } else {
    return null;
  }

  let dayInWeek = nowTime.getDay();
  dayInWeek == 0 && (dayInWeek = 7);
  let thisWeekFirstDay = nowTime.getTime() - (dayInWeek - 1) * weekTimes;
  let thisWeekLastDay = nowTime.getTime() + (7 - dayInWeek) * weekTimes;
  var firstDay = longFormateToDate(thisWeekFirstDay, "/");
  var lastDay = longFormateToDate(thisWeekLastDay, "/");
  if (delimiter == undefined || delimiter == null || delimiter === "") {
    delimiter = ",";
  }
  return firstDay + delimiter + lastDay;
}

/**
 * 将时间毫秒数转换为日期字符串 YYYY-MM-DD
 * @param {Long} time 时间毫秒数
 * @param {String} delimiter年月日之间的分隔符，默认-
 * @returns {String}
 */
export function longFormateToDate(time, delimiter) {
  let newTime = new Date(time);
  let year = newTime.getFullYear();
  let month = newTime.getMonth() + 1;
  let day = newTime.getDate();
  if (delimiter == undefined || delimiter == null || delimiter === "") {
    delimiter = "-";
  }
  return (
    year +
    delimiter +
    (month >= 10 ? month : "0" + month) +
    delimiter +
    (day >= 10 ? day : "0" + day)
  );
}

/**
 * 将输入的字符串中包含的数字直接提取出来，并返回为对应的数值
 * @param str
 * @return {*}
 */
export function getNumberFromString(str) {
  if (isType(str) === "String") {
    let temp = str.match(/((\d+\.?\d*)|(\d*\.\d+))/g);
    return temp.join();
  }
  if (isType(str) === "val") {
    return str;
  }
  return null;
}

/**
 * 获取今天是今年的第几周
 * @returns {Number}
 */
export function getThisDayYearOfWeek() {
  //该年的一月1号
  var oneday = new Date();
  // var year = oneday.getFullYear();
  oneday.setMonth(0);
  oneday.setDate(1);
  var n = 6 - ((oneday.getDay() + 6) % 7); //下一个星期日是几天后
  var oneSunday = oneday;
  //本年第一个星期日
  oneSunday.setDate(oneday.getDate() + n); //本年第一个星期天，也就是该年第一周的开始
  var lastday = new Date();
  var weeks = 0;
  while (oneSunday < lastday) {
    oneSunday.setDate(oneSunday.getDate() + 7);
    weeks++;
  }
  //console.log("今天是第"+weeks+"周")
  return weeks;
}

/**
 * 获取两个日期之间相差的天数
 * @param sDate1 日期的0时
 * @param sDate2 该日的23时
 * @returns
 */
export function dateDiff(sDate1, sDate2) {
  //sDate1和sDate2是2006-12-18格式，
  var oDate1, oDate2, iDays;
  oDate1 = getDatefromstr(sDate1 + " 00:00:00"); //转换为12-18-2006格式
  oDate2 = getDatefromstr(sDate2 + " 23:59:59");
  var lastd = oDate2.getTime() + 1000;
  iDays = parseInt(Math.abs(oDate1.getTime() - lastd) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数
  return iDays;
}

/**
 * 根据本系统配置支持的文件名（包含扩展名）或文件扩展名，获取对应文件类型
 * @param {String} filename
 * @return {String}
 * @author wangxin
 */
export function getFileType(filename) {
  if (filename != undefined && filename != null && filename != "") {
    var extension = filename;
    if (filename.lastIndexOf(".") >= 0) {
      //获取扩展名
      let index = filename.lastIndexOf(".");
      extension = filename.substr(index + 1);
    }
    for (var filetype in appConfig.TYPE_FILE_EXTENSION) {
      var types = appConfig.TYPE_FILE_EXTENSION[filetype];
      var typestr = types.join(",");
      if (typestr.indexOf(extension) >= 0) {
        //包含这种扩展名
        return filetype;
      }
    }
    return "other";
  }
}
/**
 * 获取系统目前配置支持的所有文件类型，用'|'分割列出为字符串
 * @return {String}
 * @author wangxin
 */
export function getAllFileTypeStr() {
  var extension = "";
  for (var filetype in appConfig.TYPE_FILE_EXTENSION) {
    var types = appConfig.TYPE_FILE_EXTENSION[filetype];
    var typestr = types.join("|");
    extension = extension + typestr + "|";
  }
  extension = extension.substr(0, extension.length - 1);
  return extension;
}

/**
 * 从当前控件中解析出对应的上传附件限制
 * @param $el jquery对象
 * @returns {Array}
 */
export function getUploaderLimit($el) {
  var limit = $el.attr("uploadlimit");
  var limitArr = [];
  if (limit && limit !== "") {
    limit = trim(limit);
    if (limit.indexOf(",") == -1) {
      var sizeAndCount = getParenthesesStr(limit);
      var ext = limit.split("(")[0];
      sizeAndCount = sizeAndCount.split("#");
      limitArr.push({
        name: ext,
        type: getFileType(ext),
        size: sizeAndCount[0],
        count: sizeAndCount[1]
      });
    } else {
      limit = limit.split(",");
      for (var i = 0; i < limit.length; i++) {
        var khnr = getParenthesesStr(limit[i]);
        var na = limit[i].split("(")[0];
        khnr = khnr.split("#");
        if (na === ".*") {
          //错误配置
          return [];
        }
        limitArr.push({
          name: na,
          type: getFileType(na),
          size: khnr[0],
          count: khnr[1]
        });
      }
    }
  }
  return limitArr;
}

/**
 * 解析限制字符串配置
 * @param text
 * @returns {string | string}
 */
export function getParenthesesStr(text) {
  let result = "",
    regex = /\((.+?)\)/g;
  let option = text.match(regex)[0];
  result = option.substring(1, option.length - 1);
  return result;
}

/**
 * 将字符串超过len长度部分变成省略号
 * @param {String} str
 * @param {int} len
 * @return {String}
 * @author wangxin
 */
export function stringEllipsis(str, len) {
  if (str.length > len) {
    var st = str.substring(0, len);
    return st + "...";
  }
  return str;
}

/**
 * 判断传入参数的类型
 * @param obj
 * @returns {String}
 */
export function isType(obj) {
  if (obj == null) {
    return "Null";
  }
  if (obj === undefined) {
    return "Undefined";
  }
  var type = Object.prototype.toString.call(obj);
  if (type === "[object Array]") {
    return "Array";
  } else if (type === "[object Object]") {
    return "Object";
  } else if (type === "[object Boolean]") {
    return "Boolean";
  } else if (type === "[object Function]") {
    return "Function";
  } else if (type === "[object Date]") {
    return "Date";
  } else if (type === "[object RegExp]") {
    return "RegExp";
  } else if (type === "[object String]") {
    return "String";
  } else return "val";
}

/**
 * 获取两个对象中相同属性，但是值不相同的部分组成一个新的对象
 * @param newObj
 * @param oldObj
 */
export function getObjectChangedFields(newObj, oldObj) {
  let newObject = {};
  if (newObj && oldObj) {
    let filedNames = [];
    // 老对象里面所有的属性
    for (let f1 in oldObj) {
      if (
        isType(newObj[f1]) !== "Object" &&
        isType(newObj[f1]) !== "Array" &&
        isType(newObj[f1]) !== "Function"
      ) {
        filedNames.push(f1);
      }
    }
    for (let f in newObj) {
      if (
        isType(newObj[f]) !== "Object" &&
        isType(newObj[f]) !== "Array" &&
        isType(newObj[f]) !== "Function"
      ) {
        if (filedNames.includes(f)) {
          arrayRemoveValue(filedNames, f);
        }
        if (oldObj[f] !== undefined && !isObjEqual(oldObj[f], newObj[f])) {
          // 老属性，值变了
          newObject[f] = newObj[f];
        } else if (oldObj[f] === undefined) {
          // 新加的属性
          newObject[f] = newObj[f];
        }
      }
    }
    if (filedNames.length > 0) {
      // 有被删除的属性
      for (let nf in filedNames) {
        newObject[nf] = null;
      }
    }
  }
  if (isValidObject(newObject)) return newObject;
  else return null;
}

/**
 * 判断传入的对象是否是有效对象，如果有效返回true
 * null,undefined,{}和所有属性都无效的都是无效对象
 * @param obj
 * @returns {boolean}
 */
export function isValidObject(obj) {
  if (obj) {
    let arr = Object.getOwnPropertyNames(obj);
    if (arr.length > 0) {
      let json = JSON.stringify(obj);
      if (json !== "{}") {
        for (let field in arr) {
          if (
            isType(obj[arr[field]]) === "Boolean" ||
            obj[arr[field]] ||
            obj[arr[field]] == 0 ||
            obj[arr[field]] === ""
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

/**
 * 两个数组合并去重
 * @param arg1
 * @param arg2
 * @returns {*}
 */
export function mergeArray(arg1, arg2) {
  let arr1 = [].concat(arg1);
  let arr2 = [].concat(arg2);
  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      if (arr1[i] === arr2[j]) {
        arr1.splice(i, 1); //利用splice函数删除元素，从第i个位置，截取长度为1的元素
      }
    }
  }
  //alert(arr1.length)
  for (let i = 0; i < arr2.length; i++) {
    arr1.push(arr2[i]);
  }
  return arr1;
}
/**
 * 数组内去重
 * @param array
 * @return {Array}
 */
export function uniqArray(array) {
  let temp = [];
  //let index = [];
  let l = array.length;
  for (let i = 0; i < l; i++) {
    for (let j = i + 1; j < l; j++) {
      if (array[i] === array[j]) {
        i++;
        j = i;
      }
    }
    temp.push(array[i]);
    //index.push(i);
  }
  //console.log(index);
  return temp;
}
/**
 * 将array中指定的值元素移除
 * @param {Object} array
 * @param {Object} value
 * @param {Function} tjf
 */
export function arrayRemoveValue(array, value, tjf) {
  let i = array.length;
  while (i--) {
    //console.log(i + "=" + array[i]);
    if (tjf && tjf(array[i], value)) {
      array.splice(i, 1);
    } else if (isObjEqual(array[i], value)) {
      array.splice(i, 1);
    }
  }
}
/**
 * 比较两个对象是否一样
 * @param {Object} o1
 * @param {Object} o2
 */
export function isObjEqual(o1, o2) {
  if (isType(o1) === isType(o2)) {
    if (isType(o1) === "Object") {
      //如果是对象，按照下列方法对比
      let props1 = Reflect.ownKeys(o1);
      arrayRemoveValue(props1, "__ob__");
      let props2 = Reflect.ownKeys(o2);
      arrayRemoveValue(props2, "__ob__");
      if (props1.length != props2.length) {
        return false;
      }
      for (let i = 0, max = props1.length; i < max; i++) {
        if (!isObjEqual(o1[props1[i]], o2[props1[i]])) {
          return false;
        }
      }
      return true;
    } else if (isType(o1) === "Function" || isType(o1) === "RegExp") {
      return false;
    } else if (isType(o1) === "Date") {
      //比较时间
      return o1.getTime() == o2.getTime();
    } else if (isType(o1) === "Array") {
      //比较数组
      if (o1.length != o2.length) {
        //两个数组长度不一
        return false;
      }
      if (o1.length == 0) {
        //两个空数组
        return true;
      }
      let someIndex = [];
      for (let i = 0; i < o1.length; i++) {
        let tempObj = o1[i];
        //从o2中找到和o1中的tempObj一样的对象的位置
        let index2 = o2.findIndex(function (yObj) {
          return isObjEqual(yObj, tempObj);
        });
        if (index2 >= 0) {
          someIndex.push(i);
        }
      }
      return o1.length == someIndex.length;
    } else {
      //其他类型可以直接比较
      return o1 === o2;
    }
  }
  return false;
}

/**
 * 从URL地址中解析出所有请求参数，并返回成一个对象，无参数则返回空对象
 * @param {Object} url
 * @return {Object}
 */
export function getQueryParams(url) {
  if (url && url.length > 0) {
    if (url.indexOf("?") >= 0) {
      url = url.substring(url.indexOf("?"));
    }
    //console.log("需要解析的url:"+url);
    try {
      var reg = new RegExp("(?!\\?|&)([^&|\\?]+)={0,1}([^&]+)", "ig");
      var allParams = url.match(reg);
      if (allParams) {
        var len = allParams.length;
        var temp = {};
        for (var i = 0; i < len; i++) {
          var t = allParams[i].split("=");
          temp[t[0]] = t[1];
        }
        //console.log(temp);
        return temp;
      }
    } catch (e) {
      console.log(e);
    }
    return {};
  }
  return {};
}

/**
 * 在cookie中添加对应名称的数据
 * @param {String} cname
 * @param {String} cvalue
 * @param {int} exdays 有效天数
 */
export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

/**
 * 清空所有cookie
 */
export function clearAllCookie() {
  var date = new Date();
  date.setTime(date.getTime() - 10000);
  var keys = document.cookie.match(/[^ =;]+(?=\\=)/g);
  console.log("需要删除的cookie名字：" + keys);
  if (keys) {
    for (var i = keys.length; i--; )
      document.cookie =
        keys[i] + "=0; expire=" + date.toGMTString() + "; path=/";
  }
}

/**
 * 删除指定name的cookie值
 * @param cname
 */
export function removeCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  var cookieStr = "";
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(name) == 0) {
      document.cookie =
        c + ";expires=" + new Date(0).toUTCString() + "; path=/";
    } else {
      cookieStr += c;
      cookieStr += ";";
    }
    document.cookie = cookieStr;
  }
}
/**
 * 从cookie中读取对应名称的数据
 * @param name
 * @returns {String}
 */
export function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

/**
 * 测试当前浏览器是否支持H5Storage存储
 * @returns {Boolean}
 */
export function havaStorage() {
  if (typeof Storage !== "undefined") {
    return true;
  } else {
    return false;
  }
}

/**
 * 将对应名称的值存入localStorage
 * @param name
 * @param value
 */
export function storageAdd(name, value) {
  localStorage.setItem(name, value);
}

/**
 * 从localStorage中获取对应名称的值
 * @param name
 * @returns {String}
 */
export function getStorageValue(name) {
  return localStorage.getItem(name);
}

/**
 * 从localStorage移出指定的数据
 * @param name
 */
export function removeStorageValue(name) {
  localStorage.removeItem(name);
}

/**
 * 将字符串转换为对象
 * @param str
 * @returns
 */
export function strToJson(str) {
  var json = eval("(" + str + ")");
  return json;
}

/**
 * 将字符串中的转义字符进行反转义
 * @param str
 * @returns
 */
export function htmlDecode(text) {
  var temp = document.createElement("div");
  temp.innerHTML = text;
  var output = temp.innerText || temp.textContent;
  temp = null;
  return output;
}

/**
 * 解析附件列表
 * 注意：本方法只能解析表单数据中的“_ATTACHMENTS”字段数据
 * @author wangxin
 * @param {String} htmltext
 * @return {Array}
 */
export function analysisFile(htmltext) {
  //console.log(htmltext);
  let text1 = htmlDecode(htmltext);
  let obj = strToJson(text1);
  var fjhtml = obj.attachments;
  while (fjhtml.match("&&left;") != null) {
    fjhtml = fjhtml.replace("&&left;", "<");
  }
  while (fjhtml.match("&&right;") != null) {
    fjhtml = fjhtml.replace("&&right;", ">");
  }
  console.log(fjhtml);
  var allfile = getAllFileTypeStr();
  var reg = new RegExp("/UploadFiles/[^:]+?\\.(" + allfile + ")", "ig");
  var imgpath = fjhtml.match(reg);
  fjhtml = htmlDecode(fjhtml);
  console.log(imgpath);
  var files = [];
  //检查是否有多个附件
  if (imgpath != null && isType(imgpath) === "Array" && imgpath.length > 1) {
    var tempfiles = imgpath;
    var tmepfns = fjhtml.split(";");
    for (var i in tempfiles) {
      let temfilename = tmepfns[i];
      var filesize = "";
      if (temfilename.indexOf("  ") > 0) {
        let tary = temfilename.split("  ");
        temfilename = tary[0];
        filesize = tary[tary.length - 1];
      }
      let file = {
        filename: temfilename,
        filesize: filesize,
        filepath: tempfiles[i]
      };
      files.push(file);
    }
  } else {
    let temfilename = fjhtml;
    let filesize = "";
    if (temfilename.indexOf("  ") > 0) {
      let tary = temfilename.split("  ");
      temfilename = tary[0];
      filesize = tary[tary.length - 1];
    }
    let file = {
      filename: temfilename,
      filesize: filesize,
      filepath: imgpath[0]
    };
    files.push(file);
  }
  return files;
}

/**
 * 将指定颜色变暗或变亮
 * @param {Object} col
 * @param {Object} amt
 */
export function lightenDarkenColor(col, amt) {
  let usePound = false;
  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }
  let num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  //console.log(r+"\n"+b+'\n'+g);
  let temp = ((r << 16) | (b << 8) | g).toString(16);
  let zore = "";
  if (temp.length < 6) {
    for (let i = 0; i < 6 - temp.length; i++) {
      zore += "0";
    }
  }
  return (usePound ? "#" : "") + zore + temp;
}

/**
 * 根据背景色，推荐最佳对比度字体颜色
 * @param {Object} hexcolor
 */
export function getContrastYIQ(hexcolor) {
  let r = parseInt(hexcolor.substr(0, 2), 16);
  let g = parseInt(hexcolor.substr(2, 2), 16);
  let b = parseInt(hexcolor.substr(4, 2), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

/**
 * 删除字符串左右两端的空格
 * @author wangxin
 * @param {String} str
 * @return {String}
 */
export function trim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}
/**
 * object是否为空
 * @object 判断的object
 */
export function isEmptyObject(object) {
  for (let key in object) {
    return key == undefined;
  }
  return true;
}
/**
 *  把换行，制表符转换为空字符
 * @param {Object} str
 */
export function trimEmtpy(str) {
  if (str) {
    str = str.replace(/\r\n*/g, "");
    return str;
  }
  return str;
}
/**
 *  把换行，制表符，字符串内部无效空格都转换为空字符
 * @param {Object} str
 */
export function trimAll(str) {
  if (str) {
    str = str.replace(/\r\n*/g, "");
    return str.replace(/\s*/g, "");
  }
  return str;
}
/**
 * 删除字符串左边的空格
 * @author wangxin
 * @param {String} str
 * @return {String}
 */
export function ltrim(str) {
  return str.replace(/(^\s*)/g, "");
}
/**
 * 删除字符串右边的空格
 * @author wangxin
 * @param {String} str
 * @return {String}
 */
export function rtrim(str) {
  return str.replace(/(\s*$)/g, "");
}

/**
 * 把无效字符串
 * @param str
 * @returns {*}
 */
export function changeNullToEmptyString(str) {
  if (
    str === undefined ||
    str == null ||
    (isType(str) === "String" &&
      (str === "null" || str === "NULL" || str === "Null"))
  )
    return "";
  return str;
}

/**
 * 获取数组中元素对象包含有指定属性名和值的数组元素所在index
 * @author wangxin
 * @param {Array} arraytosearch
 * @param {String} key 属性名
 * @param {String} valuetosearch 属性值
 * @return {int}
 */
export function toFindArrayIndexByKeyValue(arraytosearch, key, valuetosearch) {
  for (var i = 0; i < arraytosearch.length; i++) {
    if (arraytosearch[i][key] === valuetosearch) {
      return i;
    }
  }
  return -1;
}

/**
 * 获取数组中指定值的数组元素所在index
 * @author wangxin
 * @param {Array} arraytosearch
 * @param {String} valuetosearch 属性值
 * @return {int}
 */
export function arrayIndexOf(arraytosearch, valuetosearch) {
  for (var i = 0; i < arraytosearch.length; i++) {
    if (arraytosearch[i] === valuetosearch) {
      return i;
    }
  }
  return -1;
}

/**
 * 获取当前登录name
 */
export function getCurrentUser() {
  var userinfo = localStorage.getItem("userinfo");
  if (!userinfo) {
    return null;
  }
  return JSON.parse(userinfo).name;
}

/**
 * 获取当前登录用户的部门
 */
export function getCurrentDepart() {
  var userinfo = localStorage.getItem("userinfo");
  if (!userinfo) {
    return null;
  }
  return JSON.parse(userinfo).Org;
}

/**
 * 获取token
 */
export function getToken() {
  var userinfo = localStorage.getItem("userinfo");

  if (!userinfo) {
    return null;
  }

  return JSON.parse(userinfo).token;
}

/**
 * 给element添加eventName的事件监听
 * @param element
 * @param eventName
 * @param callback
 * @param useCapture
 */
export function domAddEventListener(element, eventName, callback, useCapture) {
  if (element.addEventListener) {
    element.addEventListener(
      eventName,
      callback,
      useCapture ? useCapture : false
    );
  } else {
    element.attachEvent(eventName, callback);
  }
}

/**
 * 触发element的事件eventName
 * @param element
 * @param eventName
 */
export function domDispatchEvent(element, eventName) {
  if ("createEvent" in document) {
    let evt = document.createEvent("HTMLEvents");
    evt.initEvent(eventName, false, true);
    element.dispatchEvent(evt);
  } else element.fireEvent("on" + eventName);
}

/**
 * IE浏览器dom解析兼容写法,获取节点属性
 * @param element
 * @param name
 * @returns {ASTNode|string|*}
 */
export function getElementAttribuie(element, name) {
  if (element) {
    if (element.attributes) {
      let attr = element.attributes[name];
      if (attr && attr.value) {
        return attr.value;
      }
    }
    return element.getAttribute(name);
  }
  return null;
}

/**
 * 节点对象是否包含class属性名对应的值
 * @param element
 * @param className
 * @return {boolean}
 */
export function elementHasClass(element, className) {
  if (
    "classList" in element &&
    typeof element.classList.contains === "function"
  ) {
    return element.classList.contains(className);
  } else {
    var classes = element.className.split(/\s+/);
    for (var i = 0; i < classes.length; i++) {
      if (classes[i] === className) {
        return true;
      }
    }
    return false;
  }
}

/**
 * IE浏览器dom解析兼容写法，获取指定节点名称的节点对象数组
 * @param doc
 * @param tagName
 * @returns {HTMLCollectionOf<SVGElementTagNameMap[*]>|ActiveX.IXMLDOMNodeList|HTMLCollectionOf<HTMLElementTagNameMap[*]>|HTMLCollectionOf<Element>|undefined|NodeListOf<HTMLElementTagNameMap[*]>|NodeListOf<Element>|NodeListOf<SVGElementTagNameMap[*]>}
 */
export function queryDomSelectorAll(doc, tagName) {
  try {
    let allElement = doc.querySelectorAll(tagName);
    if (allElement) {
      return allElement;
    }
  } catch (e) {
    return doc.getElementsByTagName(tagName);
  }
}

/**
 * IE浏览器dom解析兼容写法，检查节点为非文本节点
 * @param obj
 * @returns Boolean
 */
export function checkNoTextNode(obj) {
  if (obj instanceof Element || obj.nodeType == 1 || obj.nodeType == 9) {
    return true;
  }
  return false;
}

/**
 * IE浏览器dom解析兼容写法，检查节点的文本内容
 * @param obj
 * @returns {*}
 */
export function getNodeInnerHtml(obj) {
  return obj.innerHTML
    ? obj.innerHTML
    : obj.childNodes[0]
    ? obj.childNodes[0].nodeValue
    : "";
}

/**
 * 获取指定checkbox或radio控件的所有可选项目
 * @param $el jquery对象,被检索的checkbox或radio对象
 * @param $doml jquery对象，被检索的范围
 */
export function getCheckBoxOrRadioOptions($el, $dom) {
  let option = [];
  if ($el) {
    let name = $el.attr("name"),
      allText = [];
    if (!$el.attr("title")) {
      //如果是老模式下，则需要从其兄弟节点中遍历出所有有效的文本节点
      let textNodes = $el
        .parent()
        .contents()
        .filter(function () {
          return this.nodeType === 3 && trim($(this).text());
        });
      textNodes.each(function () {
        allText.push($(this).text());
      });
      textNodes.remove();
    }
    $dom.find("input[name='" + name + "']").each(function (n) {
      if ($(this).attr("title")) {
        //新版控件
        option.push({
          label: $(this).attr("title"),
          value: $(this).val()
        });
      } else {
        //老模板控件
        option.push({
          label: allText[n],
          value: $(this).val()
        });
      }
    });
  }
  return option;
}

/**
 * 从对象中找到 包含属性paramName值为paramValue的子对象
 * @param obj
 * @param paramName
 * @param paramValue
 * @returns {*}
 */
export function getObjByParamAndValue(obj, paramName, paramValue) {
  if (obj && paramName && paramValue !== undefined && paramValue != null) {
    if (isType(obj) === "Object") {
      let paramKeys = Object.keys(obj);
      for (let key = 0; key < paramKeys.length; key++) {
        let tempType = isType(obj[paramKeys[key]]);
        if (tempType === "Object" || tempType === "Array") {
          let tempValue = getObjByParamAndValue(
            obj[paramKeys[key]],
            paramName,
            paramValue
          );
          if (tempValue) return tempValue;
        } else if (
          tempType === "val" ||
          tempType === "Boolean" ||
          tempType === "String"
        ) {
          if (
            paramName === paramKeys[key] &&
            obj[paramKeys[key]] === paramValue
          ) {
            return obj;
          }
        }
      }
    } else if (isType(obj) === "Array") {
      for (let index = 0; index < obj.length; index++) {
        if (isType(obj[index]) === "Object") {
          let tempValue = getObjByParamAndValue(
            obj[index],
            paramName,
            paramValue
          );
          if (tempValue) return tempValue;
        }
      }
    }
  }
  return null;
}

/**
 * 从对象中找到 包含属性paramName值为paramValue的子对象的所在父对象
 * @param obj
 * @param paramName
 * @param paramValue
 * @returns {*}
 */
export function getParentObjByChildParamAndValue(obj, paramName, paramValue) {
  if (obj && paramName && paramValue !== undefined && paramValue != null) {
    if (isType(obj) === "Object") {
      let paramKeys = Object.keys(obj);
      for (let key = 0; key < paramKeys.length; key++) {
        let tempType = isType(obj[paramKeys[key]]);
        if (tempType === "Object" || tempType === "Array") {
          let tempValue = getParentObjByChildParamAndValue(
            obj[paramKeys[key]],
            paramName,
            paramValue
          );
          if (tempValue) {
            if (tempValue == 1) {
              return obj;
            } else {
              return tempValue;
            }
          }
        } else if (
          tempType === "val" ||
          tempType === "Boolean" ||
          tempType === "String"
        ) {
          if (
            paramName === paramKeys[key] &&
            obj[paramKeys[key]] === paramValue
          ) {
            return 1;
          }
        }
      }
    } else if (isType(obj) === "Array") {
      for (let index = 0; index < obj.length; index++) {
        if (isType(obj[index]) === "Object") {
          let tempValue = getParentObjByChildParamAndValue(
            obj[index],
            paramName,
            paramValue
          );
          if (tempValue) {
            if (tempValue == 1) return 1;
            else return tempValue;
          }
        }
      }
    }
  }
  return null;
}

/**
 * 从对象中找到 包含属性paramName值为paramValue的子对象所在的遍历层级
 * @param obj
 * @param paramName
 * @param paramValue
 * @returns {*}
 */
export function getErgodicLevelByParamAndValue(
  obj,
  paramName,
  paramValue,
  plevel
) {
  let level = plevel != undefined ? plevel + 1 : -1;
  if (obj && paramName && paramValue !== undefined && paramValue != null) {
    if (isType(obj) === "Object") {
      let paramKeys = Object.keys(obj);
      for (let key = 0; key < paramKeys.length; key++) {
        let tempType = isType(obj[paramKeys[key]]);
        if (tempType === "Object" || tempType === "Array") {
          let resultLevel = getErgodicLevelByParamAndValue(
            obj[paramKeys[key]],
            paramName,
            paramValue,
            level - 1
          );
          if (resultLevel >= 0) return resultLevel;
        } else if (
          tempType === "val" ||
          tempType === "Boolean" ||
          tempType === "String"
        ) {
          if (
            paramName === paramKeys[key] &&
            obj[paramKeys[key]] === paramValue
          ) {
            return level;
          }
        }
      }
    } else if (isType(obj) === "Array") {
      for (let index = 0; index < obj.length; index++) {
        if (isType(obj[index]) === "Object") {
          let tempValue = getErgodicLevelByParamAndValue(
            obj[index],
            paramName,
            paramValue,
            level
          );
          if (tempValue >= 0) return tempValue;
        }
      }
    }
  }
  return -1;
}

/**
 * 从对象中找到 包含属性paramName值为paramValue的子对象
 * @param obj
 * @param paramName
 * @param paramValue
 * @param type 类型 0：全文匹配，1：模糊匹配
 * @param sz 结果数组
 * @returns {*}
 */
export function getObjArrayByParamAndValue(
  obj,
  paramName,
  paramValue,
  type,
  sz
) {
  if (type != 0 && type != 1) {
    type = 0;
  }
  if (isType(obj) === "Object") {
    let paramKeys = Object.keys(obj);
    for (let key = 0; key < paramKeys.length; key++) {
      let tempType = isType(obj[paramKeys[key]]);
      if (tempType === "Object" || tempType === "Array") {
        getObjArrayByParamAndValue(
          obj[paramKeys[key]],
          paramName,
          paramValue,
          type,
          sz
        );
      } else if (
        tempType === "val" ||
        tempType === "Boolean" ||
        tempType === "String"
      ) {
        if (type == 0) {
          if (
            paramName === paramKeys[key] &&
            obj[paramKeys[key]].toUpperCase() === paramValue.toUpperCase()
          ) {
            sz.push(obj);
          }
        } else if (type == 1) {
          if (
            paramName === paramKeys[key] &&
            obj[paramKeys[key]].toUpperCase().includes(paramValue.toUpperCase())
          ) {
            sz.push(obj);
          }
        }
      }
    }
  } else if (isType(obj) === "Array") {
    for (let index = 0; index < obj.length; index++) {
      if (isType(obj[index]) === "Object") {
        getObjArrayByParamAndValue(obj[index], paramName, paramValue, type, sz);
      }
    }
  }
}

/**
 * 从对象中找到所有的属性paramName值返回为数组
 * @param obj
 * @param paramName
 * @param sz 结果数组
 * @returns {*}
 */
export function getValueArrayByParam(obj, paramName, sz) {
  if (isType(obj) === "Object") {
    let paramKeys = Object.keys(obj);
    for (let key = 0; key < paramKeys.length; key++) {
      let tempType = isType(obj[paramKeys[key]]);
      if (tempType === "Object" || tempType === "Array") {
        getValueArrayByParam(obj[paramKeys[key]], paramName, sz);
      } else if (
        tempType === "val" ||
        tempType === "Boolean" ||
        tempType === "String"
      ) {
        if (paramName === paramKeys[key]) {
          sz.push(obj[paramKeys[key]]);
        }
      }
    }
  } else if (isType(obj) === "Array") {
    for (let index = 0; index < obj.length; index++) {
      if (isType(obj[index]) === "Object") {
        getValueArrayByParam(obj[index], paramName, sz);
      }
    }
  }
}

/**
 * 把对象进行深度复制
 * @param obj
 * @return {*}
 */
export function deepCopy(obj) {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
}

/**
 * 如果是数值字符
 * @param data
 * @returns {boolean}
 */
export function isNumberString(data) {
  if (isType(data) === "String") {
    let reg = new RegExp(
      "^[-+]?(([0-9]+)([.]([0-9]+))?|([.]([0-9]+))?)$",
      "ig"
    );
    return reg.test(data);
  }
  return false;
}

/**
 * 函数：获取窗口尺寸
 */
export function findDimensions() {
  let winWidth, winHeight;
  //获取窗口宽度
  if (window.innerWidth) winWidth = window.innerWidth;
  else if (document.body && document.body.clientWidth)
    winWidth = document.body.clientWidth;
  //获取窗口高度
  if (window.innerHeight) winHeight = window.innerHeight;
  else if (document.body && document.body.clientHeight)
    winHeight = document.body.clientHeight;
  //通过深入Document内部对body进行检测，获取窗口大小
  if (
    document.documentElement &&
    document.documentElement.clientHeight &&
    document.documentElement.clientWidth
  ) {
    winHeight = document.documentElement.clientHeight;
    winWidth = document.documentElement.clientWidth;
  }
  return {
    height: winHeight,
    width: winWidth
  };
}

/**
 * 获取字符串中所有双字节字符
 * @param {Object} str
 */
export function getDoubleCharSize(str) {
  let patt1 = /[^\\x00-\\xff]/g;
  let tmepArr = str.match(patt1);
  if (tmepArr) {
    return tmepArr.length;
  }
  return 0;
}
/**
 * 获取字符串中包含的相当于半字节字符的数量
 * @param {Object} str
 */
export function getHalfCharSize(str) {
  let patt1 = /[ijlI:.\\(\\),!]/g;
  let tmepArr = str.match(patt1);
  if (tmepArr) {
    return tmepArr.length;
  }
  return 0;
}
/**
 * 获取字符串中包含的大写字母或相当于大写字母的字符数量
 * @param {Object} str
 */
export function getUpperCaseSize(str) {
  let patt1 = /[ABCDEFJHGKLMNOPQRSTUVWXYZ\\%~]/g;
  let tmepArr = str.match(patt1);
  if (tmepArr) {
    return tmepArr.length;
  }
  return 0;
}

/**
 * 带变量参数URL字符串，进行[参数]注入
 * @param {string} url
 * @parma {object} params
 * @param {bool} type 判断数据类型，字符串需要解析成'value'，否则都只是直接替换内容
 */
export function urlParamInjection(url, params, type) {
  if (url) {
    var reg = new RegExp("\\[([^\\]]+)\\]", "ig"); //从字符串中匹配出所有用[]括起来的字段
    var allFields = url.match(reg);
    var newUrl = url;
    if (allFields && allFields.length > 0) {
      for (var j = 0; j < allFields.length; j++) {
        var fieldName = allFields[j].slice(0, -1).slice(1);
        if (params) {
          var v = params[fieldName];
          if (type) {
            if (isType(v) === "val") {
              newUrl = newUrl.replace(allFields[j], v);
            } else {
              newUrl = newUrl.replace(allFields[j], "'" + v + "'");
            }
          } else {
            newUrl = newUrl.replace(allFields[j], v);
          }
        }
      }
    }
    return newUrl;
  }
  return url;
}

/**
 * 将对象中属性值为字符串的内容中包含的一些特殊字符进行转义
 * @param doParam
 * @returns {*}
 */
export function ecodeObjectHtmlString(doParam) {
  if (isType(doParam) === "Object" || isType(doParam) === "Array") {
    for (var key in doParam) {
      doParam[key] = ecodeObjectHtmlString(doParam[key]);
    }
  } else if (isType(doParam) === "String") {
    doParam = encodeURIComponent(doParam.replace(/"/g, '\\"'));
  }
  return doParam;
}

/**
 * 重新封装axios.get请求
 * @param action
 * @param params
 * @param success
 * @param error
 */
export function get(action, params, success, error, unloginCallbak) {
  let doParam = {
    token:
      action === appConfig.API_CONFIGS["用户登录接口"] ? "" : getLoginState(),
    from: getBrowserType()
  };
  if (params && isType(params) === "Object")
    doParam = Object.assign({}, doParam, params);
  // doParam = ecodeObjectHtmlString(doParam);
  let qs = Qs;
  //先把请求参数对象中的值包含的中文和特殊字符进行编码,并转换成GET请求参数格式
  let qsData = qs.stringify(doParam);
  axios
    .get(appConfig.HTTPS_SERVICE_URI + "/" + action + "?" + qsData)
    .then(function (resp) {
      let data = resp.data;
      if (data.success) {
        //数据提交成功
        if (success) success(data);
      } else {
        if (
          data.code === 0 &&
          action !== appConfig.API_CONFIGS["用户登录接口"]
        ) {
          //登录认证失效,需要重新去拉取登录认证信息
          removeCookie("userinfo");
          removeCookie("Org");
          removeStorageValue("userinfo");
          axios.defaults.headers.common["Authorization"] = "";
          getLoginState(
            unloginCallbak,
            appConfig.JUMP_PATH_AFTER_RECERTIFICATION
          );
        } else if (error) error(data);
      }
    })
    .catch(function (e) {
      if (error)
        error({
          success: false,
          error: e,
          message: "请求失败"
        });
    });
}

/**
 * 重新封装axios.post请求
 * @param action
 * @param params
 * @param success
 * @param error
 */
export async function post(
  action,
  params,
  success,
  error,
  unloginCallbak,
  HTTPS
) {
  console.log("1111111111111111111111111111111111111111111111111111");
  var Url = appConfig.HTTPS_SERVICE_URI; ///api/core 核心APP
  if (HTTPS != null && HTTPS != undefined && HTTPS != "") {
    Url = HTTPS; ///api/budget 概算模块
  }
  let doParam = {
    token:
      action === appConfig.API_CONFIGS["用户登录接口"] ||
      action === appConfig.API_CONFIGS["钉钉用户登录接口"]
        ? ""
        : getLoginState(),
    from: getBrowserType()
  };
  if (params && isType(params) === "Object")
    doParam = Object.assign({}, doParam, params);
  // doParam = ecodeObjectHtmlString(doParam);
  /*//先把请求参数对象中的值包含的中文和特殊字符进行编码,并转换成GET请求参数格式
    let qsData = qs.stringify(doParam, { encodeValuesOnly: true });
    //再把GET请求参数再转回成json对象，并且其中的编码值不再进行反编码
    let nep = qs.parse(qsData, { decoder: function(str, decoder, charset) {
        return str;
      }});*/
  // 登录成功后写入鉴权信息

  console.log(action);
  console.log(doParam);
  console.log("zshttp--------------->", zshttp);
  await zshttp
    .post(Url + "/" + action, doParam)
    .then(function (resp) {
      let resData = resp;
      let data = resData.data;
      if (data.Success) {
        //数据提交成功
        if (success) success(data);
      } else {
        if (
          data.code === 0 &&
          action !== appConfig.API_CONFIGS["用户登录接口"] &&
          action !== appConfig.API_CONFIGS["钉钉用户登录接口"]
        ) {
          //登录认证失效,需要重新去拉取登录认证信息
          removeCookie("userinfo");
          removeCookie("Org");
          removeStorageValue("userinfo");
          zshttp.setDefaultConfigs({
            headers: { common: { Authorization: "" } }
          });
          //axios.defaults.headers.common["Authorization"] = "";
          getLoginState(
            unloginCallbak,
            appConfig.JUMP_PATH_AFTER_RECERTIFICATION
          );
        } else if (error) error(data);
      }
    })
    .catch(function (e) {
      console.log("ex", e);
      if (error)
        error({
          success: false,
          error: e,
          message: "请求失败"
        });
    });
}

/**
 * 保存数据到当前浏览器存储
 */
export function saveLoaclData(name, data, time) {
  // var saveTime = isType(time) === "val" ? time : 240;
  if (window.sessionStorage) {
    window.sessionStorage.setItem(name, data);
  } else {
    setCookie(name, data, time);
  }
}

export function setToken(data) {
  console.log("准备写入token了:" + data);
  if (data) {
    zshttp.setDefaultConfigs({
      headers: { common: { Authorization: "Bearer " + data } }
    });
  } else {
    zshttp.setDefaultConfigs({
      headers: { common: { Authorization: "Bearer " } }
    });
  }
  console.log("token写入结束", zshttp);
}

/**
 * 从当前浏览器存储获取对应名称的数据
 */
export function getLocalData(name) {
  if (window.sessionStorage) {
    return sessionStorage.getItem(name);
  } else {
    return getCookie(name);
  }
}

/**
 * 从当前浏览器存储移除对应名称的数据
 */
export function removeLocalData(name) {
  if (window.sessionStorage) {
    sessionStorage.removeItem(name);
  } else {
    removeCookie(name);
  }
}

/**
 * 把对象拼接成 i4=4&i1=1&i2=2&i3=3格式
 * @param param
 * @param key
 * @returns {string}
 */
export function parseParam(param, key) {
  var paramStr = "";
  var paramType = isType(param);
  if (paramType === "String") {
    //param = param.replace(/\+/g, "$ADD$"); // 特殊字符转义
    //console.log("请求参数~！",param);
    paramStr += "&" + key + "=" + encodeURIComponent(param);
  } else if (paramType === "val" || paramType === "Boolean") {
    paramStr += "&" + key + "=" + encodeURIComponent(param);
  } else if (
    paramType === "Undefined" ||
    paramType === "Null" ||
    paramType === "Function" ||
    paramType === "RegExp"
  ) {
    return "";
  } else if (paramType === "Date") {
    paramStr +=
      "&" +
      key +
      "=" +
      this.dateUtils.pattern(param, this.dateUtils.UNITS.DEFAULT_FORMAT);
  } else {
    if (paramType === "Object") {
      var paramKeys = Object.keys(param);
      var that = this;
      for (var i = 0; i < paramKeys.length; i++) {
        var k = isType(key) !== "String" ? paramKeys[i] : key;
        var v = that.parseParam(param[paramKeys[i]], k);
        if (v !== "") paramStr += "&" + v;
      }
    } else if (isType(key) === "String" && paramType === "Array") {
      //数组参数需要特殊处理
    }
    /* paramKeys.forEach(function(i) {
             var k =
               key == null
                 ? i
                 : key + (paramType === "Array" ? "[" + i + "]" : "." + i);
             var v = that.parseParam(param[k], k);
             if(v!=="") paramStr += "&" + v;
         });*/
  }
  return paramStr.substr(1);
}

/**
 * 调用jquery 的Ajax实现GET请求
 */
export function jqueryGet(url, param, callBack, async) {
  var path = url;
  if (isType(param) === "Object") {
    var paramStr = parseParam(param);
    if (paramStr !== "") {
      if (url.indexOf("?") == -1) path = path + "?" + paramStr;
      else path = path + "&" + paramStr;
    }
  }
  $.ajax({
    url: path,
    type: "GET",
    async: $.type(async) === "boolean" ? async : true,
    success: function (data) {
      if (isType(data) === "String") {
        try {
          callBack(JSON.parse(data));
        } catch (e) {
          callBack(data);
        }
      } else {
        callBack(data);
      }
    },
    error: function (XMLHttpRequest) {
      var error = "";
      if (XMLHttpRequest.status == 0) {
        error = "连接中断";
      } else if (XMLHttpRequest.status == 404) {
        error = "服务地址不存在";
      } else if (XMLHttpRequest.status == 500) {
        error = "服务不存在";
      } else {
        error = XMLHttpRequest.responseText;
      }
      callBack({
        success: false,
        message: "服务异常：" + XMLHttpRequest.status + "-" + error
      });
    }
  });
}
