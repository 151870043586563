/**
 * 登录认证相关方法
 * @creator wangxin
 */
//import axios from "axios";
//import { zshttp } from "@zstech/zs-commons-library";
import appConfig from "./config";
import {
  havaStorage,
  getCookie,
  getQueryParams,
  getStorageValue,
  storageAdd,
  isApp,
  isType,
  setToken
} from "./util";
import $ from "jquery";
// import pluginState from "@/assets/js/plugin-state";

/**
 * 从企业微信获取登录授权信息，（授权成功会给本地cookie写入当前登录用户信息）
 */
export function doWeixinlogin(loginToPage) {
  window.location.href =
    appConfig.BUSSINESS_SERVER_URL +
    "?RedirectUrl=" +
    (loginToPage
      ? encodeURIComponent(loginToPage)
      : encodeURIComponent(window.location.href));
  // window.location.href = BUSSINESS_SERVER_URL + '/Loign';
}

/**
 * @description 从本地Storage或cookie中读取登录用户数据
 * @return {String}
 */
export function getNowLoginUser() {
  //return pluginState.accessToken;
  var haveStorage = havaStorage();
  if (havaStorage) {
    //如果支持Storage，则认为taken保存在Storage中
    let userinfo = getStorageValue("userinfo");
    if (userinfo != undefined && userinfo != null) {
      var jsonobj = JSON.parse(userinfo);
      if (jsonobj.token != undefined && jsonobj.token != null)
        return jsonobj.token;
    }
  }
  //如果在Storage中未找到userinfo，则从cookie中寻找
  let userinfo = getCookie("userinfo");
  if (userinfo != undefined && userinfo != null && userinfo != "") {
    var userInfoObj = getQueryParams(userinfo);
    //var userArray = userinfo.split(",");??需要重新定义返回的cookie值
    console.log("userinfo=0", userinfo, userInfoObj);
    if (!userInfoObj.token && haveStorage) {
      //如果支持Storage，则可以将cookie值写如Storage
      var useridt = userInfoObj.userid || null;
      //同步请求接口
      userinfo = {};
      $.ajaxSettings.async = false;
      $.post(
        appConfig.HTTPS_SERVICE_URI +
          "/" +
          appConfig.API_CONFIGS["用户登录接口"],
        JSON.stringify({
          account: useridt,
          password: ""
        }),
        function (res) {
          // 请求处理
          userinfo["token"] = res.data.Token;
          userinfo["name"] = res.data.Name;
          userinfo["moblie"] = res.data.Mobile;
          userinfo["Org"] = res.data.Department;
          userinfo["account"] = useridt;
        },
        "json"
      );
      $.ajaxSettings.async = true;
      storageAdd("userinfo", JSON.stringify(userinfo));
      // 登录成功后写入鉴权信息
      //axios.defaults.headers.common["Authorization"] = userinfo.token;
      setToken(userinfo.token);
    } else if (userInfoObj.token && haveStorage) {
      storageAdd("userinfo", JSON.stringify(userInfoObj));
      setToken(userInfoObj.token);
      //axios.defaults.headers.common["Authorization"] = userInfoObj.token;
    }
    return userInfoObj.token;
  } else if (haveStorage) {
    let url = window.location.href;
    let params = getQueryParams(url);
    if (params && params.iosAuthInfo) {
      //如果回调请求参数中包含ios认证信息，则可以根据此信息获取token信息
      userinfo = {};
      $.ajaxSettings.async = false;
      $.post(
        appConfig.HTTPS_SERVICE_URI +
          "/" +
          appConfig.API_CONFIGS["iso企业微信用户登录认证接口"],
        JSON.stringify({
          authInfo: params.iosAuthInfo
        }),
        function (res) {
          // 请求处理
          userinfo["token"] = res.data.Token;
          userinfo["name"] = res.data.Name;
          userinfo["moblie"] = res.data.Mobile;
          userinfo["Org"] = res.data.Department;
          userinfo["account"] = res.data.Account;
        },
        "json"
      );
      $.ajaxSettings.async = true;
      storageAdd("userinfo", JSON.stringify(userinfo));
      // 登录成功后写入鉴权信息
      setToken(userinfo.token);
      //axios.defaults.headers.common["Authorization"] = userinfo.token;
      return userinfo.token;
    }
  }
  return null;
}

/**
 * 检测当前的登录状态，如果未登录则从企业微信获取登录授权信息，
 * （授权成功会给本地cookie写入当前登录用户信息）；
 *  如果已登录则返回当前登录用户taken
 *  @param callBack 如果不需要微信回调授权则执行的函数
 *  @param loginToPage 微信授权后的跳转页面
 */
export function getLoginState(callBack, loginToPage) {
  var token = getNowLoginUser(); //'wangxin'; //
  if (token == null && !isApp() && loginToPage) {
    //doWeixinlogin(loginToPage);
    return;
  }
  isType(callBack) === "Function" && callBack(token);
  return token;
}
