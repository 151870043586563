/**
 * 系统全局配置
 * @creator hq
 */
export default {
  // 是否开发模式
  DEV_MODE: true,
  // APP版本
  APP_VER: "0.0.1",
  // 基础路径
  BASE_URL: "http://localhost:8082/",
  //可以设置在运行时使用提示框弹出日志信息,正式部署请修改为空数组[]
  ALERT_LOG: [],
  //不同文件类型对应的文件扩展名
  TYPE_FILE_EXTENSION: {
    doc: ["docx", "doc"],
    xls: ["xlsx", "xls"],
    ppt: ["pptx", "ppt"],
    pdf: ["pdf"],
    video: ["mp4", "3gp", "wmv", "avi", "mov", "rmvb", "rm", "flv", "mpg"],
    image: ["png", "jpg", "jpeg", "gif", "bmp", "webp"],
    txt: ["txt"]
  },
  //星期的中文名
  WEEK_CN: [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六"
  ],
  //业务服务器地址，如果是微信端访问则会使用本地址进行访问,用于微信授权的地址 http://localhost:2080/
  BUSSINESS_SERVER_URL: "http://localhost:8082/", //"http://localhost:8020/CommonModule/Weixin/WebApis/AuthorizeHander.ashx", //"http://localhost:2081/Weixin/Index.ashx",//"http://localhost:8020/CommonModule/Mobile",
  //业务接口地址，https://www.easy-mock.com/mock/5d5cbad71bb51228f20eb4ce/qhxny模拟数据测试地址，部署前请换为生产环境地址/HttpUtil，或者使用远程配置地址
  HTTPS_SERVICE_URI: "/api/core", //"http://localhost:8020/CommonModule/Mobile/WebApis", //"http://localhost:2081/HttpUtil",//
  HTTPS_Gs_URI: "/api/budget",
  //跳转到OA页面的中转登录页面
  OA_LOGING_JUMP_URL: "CommonModule/Mobile/WebPages/mobileLoginJump.aspx",
  //指定登录失效后微信重新认证后的跳转页面
  JUMP_PATH_AFTER_RECERTIFICATION: "/index.html#/NavigationPage",
  //应用服务的公司名称
  COMPANY_NAME: "古城煤矿发风及瓦斯氧化利用",
  //CP端浏览器访问，直接跳转到PC端，一般对应BASE_URL这个地址，如果需要前后端分离部署，则需要另外配置一个PC端的地址
  PC_TO_BACK: false,
  //可以自定义页面上touch工具的位置如：{top：0}，top,left,bottom,right四个参数设置数值,默认：{right: 15,bottom: 100}
  TOUCH_ID_POINT: null,
  //需要隐藏touch工具的页面
  TOUCH_ID_HIDE_PAGES: [
    "FormListPage",
    "MyNoticeListPage",
    "FormOriginalListPage",
    "MyFormMsgListPage",
    "CopyToMeListPage",
    "ReplyToMeListPage"
  ],
  //可以定义表单的必填标记显示再控件前方还是后方，before和after,默认是after
  FORM_REQUIRED_ICON_LOCATION: "after",
  //全局设置ueditor编辑器默认的字体
  SYS_U_EDITOR_FONT_FAMILY: "宋体",
  //全局设置ueditor编辑器默认的字号
  SYS_U_EDITOR_FONT_SIZE: "12px",
  //页面主题设置
  //背景色
  THEME_BACK_COLOR: "#f3f2f8",
  //元素主题色
  THEME_COLOR: "#4378be",
  //THEME_COLOR: "#fe7138",
  //明细表颜色
  THEME_MX_COLOR: "#F5F8FD",
  //控件元素边框主题
  THEME_BORDER: "1px solid rgb(177 175 175)",
  //主页面宫格菜单宫格是否显示边框
  MAIN_GRID_MENU_BRODER: false,
  //配置Excel是否需要明细导入
  UploaderTitle: "采购申请",
  //底部主菜单标签
  MAIN_TABER_ITEM: [
    {
      text: "首页",
      viewName: "NavigationPage",
      name: "main",
      icon: "shouye",
      iconPrefix: "icon"
    },
    {
      text: "我的处理",
      viewName: "FormListPage",
      name: "publish",
      icon: "lifangtilitiduomiantifangkuai2",
      iconPrefix: "icon",
      info: 0
    },
    {
      text: "通讯录",
      viewName: "FormOriginalListPage",
      name: "caogaoxiang",
      icon: "tongxunlu",
      iconPrefix: "icon"
    }
  ],
  ALL_EXT_COMPONENTS: [
    "ComponentDemo",
    "HeadNavBar",
    "PickerButton",
    "FormList",
    "ToDoFormListItem",
    "HandledFormListItem",
    "FromProcessingFlows",
    "MessageListItem",
    "MessageList",
    "MessageFlows",
    "FormTabbar",
    "FileListGrid",
    "Topped",
    "TouchID",
    "MenuGrid",
    "NewsListItem",
    "WeekSelect",
    "MeetingListItem",
    "PatternLock",
    "SelectUser",
    "DepartSelect",
    "TreeSelect",
    "BusinessPushItem",
    "FormSelectOptions",
    "CSelect",
    "OriginalFormListItem",
    "ViewShowRow",
    "FormHtml",
    "FormInput",
    "FormTextarea",
    "FormUEditor",
    "FormButtonSelect",
    "FormCheckBox",
    "FormRadio",
    "FormPicker",
    "FormDatetimePicker",
    "ViewDataSelect",
    "CarSelect",
    "CarDriverSelect",
    "PopupTable",
    "ALink",
    "FileUploader"
  ],

  // 主界面首页的功能名与后端返回数据名对应关系
  NAVIGATION_PAGE_HINT_NUM_MAPPING: {
    收件: "我的处理",
    通知: "我的通知",
    抄送: "我的抄送",
    回函: "我的回函",
    表单消息: "表单消息"
  },
  // 主界面首页的功能模块分组名称，数组的index对应AppHomeMenus表的menuDefine字段存储的对象的group属性值
  NAVIGATION_PAGE_GROPUS: ["审批任务", "数字工地"],
  //服务接口定义
  API_CONFIGS: {
    用户登录接口: "Login/Login",
    钉钉用户登录接口: "Login/DingDingLogin",
    iso企业微信用户登录认证接口: "mobile/iosWeiXinChecklogin",
    获取主界面菜单配置信息接口: "Menu/GetAppSubMenu",
    获取底部菜单配置信息接口: "Menu/GetBottomMenu",
    获取主界面待处理信息数量接口: "Home/GetMyNoticeNum",
    获取指定表单数据接口: "Form/GetApplicationsData",
    视图数据查询接口: "formview/GetFormViewSearchParam",
    Notice设置为已读接口: "Notice/SetAllRead",
    分页获取我的表单记录接口: "workflow/getApplicationsPage",
    分页获取原稿记录接口: "workflow/getApplicationsPage",
    分页获取回函记录接口: "Notice/getApplicationsACopyPage",
    分页获取抄送给我的表单记录接口: "Notice/getApplicationsACopyPage",
    分页获取通知记录接口: "Notice/GetApplicationsACopyPage",
    获取表单流程图接口: "workflow/getWorkFlowImg",
    表单审批接口: "workflow/doProcess",
    表单撤销接口: "workflow/doCancelBack",
    获取表单数据的接口: "form/GetFormData",
    保存表单数据的接口: "form/formDataEditSave",
    新建表单数据保存接口: "form/formDataNewSave",
    发起抄送接口: "Notice/SendACopy",
    获取关卡加签数据接口: "workflow/getEchoLocation", //废弃接口
    表单加签接口: "workflow/AddGateForSigning",
    "1_获取关卡加签数据接口": "workflow/getAddGateNewAction",
    "1_表单加签接口": "workflow/addGate",
    业务推送详情获取接口: "mobile/getXXTSinfo",
    发送表单消息接口: "Notice/SendFormMessage",
    分页获取表单所有表单消息接口: "form/getFormMessagePage",
    获取表单所有表单消息接口: "form/GetFormMessage",
    获取指定表单消息数据对象接口: "form/getMutualFormMessageInfo",
    回复表单消息接口: "Notice/ReplyFormMessage",
    "1_回复表单消息接口": "Notice/ReplyFormMessage",
    获取一条个人通信录记录接口: "addresslist/getPersonsTxlInfo",
    获取通信录的部门分组接口: "addresslist/getMailDepartments",
    表单按钮_根据按钮配置获取指定表单数据接口: "workflow/GetContractFormDataId",
    获取会议详情内容接口: "meeting/getMeetingInfo",
    分页获取会议记录接口: "meeting/getMeetingsPage",
    分页获取新闻公共记录接口: "news/getNewsBulletinPage",
    获取新闻公告信息接口: "news/getNewsBulletinInfo",
    保存表单定义接口: "form/PostFormData",
    获取表单结构接口: "form/GetTemplateContentHandler",
    获取表单模板信息接口: "form/GetFormTemplateContent",
    获取系统部门结构接口: "Organization/GetSystemDepartments",
    根据部门获取所属用户记录接口: "Organization/GetAllUsers",
    获取系统所有可用表单模板选项接口: "form/getFormTemplateOptions",
    分页获取车辆记录接口: "car/getCarListPage", //上海申能投发项目定制接口
    分页获取汽车司机记录接口: "car/getCarDriverListPage", //上海申能投发项目定制接口
    获取枚举数据列表接口: "Enum/GetList", //上海申能投发项目定制接口
    获取指定类型的数据列表: "mobile/GetSelectOptions", //用于配合FormPicker解析select异步数据控件的数据获取接口，未实现
    获取表单或视图定义: "formview/GetViewColumns", //获取ViewDataSelect控件的列表项定义
    分页获取表单或视图列表: "formview/getFormOrViewDataPage", //用于配合ViewDataSelect控件获取数据，未实现ashx
    上传文件接口: "mobile/MobileUploadFiles", //用于配合FileUploader控件上传文件
    获取枚举数据缓存: "Enum/GetEnumCache",
    获取视图列表的配置信息: "formview/GetViewConfig",
    获取标准视图数据: "formview/GetViewData",
    树形数据查询接口: "formview/GetTreeViewAllData",
    同时获取部门和用户: "Organization/GetOrgAndUsersToTree",
    获取明细配置: "Blending/GetBlendingConfig", //选入明细使用
    获取选入明细数据: "Blending/GetBlendingData", //选入明细使用
    获取选入明细列数据: "Blending/GetColumnList", //选入明细列使用
    获取附件明细数据: "ImportMpp/GetImportExcelData", //明细选择附件上传解析到明细视图中接口
    获取扩展字段方法: "Home/AddorUpdateExfieldInfo",
    校验概算节点的使用情况: "Node/CheckNodeType",
    导入Excel: "notice/InsertDataBaseByExcel",
    采购合同合同总额: "Money/SelectHjxSumInfo", //采购业务接口
    采购合同收货历史收货数量: "goods/SelectGoodsInfo", //采购业务接口
    采购发票号码唯一校验: "Goods/CheckSfpIsEx", //发票校验
    工程发票号码唯一校验: "Gcbl/CheckSfpIsEx" //发票校验
  }
};
