<template>
  <div>
    <!--宫格菜单:gridItems参数可以通过接口从后台获取-->
    <div v-if="show">
      <MenuGrid
        v-for="(item, index) in gridItems"
        :key="index"
        @click="toShowPage"
        :gridItems="item.items"
        :title="item.title"
        :line="index != gridItems.length - 1"
        :ref="'menuGrid_' + index"></MenuGrid>
      <div v-show="false" class="navigation-app_fill"></div>
    </div>
    <div v-else class="navigation-loading">
      <van-loading type="spinner" size="24px" vertical
        >菜单加载中。。。</van-loading
      >
    </div>
    <router-view ref="router" :title="titleChang" />

    <!-- 底部标签栏 -->
    <FormTabbar
      ref="formTabbar"
      :taberItem="taberItem"
      @click="toDoProcess"
      :activeTabbarName="appName"></FormTabbar>
  </div>
</template>

<script>
/**
 * 导航界面
 * @creator hq
 */
import appConfig from "@/assets/js/config";
import { getBrowserType, isType, toPage } from "@/assets/js/util";
import { getLoginState } from "@/assets/js/login";
// import requestBase from "@/mixins/requestBase";
// import MenuGrid from "@/components/MenuGrid";
// import FormTabbar from "@/components/FormTabbar";
import MicroAppBaseLibrary from "@zstech/mobile-base-library";
import { MenuGrid, FormTabbar } from "@zstech/mobile-base-library";

// menu菜单点击时会回调toPage这个函数...
window.toPage = toPage;

export default {
  isMainTabView: true,
  name: "NavigationPage",
  // mixins: [requestBase], //引入mixin
  components: { MenuGrid, FormTabbar },
  data: function () {
    return {
      headTitle: "工作台",
      action: appConfig.API_CONFIGS["获取主界面待处理信息数量接口"], //"getMyNoticeNum",
      getHomeMenuAction: appConfig.API_CONFIGS["获取主界面菜单配置信息接口"],
      show: false,
      gridItems: [],
      gridInfoNames: ["收件", "表单消息", "通知", "抄送", "回函"],
      gridGroup: appConfig.NAVIGATION_PAGE_GROPUS || [], //菜单分组定义
      tabbarActive: "main",
      token: "",
      appName: "工作台",
      taberItem: []
    };
  },
  created: function () {
    if (this.$route.query.appName) {
      this.appName = this.$route.query.appName;
    }

    this.$emit("title", this.headTitle);

    this.token = getLoginState();
    if (this.token) {
      this.getMenuList();
      this.loadMenu();
    }
  },
  methods: {
    getMenuList: function () {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      var params = {
        appName: this.appName
      };
      var _this = this;
      this.gridItems = [];
      MicroAppBaseLibrary.mpost(
        "获取主界面菜单配置信息接口",
        params,
        function (data) {
          if (data.Success) {
            //获取数据成功
            let datalist = data.Data.datalist;
            if (
              datalist != null &&
              datalist != undefined &&
              datalist.length > 0
            ) {
              _this.gridGroup = [];
              for (var i = 0; i < datalist.length; i++) {
                let func2 = eval("(false || " + datalist[i].Callback + ")");
                datalist[i].Callback = func2;
                if (
                  datalist[i].GroupName &&
                  !_this.gridGroup.includes(datalist[i].GroupName)
                ) {
                  _this.gridGroup.push(datalist[i].GroupName);
                }
              }
              let tempItem = null;
              if (_this.gridGroup.length > 0) {
                //被分组了
                let otherItemsIndex = [];
                for (let i = 0; i < _this.gridGroup.length; i++) {
                  let tempItemList = [];
                  for (let j = 0; j < datalist.length; j++) {
                    let menuDefine = null;
                    if (datalist[j].MenuDefine) {
                      try {
                        menuDefine = JSON.parse(datalist[j].MenuDefine);
                      } catch (e) {
                        console.warn(e);
                      }
                      if (
                        menuDefine &&
                        isType(menuDefine.group) === "val" &&
                        menuDefine.group === i
                      ) {
                        tempItemList.push(datalist[j]);
                      } else if (
                        otherItemsIndex.indexOf(j) < 0 &&
                        (!menuDefine ||
                          isType(menuDefine.group) !== "val" ||
                          menuDefine.group < 0 ||
                          menuDefine.group >= _this.gridGroup.length)
                      ) {
                        otherItemsIndex.push(j);
                      }
                    }
                  }
                  if (tempItemList.length > 0) {
                    tempItem = { title: _this.gridGroup[i] };
                    tempItem.items = tempItemList;
                    _this.gridItems.push(tempItem);
                  }
                }
                if (otherItemsIndex.length > 0) {
                  //有非分组定义菜单
                  let otherItem = [];
                  for (let v = 0; v < otherItemsIndex.length; v) {
                    otherItem.push(datalist[otherItemsIndex[v]]);
                  }
                  _this.gridItems.push({ items: otherItem });
                }
              } else {
                tempItem = { items: datalist };
                _this.gridItems.push(tempItem);
              }
            }
            _this.getNumData();
          } else {
            //获取数据失败
            _this.$toast.clear();
            console.warn(data.StatusCode + ":" + data.Message);
            _this.$toast("数据获取失败：" + data.Message);
            _this.show = true;
          }
        },
        function (e) {
          _this.$toast.clear();
          console.error(
            getBrowserType() +
              "端" +
              _this.getHomeMenuAction +
              "接口调取失败" +
              (e.stack || e.message)
          );
          _this.$toast("数据获取失败：网络异常");
          _this.show = true;
        }
      );
    },
    /**
     * 获取每种分类下的统计数量
     */
    getNumData: function () {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      var params = {
        noticetype: ""
      };
      var _this = this;
      MicroAppBaseLibrary.mpost(
        "获取主界面待处理信息数量接口",
        params,
        function (data) {
          if (data.Success) {
            //获取数据成功
            let datalist = data.Data.datalist;
            if (
              datalist != null &&
              datalist != undefined &&
              datalist.length > 0
            ) {
              for (let i = 0; i < datalist.length; i++) {
                let type = datalist[i].type;
                _this.gridItems.map(function (d) {
                  for (let j = 0; j < d.items.length; j++) {
                    if (
                      d.items[j].Text ===
                      appConfig.NAVIGATION_PAGE_HINT_NUM_MAPPING[type]
                    ) {
                      d.items[j].Info = datalist[i].num;
                      break;
                    }
                  }
                });
              }
            }
            console.log("xxxxxx", _this.gridItems);
            _this.$toast.clear();
          } else {
            //获取数据失败
            _this.$toast.clear();
            console.warn(data.StatusCode + ":" + data.Message);
            _this.$toast("数据获取失败：" + data.Message);
          }
          _this.show = true;
        },
        function (e) {
          _this.$toast.clear();
          console.error(
            getBrowserType() +
              "端" +
              _this.action +
              "接口调取失败" +
              (e.stack || e.message)
          );
          _this.$toast("数据获取失败：网络异常");
          _this.show = true;
        }
      );
    },

    /**
     * 响应页面宫格菜单点击点击事件
     * @param {Function} callbackname 回调函数对象
     * @author wangxin
     */
    toShowPage: function (callbackname) {
      callbackname();
    },
    loadMenu: function () {
      var _this = this;

      var params = {
        appName: "导航"
      };
      MicroAppBaseLibrary.mpost(
        "获取主界面菜单配置信息接口",
        params,
        function (res) {
          if (res.Success) {
            if (res.Data.datalist.length > 0) {
              _this.taberItem = [];
              for (let i = 0; i < res.Data.datalist.length; i++) {
                const item = res.Data.datalist[i];
                _this.taberItem.push({
                  icon: item.Icon,
                  iconPrefix: item.IconPrefix,
                  name: item.Text,
                  text: item.Text,
                  viewName: item.Callback
                });
              }

              console.log("加载底部菜单：", _this.taberItem);
            }
          }
        }
      );
    },
    /**
     * 响应页面下方操作标签栏点击事件
     * @param {String} index 标签name
     * @author wangxin
     */
    toDoProcess: function (tabDefine) {
      this.title = tabDefine.text;
      this.$emit("title", this.title);
      if (tabDefine.viewName.indexOf("Navigation") > -1) {
        if (tabDefine.viewName.indexOf("=") > -1)
          this.appName = tabDefine.viewName.split("=")[1];
        else this.appName = "工作台";

        this.getMenuList();
      } else {
        this.$router.push("/" + tabDefine.viewName);
      }
    },
    titleChang: function (name) {
      if (name == "") {
        console.log("没有titlehead");
        return;
      } else {
        this.title = name;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation-loading {
  padding: 24px;
}

.navigation-app_fill {
  height: 50px;
  width: 100%;
}

::v-deep .van-grid-item {
  padding-top: 0 !important;
  height: 120px !important;
}
</style>
